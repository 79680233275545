import { ReactElement } from 'react'
import { Popup } from 'react-map-gl'
import { PositionType } from 'react-map-gl/src/utils/dynamic-position'
import { AnyProps } from 'supercluster'
import CartoucheSetup from './CartoucheSetup'

type Props = {
  clusterLeaves: Array<supercluster.PointFeature<AnyProps>> | undefined
  clusterId: number
  latitude: number
  longitude: number
  popupAnchor: PositionType
  offsetTop: number
  shortTrain: boolean
}

export default function CartouchePopup({
  clusterLeaves, clusterId, popupAnchor, offsetTop,
  latitude, longitude, shortTrain,
}: Props): ReactElement {
  const orderBytrainNumber = clusterLeaves !== undefined ? (clusterLeaves.sort((a, b) => {
    if (a.properties.trainNumber < b.properties.trainNumber) return -1
    return a.properties.trainNumber > b.properties.trainNumber ? 1 : 0
  })) : undefined
  const orderByDirection = orderBytrainNumber !== undefined ? (orderBytrainNumber.sort((a, b) => {
    if (a.properties.direction < b.properties.direction) return -1
    return a.properties.direction > b.properties.direction ? 1 : 0
  })) : undefined
  return (
    <Popup
      className="cartouche-popup"
      key={`popup-${clusterId}`}
      latitude={latitude}
      longitude={longitude}
      closeButton={false}
      closeOnClick={false}
      dynamicPosition={false}
      tipSize={20}
      offsetTop={offsetTop}
      anchor={popupAnchor}
    >
      <div className="flex-column column-gap">
        {orderByDirection?.map(cartouche => (
          <CartoucheSetup
            key={cartouche.properties.cartoucheId}
            direction={cartouche.properties.direction}
            trainNumber={cartouche.properties.trainNumber}
            id={cartouche.properties.id}
            railway={cartouche.properties.railway}
            delay={cartouche.properties.delay}
            timeObserved={cartouche.properties.timeObserved}
            shortTrain={shortTrain}
            isTco={cartouche.properties.source === 'TCO'}
          />
        ))}
      </div>
    </Popup>
  )
}
