import { store } from 'Store'

const OPENTCO_ADMIN = 'open_tco::ADMIN'
const OPENTCO_OPERATOR = 'open_tco::OPERATOR'
const OPENTCO_ACCESS = 'open_tco::ACCESS'
const VIGNEUX_PERMISSION = 'open_tco::VIGNEUX'
const SAINT_RAPHAEL_PERMISSION = 'open_tco::ST_RAPH'
const CERBERE_PERMISSION = 'cerbere::ACCESS'

export const hasAdminAccess = (): boolean => {
  const { appPermissions } = store.getState().user
  return appPermissions.includes(OPENTCO_ADMIN)
}

export const hasOperatorAccess = (): boolean => {
  const { appPermissions } = store.getState().user
  return appPermissions.includes(OPENTCO_OPERATOR)
}

export const hasOpentcoAccess = (): boolean => {
  const { appPermissions } = store.getState().user
  return appPermissions.includes(OPENTCO_ACCESS)
}

export const hasVigneuxAccess = (): boolean => {
  const { appPermissions } = store.getState().user
  return appPermissions.includes(VIGNEUX_PERMISSION)
}

export const hasSaintRaphaelAccess = (): boolean => {
  const { appPermissions } = store.getState().user
  return appPermissions.includes(SAINT_RAPHAEL_PERMISSION)
}

export const hasCerbereAccess = (): boolean => {
  const { appPermissions } = store.getState().user
  return (
    appPermissions.includes(CERBERE_PERMISSION)
  )
}
