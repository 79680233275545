/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import WidgetsService from './services/widgetsService'
import {
  DeleteParams, WidgetPosition, WidgetState, WidgetView, WidgetViewport,
} from './types'

const initialState: WidgetState = {
  widgetsList: [],
  loading: false,
  lock: true,
  shortTrain: false,
  widgetViewport: undefined,
  updatedWidget: undefined,
  widgetsPositions: [],
}

const addWidget = (state: WidgetState, action: PayloadAction<WidgetView>) => {
  state.widgetsList.push(action.payload)
  state.responseError = undefined
}

const deleteWidget = (state: WidgetState, action: PayloadAction<DeleteParams>) => {
  state.widgetsList = state.widgetsList.filter(widget => widget.slug !== action.payload.widgetSlug)
}

export const viewSlice = createSlice({
  name: 'widget',
  initialState,
  reducers: {
    updateWidgetsList: (state, action: PayloadAction<Array<WidgetView>>) => {
      state.widgetsList = action.payload
    },
    toggleLock: (state, action: PayloadAction<boolean>) => {
      state.lock = action.payload
    },
    updateViewport: (state, action: PayloadAction<WidgetViewport | undefined>) => {
      state.widgetViewport = action.payload
      const updatedWidget = state.widgetsList.find(widget => widget.slug === action.payload?.widgetSlug)
      if (updatedWidget) {
        state.widgetsList = state.widgetsList.map(widget => (widget.slug === action.payload?.widgetSlug ? { ...widget, viewport: action.payload?.viewport } : widget))
      }
    },
    updateResponseError: state => {
      state.responseError = undefined
    },
    updateWidgetsPositions: (state, action: PayloadAction<WidgetPosition[]>) => {
      state.widgetsPositions = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(WidgetsService.getWidgetsList.pending, state => {
      state.loading = true
    })
    builder.addCase(WidgetsService.getWidgetsList.fulfilled, (state, action) => {
      state.widgetsList = action.payload
      state.loading = false
    })
    builder.addCase(WidgetsService.createWidget.fulfilled, addWidget)
    builder.addCase(WidgetsService.createWidget.rejected, (state, action) => {
      state.responseError = action.payload
    })
    builder.addCase(WidgetsService.updateWidget.fulfilled, (state, action) => {
      state.updatedWidget = action.payload
      state.responseError = undefined
    })
    builder.addCase(WidgetsService.updateWidget.rejected, (state, action) => {
      state.responseError = action.payload
    })
    builder.addCase(WidgetsService.deleteWidget.fulfilled, deleteWidget)
  },
})

export const {
  updateWidgetsList, toggleLock, updateViewport, updateResponseError, updateWidgetsPositions,
} = viewSlice.actions

export default viewSlice.reducer
