/* eslint-disable react/no-array-index-key */
import {
  Badge, Box, Dialog, DialogContent, IconButton, Typography,
} from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AlertsDetails } from 'reducers/types'
import { RootState } from 'Store'
import AlertsStatus from './AlertsStatus'

export default function FluxAlerts(): ReactElement {
  const { tcoAlerts, basicAlerts } = useSelector((state: RootState) => state.stream)
  const { activeZone } = useSelector((state: RootState) => state.zone)
  const [errorAlerts, setErrorAlerts] = useState<AlertsDetails[]>([])
  const [warningAlerts, setWarningAlerts] = useState<AlertsDetails[]>([])
  const [alerts, setAlerts] = useState<any>([])
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    const errors = []
    const warnings = []
    if (tcoAlerts) {
      if (tcoAlerts.status === AlertsStatus.Error) errors.push(...tcoAlerts.alerts)
      if (tcoAlerts.status === AlertsStatus.Warning) warnings.push(...tcoAlerts.alerts)
    }
    if (basicAlerts) {
      if (basicAlerts.status === AlertsStatus.Error) errors.push(...basicAlerts.alerts)
    }
    setErrorAlerts([...errors])
    setWarningAlerts([...warnings])
  }, [tcoAlerts, basicAlerts, activeZone])

  useEffect(() => {
    const alertsList = []
    errorAlerts.forEach(err => alertsList.push({ type: 'error', alert: err }))
    warningAlerts.forEach(war => alertsList.push({ type: 'warning', alert: war }))
    setAlerts(alertsList)
  }, [errorAlerts, warningAlerts])

  return (
    <>
      {alerts.length !== 0 && alerts.slice(0, 2).map((alert: any, index: number) => (
        <Box key={index} className={`stream-alert${alert.type === 'error' ? ' error-alert' : ' warning-alert'}`}>
          <Typography>
            {alert.alert.issue}
          </Typography>
        </Box>
      ))}
      {alerts.length !== 0 && alerts.slice(2, alerts.length).length !== 0 && (
      <IconButton onClick={() => setOpen(true)}>
        <Badge
          badgeContent={`+${alerts.length - 2}`}
          className="alert-badge"
        />
      </IconButton>
      )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{ m: '10px' }}
      >
        <DialogContent>
          {alerts.slice(2, alerts.length).map((alert: any, index: number) => (
            <Box
              key={index}
              className={`my-2 stream-alert${alert.type === 'error' ? ' error-alert' : ' warning-alert'}`}
            >
              <Typography>
                {alert.alert.issue}
              </Typography>
            </Box>
          ))}
        </DialogContent>
      </Dialog>
    </>

  )
}
