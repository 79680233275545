import { ErrorOutline } from '@mui/icons-material'
import { Grid, Input, InputLabel } from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { ResponseError } from 'types'

import './input.scss'

type Props = {
    label?: string;
    value: string;
    onChange: (value: any) => void;
    responseError?: ResponseError;
    patternError?: boolean;
}

const defaultProps = {
  label: '',
  responseError: undefined,
  patternError: false,
}

export default function SimpleInput({
  label, value, onChange, responseError, patternError,
}: Props): ReactElement {
  return (
    <Grid>
      {label !== '' && (
      <InputLabel
        htmlFor="my-input"
        className={`input-label${(responseError && responseError.status === 400) || patternError
          ? ' input-label-warning' : ''}`}
      >
        {label}
      </InputLabel>
      )}
      <Input
        value={value}
        onChange={onChange}
        onKeyPress={event => {
          if (event.key === 'Enter') { event.preventDefault() }
        }}
        className={`input-field${(responseError && responseError.status === 400) || patternError
          ? ' input-field-warning' : ''}`}
        inputProps={{ pattern: "[a-zA-Z0-9ÀÂÄÇÉÈÊËÎÏÔÖÙÛÜŸÆŒàâäçéèêëîïôöùûüÿæœ._~:/?#@!$&'[\\]()*+,;=\\s-]+" }}
      />
      {responseError && responseError.status === 400 && (
      <div className="d-flex mt-1">
        <ErrorOutline className="error-icon" />
        <div className="input-label input-label-warning">{responseError.message?.detail}</div>
      </div>
      )}
      {patternError && (
      <div className="d-flex mt-1">
        <ErrorOutline className="error-icon" />
        <div className="input-label input-label-warning">{terms.Common.patternError}</div>
      </div>
      )}
    </Grid>
  )
}

SimpleInput.defaultProps = defaultProps
