import { ReactElement } from 'react'
import Typography from '@mui/material/Typography'
import terms from 'common/terms'
import { Card, CardActionArea } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import './widget.scss'

type Props = {
  onClick: () => void
}

export default function AddFirstWidgetCard({ onClick }: Props): ReactElement {
  return (

    <Card
      sx={{ width: '90%', height: 220 }}
      className="first-widget-btn shadow-none"
    >
      <CardActionArea
        aria-label="settings"
        onClick={onClick}
      >
        <AddIcon />
        <Typography>
          {terms.Widget.createFirstWidget}
        </Typography>
      </CardActionArea>
    </Card>
  )
}
