import { ReactElement, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { Stack, Divider } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import terms from 'common/terms'
import { Alerts } from 'reducers/types'
import AlertsStatus from './AlertsStatus'
import AlertsMonitor from './AlertsMonitor/AlertsMonitor'

interface Props {
  showAlerts: boolean
}

export default function TcoInfos({ showAlerts }: Props): ReactElement {
  const { tcoAlerts } = useSelector((state: RootState) => state.stream)
  const { basicAlerts } = useSelector((state: RootState) => state.stream)
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)
  const [currentDate, setCurrentDate] = useState(new Date())

  useEffect(() => {
    setInterval(() => {
      setCurrentDate(new Date())
    }, 1000)
  }, [])

  const getStreamColor = (alert: Alerts) => {
    if (alert) {
      switch (alert.status) {
        case AlertsStatus.Ok:
          return 'text-success'
        case AlertsStatus.Error:
          return 'text-danger'
        case AlertsStatus.Warning:
          return 'warning-alert-sign'
        default:
          return 'text-danger'
      }
    } else return 'text-danger'
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        justifyContent: 'space-around', alignItems: 'center', mr: '10px', ml: '10px',
      }}
    >
      {(activePerimeter.tco || activePerimeter.tconext) && (
      <>
        <div className="stream-state-tx">{terms.Common.tco}</div>
        <i className={`${getStreamColor(tcoAlerts)} icons-slider-on mr-2`} />
      </>
      )}
      {activePerimeter.basic && (
      <>
        <div className="stream-state-tx">{terms.Common.basic}</div>
        <i className={`${getStreamColor(basicAlerts)} icons-slider-on mr-2`} />
      </>
      )}
      <Divider
        orientation="vertical"
        sx={{ height: '10px', backgroundColor: '#878787', border: '1px solid #878787' }}
      />
      {showAlerts && (
        <>
          <AlertsMonitor />
          <Divider
            orientation="vertical"
            sx={{ height: '10px', backgroundColor: '#878787', border: '1px solid #878787' }}
          />
        </>
      )}
      <div className="pr-2"><AccessTimeIcon sx={{ color: '#878787' }} /></div>
      <div className="ml-0">{currentDate.toLocaleString('fr-FR', { hour12: false }).split(' ')[1]}</div>
      <div className="pr-2"><CalendarMonthOutlinedIcon sx={{ color: '#878787' }} /></div>
      <div className="ml-0">{currentDate.toLocaleString().split(' ')[0]}</div>
    </Stack>
  )
}
