export enum ObjectsLayer {
    tiv = 'opentco_troncons_itineraire_voie',
    signaux = 'opentco_signaux',
    gareTrigram = 'opentco_trigrammes_gare',
    gareLabel = 'opentco_noms_gare',
    adv = 'opentco_appareils_de_voie',
    sensCirculation = 'opentco_sens_circulation',
    bv = 'opentco_batiments_voyageurs',
    quai = 'opentco_quais',
    trackLabel = 'opentco_noms_voie'
  }

export default ObjectsLayer
