import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import terms from 'common/terms'
import SimpleInput from 'components/Common/Input/SimpleInput'
import {
  Dialog, DialogActions, DialogTitle, DialogContent, Button, ThemeProvider,
} from '@mui/material'
import { customCancelButton, customCreationButton } from 'common/CustomTheme'
import { RootState } from 'Store'
import AddButton from 'components/Common/Button/AddButton'
import { updateResponseError } from 'reducers/perimeter'
import PerimetersService from '../../reducers/services/perimetersService'

export default function AddPerimeterModal(): ReactElement {
  const dispatch = useDispatch()

  const [open, setOpen] = useState<boolean>(false)
  const [perimeterName, setPerimeterName] = useState<string>('')
  const [patternError, setPatternError] = useState<boolean>(false)
  const { responseError, perimetersList } = useSelector((state: RootState) => state.perimeter)

  const handleOpen = () => setOpen(true)
  const onCancel = () => {
    setOpen(false)
    setPerimeterName('')
    dispatch(updateResponseError(undefined))
    setPatternError(false)
  }

  useEffect(() => {
    if (!responseError && open) onCancel()
  }, [responseError, perimetersList])

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') { return }
    onCancel()
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const patternMatcher = event.target.value
      .match('^[a-zA-Z0-9ÀÂÄÇÉÈÊËÎÏÔÖÙÛÜŸÆŒàâäçéèêëîïôöùûüÿæœ._~:/?#@!$&\'[\\]()*+,;=-\\s]+')
    setPerimeterName(event.target.value)
    setPatternError(false)
    if ((patternMatcher && patternMatcher[0].length !== event.target.value.length)
     || (patternMatcher === null && event.target.value !== '')) {
      setPatternError(true)
    }
  }

  const addPerimeter = event => {
    event.preventDefault()
    const params = {
      label: perimeterName,
    }
    dispatch(PerimetersService.createPerimeter(params))
  }

  return (
    <>
      <AddButton handleOpen={handleOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ m: '10px' }}
      >
        <DialogTitle id="alert-dialog-title" className="modal-title">
          {terms.Perimeter.addPerimeter}
        </DialogTitle>
        <DialogContent>
          <SimpleInput
            label={terms.Common.name}
            value={perimeterName}
            onChange={handleNameChange}
            responseError={responseError}
            patternError={patternError}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', p: '20px 24px' }}>
          <ThemeProvider theme={customCancelButton}>
            <Button onClick={onCancel}>
              {terms.Common.cancel}
            </Button>
          </ThemeProvider>
          <ThemeProvider theme={customCreationButton}>
            <Button onClick={addPerimeter} disabled={perimeterName === '' || patternError}>
              {terms.Perimeter.addPerimeterButton}
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
    </>
  )
}
