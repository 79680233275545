/* eslint-disable max-len */
import { Objects } from 'components/Common/ToolsBar/types'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { ResizableObjects } from 'reducers/types'
import { RootState } from 'Store'
import { getSize } from '../utils'

type Props = {
  trigram: string,
}

export default function GareSvg({
  trigram,
}: Props): ReactElement {
  const { objectsLayer } = useSelector((state: RootState) => state.objects)
  const garesObject: ResizableObjects = objectsLayer?.filter((obj: ResizableObjects) => obj.label === Objects.Gare)[0]

  return (
    <svg
      width={30 * getSize(garesObject?.size)}
      height={30 * getSize(garesObject?.size)}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g>
        <defs>
          <filter id="f3" x="0" y="0" width="170%" height="170%">
            <feOffset result="offOut" in="SourceAlpha" dx="1" dy="1" />
            <feGaussianBlur result="blurOut" in="offOut" stdDeviation="1" />
            <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
          </filter>
        </defs>
        <circle fill="#C0CFD8" cx={12 * getSize(garesObject?.size)} cy={12 * getSize(garesObject?.size)} r={12 * getSize(garesObject?.size)} filter="url(#f3)" />
        <text
          fontWeight="bolder"
          fill="#242C31"
          xmlSpace="preserve"
          textAnchor="middle"
          fontFamily="'Avenir'"
          fontSize={9 * getSize(garesObject?.size)}
          y={15 * getSize(garesObject?.size)}
          x={12 * getSize(garesObject?.size)}
        >
          {trigram}
        </text>
      </g>
    </svg>
  )
}
