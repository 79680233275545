import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { updateUpdatedPerimeter } from 'reducers/perimeter'
import { hasAdminAccess } from 'helpers/permissions'
import Grid from '@mui/material/Grid'
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import terms from 'common/terms'
import { Perimeter } from '../../reducers/types'
import AddPerimeterModal from './AddPerimeterModal'
import PerimetersService from '../../reducers/services/perimetersService'
import CardItem from './CardItem'
import PerimeterOrdering from './PerimeterOrdering'

export default function CardsList(): ReactElement {
  const dispatch = useDispatch()
  const {
    perimetersList, updatedPerimeter, perimetersCount, orderBy,
  } = useSelector((state: RootState) => state.perimeter)

  useEffect(() => {
    if (updatedPerimeter || perimetersList.length === 0) {
      dispatch(PerimetersService.getPerimetersList({ ordering: orderBy }))
      dispatch(updateUpdatedPerimeter(false))
    }
  }, [updatedPerimeter])

  return (
    <Fade in timeout={500}>
      <Box>
        <Grid container sx={{ height: '100%', p: 2, justifyContent: 'center' }} margin="auto">
          <Grid
            item
            container
            xs={12}
            sm={10}
            md={8}
            sx={{ p: '11px', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '12px', color: '#868686' }}>
              {terms.Perimeter.perimeterLength}
              {' '}
              <span style={{ color: '#43454A' }}>
                (
                {perimetersCount}
                )
              </span>
            </Typography>
            <PerimeterOrdering />
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={10}
            md={8}
            spacing={1}
            sx={{ alignContent: 'flex-start', justifyContent: 'flex-start' }}
          >
            {hasAdminAccess() && (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={4}
              xl={3}
              sx={{ justifyContent: 'center' }}
            >
              <AddPerimeterModal />
            </Grid>
            )}
            {perimetersList.map((perimeter: Perimeter) => (
              <Grid
                item
                key={perimeter.slug}
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={3}
                sx={{ justifyContent: 'center' }}
              >
                <CardItem perimeter={perimeter} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Fade>
  )
}
