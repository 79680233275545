import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { hasAdminAccess, hasOperatorAccess } from 'helpers/permissions'
import { updateActiveZone, updateDashboardTabs } from 'reducers/zone'
import {
  Grid, Card, CardContent, CardActions, Typography, Divider, ThemeProvider, Button, Chip,
} from '@mui/material'
import { customOpenButton } from 'common/CustomTheme'
import { Zone } from 'reducers/types'
import terms from 'common/terms'
import { DEFAULT_LATE_PARAMS, updateIsLateSettings, updateObjectsLayer } from 'reducers/objects'
import InitialResizableObjects from 'components/Common/ToolsBar/InitialResizableObjects'
import history from '../../appHistory'

import './dashboard.scss'
import ActionsMenu from './ActionsMenu'
import ScreenSize from './DashboardInputModal/ScreenSize'

type Props = {
    zone?: Zone,
    path: string,
    fullView?: boolean,
    cameraView?: boolean,
    }
export default function DashboardCard({
  zone, path, fullView, cameraView,
}: Props): ReactElement {
  const dispatch = useDispatch()
  const { dashboardTabs } = useSelector((state: RootState) => state.zone)

  const goToDashboard = () => {
    if (zone) {
      dispatch(updateActiveZone(zone))
      if (dashboardTabs.filter(dashboard => dashboard.key === zone.pk).length === 0) {
        const newTabsList = []
        newTabsList.push(...dashboardTabs)
        newTabsList.push({ key: zone.pk, value: zone.label })
        dispatch(updateDashboardTabs(newTabsList))
      }
    } else if (fullView) {
      dispatch(updateActiveZone({ label: terms.Dashboard.fullViewCard }))
      if (dashboardTabs.filter(dashboard => dashboard.key === undefined).length === 0) {
        const newTabsList = []
        newTabsList.push(...dashboardTabs)
        newTabsList.push({ value: terms.Dashboard.fullViewCard })
        dispatch(updateDashboardTabs(newTabsList))
      }
    } else if (cameraView) {
      dispatch(updateActiveZone({ label: terms.Dashboard.cameraViewCard }))
      if (dashboardTabs.filter(dashboard => dashboard.key === 'camera').length === 0) {
        const newTabsList = []
        newTabsList.push(...dashboardTabs)
        newTabsList.push({ value: terms.Dashboard.cameraViewCard, key: 'camera' })
        dispatch(updateDashboardTabs(newTabsList))
      }
    }
    if (zone && zone.screen_settings) {
      dispatch(updateObjectsLayer(zone.screen_settings))
      dispatch(updateIsLateSettings({
        is_train_late_delay: zone.is_train_late_delay ?? DEFAULT_LATE_PARAMS.is_train_late_delay,
        late_color: zone.late_color ?? DEFAULT_LATE_PARAMS.late_color,
        on_time_color: zone.on_time_color ?? DEFAULT_LATE_PARAMS.on_time_color,
      }))
    } else {
      dispatch(updateObjectsLayer(InitialResizableObjects))
      dispatch(updateIsLateSettings(DEFAULT_LATE_PARAMS))
    }
    history.push(path)
  }

  const getLabel = () => {
    if (fullView) {
      return terms.Dashboard.fullViewCard
    } if (cameraView) {
      return terms.Dashboard.cameraViewCard
    }
    return zone ? zone.label : terms.Dashboard.fullViewCard
  }

  const getDescription = () => {
    if (fullView) {
      return terms.Dashboard.fullViewDescription
    } if (cameraView) {
      return terms.Dashboard.cameraViewDescription
    }
    return zone ? zone.description : terms.Dashboard.fullViewDescription
  }

  return (
    <>
      <Grid
        item
        xs={10}
        sm={6}
        md={6}
        lg={4}
        xl={3}
        sx={{ justifyContent: 'center' }}
      >
        <Card
          sx={{ height: '220px' }}
          className="dashboard-card"
        >
          <CardContent className="card-content">
            <Grid>
              <Typography className="card-label">
                {getLabel()}
              </Typography>
              {(hasAdminAccess() || hasOperatorAccess()) && zone && <ActionsMenu zone={zone} />}
            </Grid>
            <Typography className="card-description">
              {getDescription()}
            </Typography>
          </CardContent>
          <Divider sx={{ m: '0 10px' }} />
          <CardActions className="card-actions">
            {zone && <Chip label={ScreenSize.filter(size => size.key === zone.screen_size)[0].value} />}
            <ThemeProvider theme={customOpenButton}>
              <Button onClick={goToDashboard}>
                {terms.Common.open}
              </Button>
            </ThemeProvider>
          </CardActions>
        </Card>
      </Grid>
    </>
  )
}

DashboardCard.defaultProps = {
  zone: undefined,
  fullView: undefined,
  cameraView: undefined,
}
