import { ReactElement } from 'react'
import { IconButton } from '@mui/material'
import TrainIcon from '@mui/icons-material/Train'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { WidgetView } from 'reducers/types'
import WidgetsService from 'reducers/services/widgetsService'

type Props = {
  shortTrain: boolean
  setShortTrain: (value: boolean) => void
  widget: WidgetView,
}

export default function ShortTrainButton({ shortTrain, setShortTrain, widget }: Props): ReactElement {
  const dispatch = useDispatch()
  const { lock } = useSelector((state: RootState) => state.widget)
  const { activeZone } = useSelector((state: RootState) => state.zone)
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)

  const handleClick = () => {
    setShortTrain(!shortTrain)
    const requestParams = {
      perimeterSlug: activePerimeter?.slug,
      zonePk: activeZone?.pk,
      widgetSlug: widget.slug,
      widget: {
        short_train: !shortTrain,
      },
    }
    dispatch(WidgetsService.updateWidget(requestParams))
  }

  return (
    <IconButton onClick={handleClick}>
      <TrainIcon sx={{ color: lock ? 'white' : '#00000042' }} />
    </IconButton>
  )
}
