import { ReactElement, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import ToolsBar from 'components/Common/ToolsBar/ToolsBar'
import TcoDataStream from 'components/Common/TcoDataStream'
import BasicStream from 'components/Common/BasicStream'
import TcoInfos from '../Common/ToolsBar/TcoInfos'
import Map from '../Map/Map'

export default function FullViewMap(): ReactElement {
  const { fullscreenHandler } = useSelector((state: RootState) => state.app)
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)

  const [shortTrain, setShortTrain] = useState<boolean>(false)

  return (
    <>
      {(activePerimeter.tco || activePerimeter.tconext) && <TcoDataStream />}
      {activePerimeter.basic && (<BasicStream />)}
      <main className={`mastcontainer mastcontainer-map${fullscreenHandler.active ? ' fullscreen' : ''}`}>
        <ToolsBar showAlerts={false} shortTrain={{ value: shortTrain, setter: setShortTrain }} />
        <Map shortTrain={shortTrain} />
        {fullscreenHandler.active && <TcoInfos showAlerts={false} />}
      </main>
    </>
  )
}
