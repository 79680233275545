import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrderBy } from 'components/Common/types'
import PerimetersService from 'reducers/services/perimetersService'
import { ResponseError } from 'types'
import { Perimeter, PerimeterState } from './types'

const initialState: PerimeterState = {
  perimetersList: [],
  perimetersCount: 0,
  updatedPerimeter: false,
  orderBy: OrderBy.lastCreated,
  signalsLayer: null,
  advLayer: null,
  bvLayer: null,
  quaiLayer: null,
  sensCirculationLayer: null,
  layerBbox: null,
  prNameLayer: null,
  prTrigramLayer: null,
  trackLabelsLayer: null,
}

export const perimeterSlice = createSlice({
  name: 'perimeter',
  initialState,
  reducers: {
    updatePerimetersList: (state, action: PayloadAction<Array<Perimeter>>) => {
      state.perimetersList = action.payload
    },
    updateActivePerimeter: (state, action: PayloadAction<Perimeter>) => {
      state.activePerimeter = action.payload
    },
    updateUpdatedPerimeter: (state, action: PayloadAction<boolean>) => {
      state.updatedPerimeter = action.payload
    },
    updateResponseError: (state, action: PayloadAction<ResponseError | undefined>) => {
      state.responseError = action.payload
    },
    setOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(PerimetersService.getPerimetersList.fulfilled, (state, action) => {
      state.perimetersList = action.payload.results
      state.perimetersCount = action.payload.count
    })
    builder.addCase(PerimetersService.createPerimeter.fulfilled, (state, action) => {
      state.perimetersList.push(action.payload)
      state.responseError = undefined
    })
    builder.addCase(PerimetersService.createPerimeter.rejected, (state, action) => {
      state.responseError = action.payload
    })
    builder.addCase(PerimetersService.updatePerimeter.fulfilled, (state, action) => {
      state.updatedPerimeter = true
      state.activePerimeter = action.payload
      state.responseError = undefined
    })
    builder.addCase(PerimetersService.updatePerimeter.rejected, (state, action) => {
      state.responseError = action.payload
    })
    builder.addCase(PerimetersService.deletePerimeter.fulfilled, (state, action) => {
      state.perimetersList = state.perimetersList.filter(perimeter => perimeter.slug !== action.payload)
    })
    builder.addCase(PerimetersService.getSignalsLayerData.fulfilled, (state, action) => {
      state.signalsLayer = action.payload
    })
    builder.addCase(PerimetersService.getAdvLayerData.fulfilled, (state, action) => {
      state.advLayer = action.payload
    })
    builder.addCase(PerimetersService.getBvLayerData.fulfilled, (state, action) => {
      state.bvLayer = action.payload
    })
    builder.addCase(PerimetersService.getSensCirculationLayerData.fulfilled, (state, action) => {
      state.sensCirculationLayer = action.payload
    })
    builder.addCase(PerimetersService.getQuaiLayerData.fulfilled, (state, action) => {
      state.quaiLayer = action.payload
    })
    builder.addCase(PerimetersService.getPrLabelLayerData.fulfilled, (state, action) => {
      state.prNameLayer = action.payload
    })
    builder.addCase(PerimetersService.getPrTrigramLayerData.fulfilled, (state, action) => {
      state.prTrigramLayer = action.payload
    })
    builder.addCase(PerimetersService.getLayersBbox.fulfilled, (state, action) => {
      state.layerBbox = action.payload
    })
    builder.addCase(PerimetersService.getTrackLabelLayerData.fulfilled, (state, action) => {
      state.trackLabelsLayer = action.payload
    })
  },
})

export const {
  updatePerimetersList, updateActivePerimeter, updateUpdatedPerimeter, updateResponseError, setOrderBy,
} = perimeterSlice.actions

export default perimeterSlice.reducer
