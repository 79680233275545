import { ResizableObjects } from '../../../reducers/types'
import { Objects, ScreenSize } from './types'

export const InitialResizableObjects: ResizableObjects[] = [
  {
    label: Objects.Cartouche,
    display: true,
    adminSlider: false,
    size: ScreenSize.Medium,
  },
  {
    label: Objects.DisparitionDelay,
    display: true,
    adminSlider: true,
    size: ScreenSize.Small,
  },
  {
    label: Objects.Agregation,
    display: true,
    adminSlider: false,
    size: ScreenSize.Medium,
  },

  {
    label: Objects.Signals,
    display: false,
    adminSlider: false,
    size: ScreenSize.XSmall,
  },
  {
    label: Objects.Adv,
    display: false,
    adminSlider: false,
    size: ScreenSize.XSmall,
  },
  {
    label: Objects.Tiv,
    display: true,
    adminSlider: false,
    size: ScreenSize.XSmall,
  },
  {
    label: Objects.Gare,
    display: true,
    adminSlider: false,
    size: ScreenSize.Small,
  },
  {
    label: Objects.Annonces,
    display: true,
    adminSlider: false,
    size: ScreenSize.Small,
  },
  {
    label: Objects.Quais,
    display: true,
    adminSlider: false,
    size: ScreenSize.Small,
  },
]

export default InitialResizableObjects
