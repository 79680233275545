import { ReactElement } from 'react'
import { Layer, LayerProps, Source } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { ResizableObjects } from 'reducers/types'
import { Objects } from 'components/Common/ToolsBar/types'
import ObjectsLayer from './types'
import { getStops } from './utils'

const quaisLayer: LayerProps = {
  id: ObjectsLayer.quai,
  type: 'fill',
  paint: {
    'fill-color': '#FFFFFF',
    'fill-opacity': 0.07,
  },
}

const quaisLabelsLayer: LayerProps = {
  id: `${ObjectsLayer.quai}-labels`,
  type: 'symbol',
  layout: {
    'text-field': ['get', 'label'],
    'text-font': ['Open Sans Regular'],
    'text-allow-overlap': true,
    'text-transform': 'uppercase',
    'text-letter-spacing': 0.05,
  },
  paint: {
    'text-color': 'white',
  },
  minzoom: 14,
}

export default function Quais(): ReactElement {
  const { objectsLayer } = useSelector((state: RootState) => state.objects)
  const { quaiLayer } = useSelector((state: RootState) => state.perimeter)
  const quaisObject: ResizableObjects = objectsLayer.find((object: ResizableObjects) => object.label
   === Objects.Quais)

  return (
    <>
      <Source id={ObjectsLayer.quai} type="geojson" data={quaiLayer}>
        <Layer {...quaisLayer} />
        <Layer
          {...{
            ...quaisLabelsLayer,
            layout: {
              ...quaisLabelsLayer.layout,
              visibility: quaisObject?.display ? 'visible' : 'none',
              'text-size': {
                stops: getStops(quaisObject?.size, 0.7),
              },
            },
          }}
        />
      </Source>
    </>
  )
}
