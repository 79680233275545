import { MutableRefObject, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { ResizableObjects } from 'reducers/types'
import { RootState } from 'Store'
import { MapRef, ViewportProps } from 'react-map-gl'
import { Objects } from 'components/Common/ToolsBar/types'
import Cartouches from './Cartouches'
import Gares from './Gares/Index'
import PlanDeVoies from './PlanDeVoies'
import Signals from './Signals'
import SensDeCirculation from './SensDeCirculation'
import Quais from './Quais'
import Bvs from './Bvs'
import Advs from './Advs'
import ZonesZaps from './ZonesZaps'
import GareLabels from './GareLabels'
import TrackLabels from './TrackLabels'

type Props = {
  viewport: ViewportProps,
  mapRef: MutableRefObject<MapRef | undefined> | undefined,
  shortTrain: boolean
}
export default function MapLayers({ viewport, mapRef, shortTrain }: Props): ReactElement {
  const { objectsLayer } = useSelector((state: RootState) => state.objects)
  const basicDataStream = useSelector((state: RootState) => state.stream.basicDataStream)
  const tcoDataStream = useSelector((state: RootState) => state.stream.tcoDataStream)
  const garesObject: ResizableObjects = objectsLayer?.filter((obj: ResizableObjects) => obj.label
   === Objects.Gare)[0]
  const cartoucheObject: ResizableObjects = objectsLayer.filter((object: ResizableObjects) => object.label
   === Objects.Cartouche)[0]

  return (
    <>
      <PlanDeVoies />
      <Signals />
      <SensDeCirculation />
      <Quais />
      <Bvs />
      <Advs />
      {garesObject?.display && (
      <Gares
        viewportZoom={viewport !== undefined ? viewport.zoom : 10}
        mapRef={mapRef}
      />
      )}
      <GareLabels />
      {basicDataStream !== undefined && cartoucheObject.display && (
      <Cartouches
        viewportZoom={viewport !== undefined ? viewport.zoom : 10}
        mapRef={mapRef}
        shortTrain={shortTrain}
      />
      )}
      {(tcoDataStream !== undefined) && (<ZonesZaps />)}
      <TrackLabels />
    </>
  )
}
