import { ReactElement } from 'react'
import { Layer, LayerProps, Source } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { Objects, ScreenSize } from 'components/Common/ToolsBar/types'
import { ResizableObjects } from 'reducers/types'
import ObjectsLayer from './types'
import { getStops } from './utils'

export default function ZonesZaps() : ReactElement {
  const { tcoDataStream } = useSelector((state: RootState) => state.stream)
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)
  const { objectsLayer } = useSelector((state: RootState) => state.objects)

  const sgObject: ResizableObjects = objectsLayer.filter((object: ResizableObjects) => object.label
   === Objects.Annonces)[0]

  const offsetRate = () => {
    switch (sgObject?.size) {
      case ScreenSize.Small: return -0.9
      case ScreenSize.Medium: return -0.8
      case ScreenSize.Large: return -0.7
      case ScreenSize.Xlarge: return -0.6
      default: return -1
    }
  }

  const layerStyleZap: LayerProps = {
    id: 'tcoZapLayer',
    type: 'circle',
    minzoom: 12.5,
    paint: {
      'circle-translate': [
        'interpolate', ['linear'], ['zoom'],
        14, ['case', activePerimeter.is_upside_down, ['literal', [0, 4]], ['literal', [0, -4]]],
        18, ['case', activePerimeter.is_upside_down, ['literal', [0, 35]], ['literal', [0, -35]]],
      ],
      'circle-radius': [
        'interpolate', ['linear'], ['zoom'],
        14, 2,
        18, 12,
      ],
      'circle-color': ['case',
        ['==', ['get', 'obj_state'], 0], '#C0CFD8',
        ['==', ['get', 'obj_state'], 1], '#E41F13',
        ['==', ['get', 'obj_state'], 2], '#72A512',
        'black',
      ],
      'circle-translate-anchor': 'viewport',
    },
  }

  const layerStyleSignalsStatus: LayerProps = {
    id: 'tcoStyleSignalsLayer',
    type: 'circle',
    minzoom: 12.5,
    paint: {
      'circle-radius': [
        'interpolate', ['linear'], ['zoom'],
        14, 2,
        18, 12,
      ],
      'circle-color': ['case',
        ['==', ['get', 'obj_state'], 0], '#C0CFD8',
        ['==', ['get', 'obj_state'], 1], '#E41F13',
        ['==', ['get', 'obj_state'], 2], '#72A512',
        'black',
      ],
    },
  }

  const layerStyleZone: LayerProps = {
    id: 'tcoZoneLayer',
    beforeId: ObjectsLayer.signaux,
    type: 'line',
    layout: {
      'line-cap': 'round',
    },
    paint: {
      'line-width': [
        'interpolate',
        ['linear'],
        ['zoom'],
        10,
        1,
        15,
        3,
        16,
        8,
      ],
      'line-color': ['case',
        ['==', ['get', 'obj_state'], 0], 'rgba(0,0,0,0)',
        ['==', ['get', 'obj_state'], 1], '#E41F13',
        ['==', ['get', 'obj_state'], 2], '#72A512',
        'rgba(0,0,0,0)',
      ],
    },
  }

  const layerStyleSignalDirection: LayerProps = {
    id: 'tcoSignalDirectionLayer',
    type: 'symbol',
    minzoom: 12.5,
    layout: {
      'icon-image': 'direction',
      'icon-size': [
        'interpolate', ['linear'], ['zoom'],
        14, 0.2,
        18, 1,
      ],
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'icon-rotate': ['case', ['==', ['get', 'orientation'], 'LEFT'], 180, 0],
      'icon-offset': [35, 0],
    },
    paint: {},
  }

  const layerStyleZoneObjStateZero: LayerProps = {
    id: 'tcoZoneLayer2',
    beforeId: 'tcoZoneLayer',
    type: 'line',
    layout: {
      'line-cap': 'round',
    },
    paint: {
      'line-width': [
        'interpolate',
        ['linear'],
        ['zoom'],
        10,
        1,
        15,
        3,
        16,
        8,
      ],
      'line-color': ['case',
        ['==', ['get', 'obj_state'], 0], '#C0CFD8',
        ['==', ['get', 'obj_state'], 1], 'rgba(0,0,0,0)',
        ['==', ['get', 'obj_state'], 2], 'rgba(0,0,0,0)',
        'black',
      ],
    },
  }

  const layerStyleAnnonce: LayerProps = {
    id: 'tcoAnnonceLayer',
    type: 'symbol',
    minzoom: 12.5,
    layout: {
      'text-field': 'ANNONCE',
      'text-transform': 'uppercase',
      'text-letter-spacing': 0.04,
      'text-font': [
        'Open Sans Regular',
      ],
      'text-allow-overlap': true,
      'text-size': {
        stops: getStops(sgObject?.size),
      },
      'text-offset': [
        'interpolate',
        ['linear'],
        ['zoom'],
        13,
        ['literal', [0, offsetRate()]],
        15,
        ['literal', [0, 1.4 * offsetRate()]],
        17,
        ['literal', [0, 1.8 * offsetRate()]],
        19,
        ['literal', [0, 2.2 * offsetRate()]],
      ],
      visibility: sgObject?.display ? 'visible' : 'none',
    },
    paint: {
      'text-color': ['case',
        ['==', ['get', 'obj_state'], 1], '#E41F13',
        ['==', ['get', 'obj_state'], 2], '#C0CFD8',
        'black',
      ],
    },

  }

  const layerStyleAnnonceCircle: LayerProps = {
    id: 'tcoAnnonceCircleLayer',
    type: 'circle',
    minzoom: 12.5,
    paint: {
      'circle-radius': [
        'interpolate', ['linear'], ['zoom'],
        14, 2,
        18, 12,
      ],
      'circle-color': ['case',
        ['==', ['get', 'obj_state'], 1], '#E41F13',
        ['==', ['get', 'obj_state'], 2], '#C0CFD8',
        'black',
      ],
    },
  }

  const filterZaps = ['==', ['get', 'obj_type'], 'zap']
  const filterZones = ['==', ['get', 'obj_type'], 'zone']
  const filterSignalsStatus = ['==', ['get', 'obj_type'], 'signal']
  const filterDirections = ['all',
    ['==', ['get', 'obj_type'], 'signal'],
    ['in', ['get', 'orientation'], ['literal', ['LEFT', 'RIGHT']]],
  ]
  const filterAnnonce = ['==', ['get', 'obj_type'], 'annonce']

  return (
    <Source id="my-data" type="geojson" data={tcoDataStream}>
      <Layer {...layerStyleSignalDirection} filter={filterDirections} />
      <Layer {...layerStyleSignalsStatus} filter={filterSignalsStatus} />
      <Layer {...layerStyleAnnonceCircle} filter={filterAnnonce} />
      <Layer {...layerStyleAnnonce} filter={filterAnnonce} />
      <Layer {...layerStyleZap} filter={filterZaps} />
      <Layer {...layerStyleZone} filter={filterZones} />
      <Layer {...layerStyleZoneObjStateZero} filter={filterZones} />
    </Source>
  )
}
