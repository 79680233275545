import {
  ReactElement, useCallback, useEffect, useState,
} from 'react'
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import { Paper, IconButton, debounce } from '@mui/material'
import { RootState } from 'Store'
import { useDispatch, useSelector } from 'react-redux'
import { ResizableObjects } from 'reducers/types'
import ZonesService from 'reducers/services/zonesService'
import { InitialResizableObjects } from './InitialResizableObjects'
import ResizableLayer from './ResizableLayer'
import DelaySlider from './DelaySlider'

export default function LayersSizeSidebar(): ReactElement {
  const dispatch = useDispatch()
  const [displaySidebar, setDisplaySidebar] = useState(false)
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)
  const { objectsLayer, isLateSettings } = useSelector((state: RootState) => state.objects)
  const { activeZone } = useSelector((state: RootState) => state.zone)

  const saveSettings = newSettings => {
    dispatch(ZonesService.updateZone({
      perimeterSlug: activePerimeter.slug,
      zonePk: activeZone.pk,
      zone: {
        screen_settings: newSettings,
      },
    }))
  }
  const debouncedSaveSettings = useCallback(debounce(saveSettings, 1000), [activePerimeter, activeZone])

  useEffect(() => {
    if (displaySidebar && activeZone.pk) {
      debouncedSaveSettings(objectsLayer)
    }
  }, [objectsLayer])

  const handleClick = () => {
    setDisplaySidebar(!displaySidebar)
  }
  return (
    <div>
      <IconButton
        className={`toolbar-action-icon${displaySidebar ? ' active-size-sidebar-icon' : ''}`}
        onClick={handleClick}
      >
        <LayersOutlinedIcon />
      </IconButton>
      <Paper className={`${displaySidebar ? 'size-side-bar-extended' : 'size-side-bar'}`}>
        {displaySidebar && (
        <div className="side-bar-max-height">
          <DelaySlider
            defaultLateDelay={isLateSettings.is_train_late_delay}
            defaultLateColor={isLateSettings.late_color}
            defaultOnTimeColor={isLateSettings.on_time_color}
          />
          {InitialResizableObjects.map((obj: ResizableObjects) => (
            <ResizableLayer key={obj.label} objectToResize={obj} />
          ))}
        </div>
        )}
      </Paper>
    </div>
  )
}
