import MAIN_API from 'config/config'
import { Objects } from 'components/Common/ToolsBar/types'
import { ReactElement } from 'react'
import { Layer, LayerProps, Source } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { ResizableObjects } from 'reducers/types'
import { RootState } from 'Store'
import ObjectsLayer from './types'
import { getStops } from './utils'

const tivVSLayer: LayerProps = {
  id: 'tivVS',
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#53656F',
    'line-width': [
      'interpolate',
      ['linear'],
      ['zoom'],
      10,
      1,
      15,
      3,
      16,
      8,
    ],
  },
  filter: ['==', 'type_voie', 'VS'],
}

const tivVPLayer: LayerProps = {
  id: 'tivVP',
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#C0CFD8',
    'line-width': [
      'interpolate',
      ['linear'],
      ['zoom'],
      10,
      1,
      15,
      3,
      16,
      8,
    ],
  },
  filter: ['==', 'type_voie', 'VP'],
}

const truckNameLayer: LayerProps = {
  id: 'nVoie',
  type: 'symbol',
  layout: {
    'text-font': [
      'Open Sans Regular',
    ],
    'symbol-placement': 'line',
    'symbol-spacing': [
      'interpolate', ['linear'], ['zoom'],
      12, 30,
      13, 100,
      14, 200,
      15, 300,
      16, 500,
    ],
    'text-allow-overlap': {
      stops: [
        [13, false],
        [15, true],
      ],
    } as unknown as boolean,
    'text-field': ['get', 'nom_voie'],
    'text-transform': 'uppercase',
    'text-letter-spacing': 0.05,
    'text-offset': [
      'interpolate',
      ['linear'],
      ['zoom'],
      13,
      ['literal', [0, -0.5]],
      15,
      ['literal', [0, -1]],
    ],
  },
  paint: {
    'text-color': 'white',
  },
}

export default function PlanDeVoies() : ReactElement {
  const { objectsLayer } = useSelector((state: RootState) => state.objects)
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)
  const tivObject: ResizableObjects = objectsLayer.filter((object: ResizableObjects) => object.label
   === Objects.Tiv)[0]

  return (
    <>
      <Source
        type="vector"
        url={`${MAIN_API.proxy}/chartis/v2/layer/${ObjectsLayer.tiv}/mvt/sch/?perimeter=${activePerimeter?.slug}`}
        source-layer={ObjectsLayer.tiv}
        id={ObjectsLayer.tiv}
      >
        <Layer {...tivVSLayer} source-layer={ObjectsLayer.tiv} />
        <Layer {...tivVPLayer} source-layer={ObjectsLayer.tiv} />
        <Layer
          {...{
            ...truckNameLayer,
            layout: {
              ...truckNameLayer.layout,
              visibility: tivObject.display ? 'visible' : 'none',
              'text-size': {
                stops: getStops(tivObject?.size),
              },
            },
          }}
          source-layer={ObjectsLayer.tiv}
        />
      </Source>
    </>
  )
}
