export const MAIN_API = {
  proxy: process.env.REACT_APP_GATEWAY_URL || '',
  api: process.env.REACT_APP_USE_LOCAL_BACKEND
    ? `http://${window.location.hostname}:8000`
    : process.env.REACT_APP_GATEWAY_URL,
  version: '0.0.1.20200408.1',
}

export const PORTAL_URL = {
  url: process.env.REACT_APP_PORTAL_URL,
}

export const STREAM_URL = {
  url: process.env.REACT_APP_STREAM_URL,
}

export const GATEWAY_DOMAIN = {
  url: process.env.REACT_APP_GATEWAY_DOMAIN,
}

export default MAIN_API
