import MAIN_API from 'config/config'
import { MapRequest } from 'react-map-gl'
import { ScreenSize } from 'components/Common/ToolsBar/types'

const isTileserverResource = (type: string) => (type === 'Source' || type === 'Tile')

const replaceUrl = (url: string): string => {
  if (!MAIN_API.proxy.includes('.dev.')) {
    const newEnv = MAIN_API.proxy.includes('staging') ? 'staging.dgexsol.' : 'dgexsol.'
    return url.replace('dev.dgexsol.', newEnv)
  }
  return url
}

export const transformRequest = (url: string | undefined, resourceType: string | undefined): MapRequest => {
  if (url === undefined) return { url: '' }
  if ((isTileserverResource(resourceType as string))) {
    const newUrl = url.startsWith(MAIN_API.proxy) ? url : replaceUrl(url)
    return {
      url: newUrl,
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
    }
  }

  if (resourceType === 'Glyphs' || resourceType?.includes('Sprite')) {
    return { url: replaceUrl(url) }
  }
  return { url }
}

export const getSize = objectSize => {
  switch (objectSize) {
    case ScreenSize.Small: return 1.4
    case ScreenSize.Medium: return 1.8
    case ScreenSize.Large: return 2.2
    case ScreenSize.Xlarge: return 2.6
    default: return 1
  }
}

export const getStops = (objectSize, factor = 1) => {
  const zoomLevel = [13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5,
    19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5, 24, 24.5]
  return zoomLevel.map((z, i) => [z, (7 + i) * getSize(objectSize) * factor])
}
