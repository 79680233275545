import Map from 'components/Map/Map'
import { ReactElement, useState } from 'react'
import { WidgetView } from 'reducers/types'
import WidgetTools from './WidgetTools/WidgetTools'

type Props = {
  params: WidgetView
}

export default function Widget({ params }: Props): ReactElement {
  const [shortTrain, setShortTrain] = useState<boolean>(params.short_train)

  return (
    <>
      <WidgetTools widget={params} shortTrain={shortTrain} setShortTrain={setShortTrain} />
      <Map viewportProp={params.viewport} widget={params} shortTrain={shortTrain} />
    </>
  )
}
