import { ReactElement } from 'react'
import { Close } from '@mui/icons-material'
import { Alert } from 'reducers/types'
import { ReactComponent as LogoTrain } from 'assets/logos/logo-train.svg'
import './AlertNotification.scss'

interface Props {
  alert: Alert
  handleClose?: () => void
}

const AlertNotification = ({ alert, handleClose }: Props): ReactElement => (
  <div className={`alert-notification ${alert.code === 1001 ? 'secondary' : ''}`}>
    <div className="logo">
      <LogoTrain />
    </div>
    <div className="content">
      <div className="title">
        <span>{alert.issue}</span>
        {handleClose && (
          <button type="button" onClick={handleClose}>
            <Close />
          </button>
        )}
      </div>
      <div className="message">
        <span>{alert.details}</span>
      </div>
      <div className="hour">
        <span>{alert.timestamp}</span>
      </div>
    </div>
  </div>
)

AlertNotification.defaultProps = {
  handleClose: undefined,
}

export default AlertNotification
