/* eslint-disable max-len */
import { ReactElement, useEffect, useRef } from 'react'
import 'App.scss'
import _ from 'lodash'
import jsonpatch from 'fast-json-patch'
import {
  updateBasicDataStream, updateBasicStreamState, toggleBasicReOpen, updateBasicAlerts,
} from 'reducers/stream'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { GATEWAY_DOMAIN } from 'config/config'

export default function BasicStream(): ReactElement {
  const dispatch = useDispatch()
  const basicReOpen = useSelector((state: RootState) => state.stream.basicReOpen)
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)
  let basicDataStream
  const wsBasic: any = useRef()

  useEffect(() => {
    wsBasic.current = new WebSocket(
      `wss://${GATEWAY_DOMAIN.url}/open_tco/data_stream/basic/${activePerimeter?.slug}/?_auth_token=${`${localStorage.getItem(
        'access_token',
      )}`}`,
    )
    wsBasic.current.onopen = () => dispatch(updateBasicStreamState(true))
    wsBasic.current.onmessage = (event: { data: any }) => {
      const response = JSON.parse(event.data)
      if (basicDataStream === undefined) {
        basicDataStream = {}
      }
      if (response.op === 'alert') {
        dispatch(updateBasicAlerts(response))
      }
      if (response[0]?.op !== 'health' && response.op !== 'alert') {
        const newData = jsonpatch.applyPatch(_.cloneDeep(basicDataStream), response).newDocument
        basicDataStream = newData
        dispatch(updateBasicDataStream(newData))
      }
    }
    wsBasic.current.onclose = () => {
      dispatch(updateBasicStreamState(false))
      dispatch(toggleBasicReOpen(!basicReOpen))
      basicDataStream = {}
    }
    const wsBasicCurrent = wsBasic.current
    return () => {
      wsBasicCurrent.close()
    }
  }, [basicReOpen])

  return (
    <> </>
  )
}
