import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, ThemeProvider,
} from '@mui/material'
import { customCancelButton, customCreationButton } from 'common/CustomTheme'
import terms from 'common/terms'
import { Zone } from 'reducers/types'
import {
  setDashboardDescription, setDashboardName, setDefaultDashboard, setPatternError, setScreenSize, updateResponseError,
} from 'reducers/zone'
import { RootState } from 'Store'
import ZonesService from '../../reducers/services/zonesService'
import FormContent from './DashboardInputModal/FormContent'

type Props = {
  closeMenu: () => void
  zone: Zone
}

export default function UpdateZoneModal({ closeMenu, zone }: Props): ReactElement {
  const dispatch = useDispatch()
  const [open, setOpen] = useState<boolean>(false)
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)
  const {
    dashboardName, dashboardDescription, defaultDashboard, screenSize, patternError, responseError, updatedZone,
  } = useSelector((state: RootState) => state.zone)

  useEffect(() => {
    dispatch(setDashboardName(zone.label))
    dispatch(setDashboardDescription(zone.description))
    dispatch(setDefaultDashboard(zone.default))
    dispatch(setScreenSize(zone.screen_size))
  }, [zone])

  const handleOpen = () => setOpen(true)
  const onCancel = () => {
    setOpen(false)
    dispatch(setDashboardName(''))
    dispatch(setDashboardDescription(''))
    dispatch(setDefaultDashboard(false))
    dispatch(setScreenSize(''))
    dispatch(updateResponseError())
    dispatch(setPatternError(false))
    closeMenu()
  }
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') { return }
    onCancel()
  }
  useEffect(() => {
    if (!responseError && open) onCancel()
  }, [responseError, updatedZone])

  const updateDashboard = async () => {
    let zoneToUpdate = {}
    if (dashboardName !== zone.label) zoneToUpdate = { ...zoneToUpdate, label: dashboardName }
    if (dashboardDescription !== zone.description) zoneToUpdate = { ...zoneToUpdate, description: dashboardDescription }
    if (defaultDashboard !== zone.default) zoneToUpdate = { ...zoneToUpdate, default: defaultDashboard }
    if (screenSize !== zone.screen_size) zoneToUpdate = { ...zoneToUpdate, screen_size: screenSize }

    const params = {
      perimeterSlug: activePerimeter.slug,
      zonePk: zone.pk,
      zone: zoneToUpdate,
    }
    dispatch(ZonesService.updateZone(params))
  }

  return (
    <>
      <Button
        onClick={handleOpen}
        className="actions-menu-btn"
      >
        {terms.Common.modify}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ m: '10px' }}
      >
        <DialogTitle id="alert-dialog-title" className="modal-title">
          {terms.Dashboard.updateDashboard}
        </DialogTitle>
        <DialogContent>
          <FormContent />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', p: '20px 24px' }}>
          <ThemeProvider theme={customCancelButton}>
            <Button onClick={onCancel}>
              {terms.Common.cancel}
            </Button>
          </ThemeProvider>
          <ThemeProvider theme={customCreationButton}>
            <Button
              onClick={updateDashboard}
              disabled={dashboardName === '' || patternError}
            >
              {terms.Dashboard.updateButton}
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
    </>
  )
}
