import { ScreenSize } from 'components/Common/ToolsBar/types'

/* eslint-disable max-len */

export const getScale = size => {
  switch (size) {
    case ScreenSize.XSmall: return 0.4
    case ScreenSize.Small: return 0.5
    case ScreenSize.Medium: return 0.75
    case ScreenSize.Large: return 1
    case ScreenSize.Xlarge: return 1.25
    case ScreenSize.XXLarge: return 1.5
    default:
      return 0.3
  }
}
// CartoucheSetup.tsx
export const getHeadParis = () => 'M24.09 1.5789H101.0526V30H8.3796C3.1867 30 0.2128 24.0817 3.3116 19.9151L12.6973 7.3089C15.3686 3.7037 19.5969 1.5789 24.09 1.5789 1Z'

// CartoucheSetup.tsx
export const getHeadOthers = () => 'M78.5415 1.5789H1.5789V30H94.2519C99.4447 30 102.4187 24.0817 99.3198 19.9151L89.9442 7.3089C87.2628 3.7037 83.0346 1.5789 78.5415 1.5789 1Z'

export const getHeadUnknown = () => 'M1.5 1.5 H100 V30 H1.5 V30 Z'

// index.tsx
export const getClustersRadius = size => {
  switch (size) {
    case ScreenSize.Small: return 0.4
    case ScreenSize.Medium: return 0.6
    case ScreenSize.Large: return 0.8
    case ScreenSize.Xlarge: return 1
    default: return 0.2
  }
}

// index.tsx
export const getDisparationDelay = size => {
  switch (size) {
    case ScreenSize.XSmall: return 1
    case ScreenSize.Small: return 2
    case ScreenSize.Medium: return 3
    case ScreenSize.Large: return 5
    case ScreenSize.Xlarge: return 15
    case ScreenSize.XXLarge: return 30
    default: return 0.5
  }
}

// CartoucheMarker.tsx
export const getCartoucheOffsetTop = size => getScale(size) * -16

export const getCartoucheOffsetLeft = size => getScale(size) * -121

export const getCartoucheOffsetLeftShortTrain = size => getScale(size) * -51
