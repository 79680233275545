import { ReactElement } from 'react'
import {
  Checkbox, FormControlLabel, FormGroup, Grid, Typography,
} from '@mui/material'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { setScreenSize } from 'reducers/zone'
import ScreenSize from './ScreenSize'

export default function ScreenSizeCheckbox(): ReactElement {
  const dispatch = useDispatch()
  const { screenSize } = useSelector((state: RootState) => state.zone)

  const handleChange = (value: string) => {
    if (value === screenSize) {
      dispatch(setScreenSize(''))
    } else {
      dispatch(setScreenSize(value))
    }
  }

  return (
    <Grid sx={{ mt: '10px' }}>
      <Typography sx={{ fontSize: '12px', color: '#868686' }}>
        {terms.Dashboard.screenChoice}
      </Typography>
      {ScreenSize.map(size => (
        <FormGroup key={size.key}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={size.key === screenSize}
                onChange={() => handleChange(size.key)}
                sx={{ color: '#C8C8C8' }}
              />
              )}
            label={size.value}
          />
        </FormGroup>
      ))}
    </Grid>
  )
}
