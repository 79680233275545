import { ReactElement, useEffect, useState } from 'react'
import {
  Box, Button, IconButton, Modal, ThemeProvider,
} from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { useDispatch, useSelector } from 'react-redux'
import WidgetsService from 'reducers/services/widgetsService'
import { WidgetView } from 'reducers/types'
import { RootState } from 'Store'
import { updateResponseError } from 'reducers/widget'
import terms from 'common/terms'
import SimpleInput from 'components/Common/Input/SimpleInput'
import { customCancelButton, customCreationButton } from 'common/CustomTheme'

type Props = {
  widget: WidgetView
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '16px 24px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
}

export default function UpdateWidgetModal({ widget }: Props): ReactElement {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [widgetName, setWidgetName] = useState<string>(widget.label)
  const [patternError, setPatternError] = useState<boolean>(false)
  const { activeZone } = useSelector((state: RootState) => state.zone)
  const { lock } = useSelector((state: RootState) => state.widget)
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)
  const { responseError, updatedWidget } = useSelector((state: RootState) => state.widget)
  const handleOpen = () => {
    setOpen(true)
    setWidgetName(widget.label)
  }

  const onCancel = () => {
    setOpen(false)
    setWidgetName('')
    dispatch(updateResponseError())
    setPatternError(false)
  }
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') { return }
    onCancel()
  }

  useEffect(() => {
    if (!responseError && open) onCancel()
  }, [responseError, updatedWidget])

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const patternMatcher = event.target.value
      .match('^[a-zA-Z0-9ÀÂÄÇÉÈÊËÎÏÔÖÙÛÜŸÆŒàâäçéèêëîïôöùûüÿæœ._~:/?#@!$&\'[\\]()*+,;=-\\s]+')
    setWidgetName(event.target.value)
    setPatternError(false)
    if ((patternMatcher && patternMatcher[0].length !== event.target.value.length)
     || (patternMatcher === null && event.target.value !== '')) {
      setPatternError(true)
    }
  }

  const updateWidget = async () => {
    const params = {
      perimeterSlug: activePerimeter?.slug,
      zonePk: activeZone?.pk,
      widgetSlug: widget.slug,
      widget: {
        label: widgetName,
      },
    }
    dispatch(WidgetsService.updateWidget(params))
  }
  return (
    <>
      <IconButton onClick={handleOpen} disabled={!lock}>
        <EditOutlinedIcon sx={{ color: lock ? 'white' : '#00000042' }} />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        disablePortal
      >
        <Box className="modal-widget" sx={style}>
          <strong className="modal-widget-title">
            {`${terms.Widget.renameWidget} ${widget.label}`}
          </strong>
          <SimpleInput
            label={terms.Common.name}
            value={widgetName}
            onChange={handleNameChange}
            responseError={responseError}
            patternError={patternError}
          />
          <div className="actions">
            <ThemeProvider theme={customCancelButton}>
              <Button onClick={onCancel}>
                {terms.Common.cancel}
              </Button>
            </ThemeProvider>
            <ThemeProvider theme={customCreationButton}>
              <Button
                onClick={updateWidget}
                disabled={widgetName === '' || patternError || widgetName === widget.label}
              >
                {terms.Common.update}
              </Button>
            </ThemeProvider>
          </div>
        </Box>
      </Modal>
    </>
  )
}
