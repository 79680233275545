import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Alert, AlertsState } from './types'

const initialState: AlertsState = {
  alertsList: [],
  settings: {
    volume: 50,
    soundAlerts: false,
    textAlerts: false,
    notifiedStations: [],
  },
  newAlert: undefined,
  alertsReopen: false,
}

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    updateAlertsList: (state, action: PayloadAction<Array<Alert>>) => {
      state.alertsList = action.payload
    },
    updateSettings: (state, action: PayloadAction<AlertsState['settings']>) => {
      state.settings = action.payload
    },
    receiveNewAlert: (state, action: PayloadAction<Alert>) => {
      const newAlert = {
        ...action.payload,
        id: `${action.payload.code}-${action.payload.timestamp}`,
      }
      state.newAlert = newAlert
      state.alertsList = [newAlert, ...state.alertsList]
    },
    receivePreviousAlerts: (state, action: PayloadAction<Alert[]>) => {
      state.alertsList = [...action.payload]
    },
    resetState: state => {
      state.alertsList = []
      state.newAlert = undefined
    },
    toggleAlertsReopen: state => {
      state.alertsReopen = !state.alertsReopen
    },
  },
})

export const {
  updateAlertsList, updateSettings, receiveNewAlert, resetState,
  receivePreviousAlerts, toggleAlertsReopen,
} = alertsSlice.actions

export default alertsSlice.reducer
