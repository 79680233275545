/* eslint-disable camelcase */
import {
  Box, Checkbox, FormControlLabel, Modal,
  TextField,
} from '@mui/material'
import { post } from '@osrdata/app_core/dist/requests'
import { hasAdminAccess } from 'helpers/permissions'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { useState } from 'react'
import { CommunicationMessage } from 'types'
import './CommunicationMessage.scss'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  minWidth: 500,
  boxShadow: 24,
  padding: '24px',
  gap: '24px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '4px',
}

const emptyMessage = {
  title: '',
  message: '',
  date_start: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
  date_end: moment(new Date()).add(1, 'week').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
}

interface Props {
  message: CommunicationMessage | null
  open: boolean
  handleClose: () => void
}

const CommunicationMessageModal = ({ message, open, handleClose }: Props) => {
  const [editMode, setEditMode] = useState(false)
  const [checkedMessage, setCheckedMessage] = useState(false)
  const [newMessage, setNewMessage] = useState<{
    title: string,
    message: string,
    date_start: string,
    date_end: string,
  }>(emptyMessage)

  const seenMessage = localStorage.getItem('communicationMessageSeenId') === message?.pk?.toString()

  const onClose = (event, reason) => {
    if (reason === 'backdropClick') return
    handleClose()
  }

  const handleClickSeen = () => {
    handleClose()
    if (message.pk) {
      localStorage.setItem('communicationMessageSeenId', message.pk.toString())
    }
  }

  const handlePostMessage = () => {
    post('/open_tco/communication/', newMessage).then(() => {
      handleClose()
    })
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box className="communication-message" sx={style}>
        {editMode ? (
          <>
            <div className="form">
              <TextField
                id="title"
                label="Titre"
                value={newMessage.title}
                onChange={e => setNewMessage({ ...newMessage, title: e.target.value })}
              />
              <TextField
                id="message"
                label="Message"
                multiline
                rows={4}
                value={newMessage.message}
                onChange={e => setNewMessage({ ...newMessage, message: e.target.value })}
              />
              <div className="select-date">
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
                  <span>Afficher du :</span>
                  <DateTimePicker
                    value={new Date(newMessage.date_start)}
                    onChange={date => setNewMessage(
                      { ...newMessage, date_start: moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ') },
                    )}
                    renderInput={params => <TextField {...params} />}
                  />
                  <span>au :</span>
                  <DateTimePicker
                    value={new Date(newMessage.date_end)}
                    onChange={date => setNewMessage(
                      { ...newMessage, date_end: moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ') },
                    )}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="actions">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setEditMode(false)
                  setNewMessage(emptyMessage)
                }}
              >
                Annuler
              </button>
              <button type="button" className="btn btn-primary" onClick={handlePostMessage}>
                Enregistrer
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="header">
              <h1>{message?.title || 'Pas de titre'}</h1>
              {hasAdminAccess() && (
              <button
                type="button"
                className="edit-button"
                onClick={() => setEditMode(!editMode)}
              >
                Modifier le message
              </button>
              )}
            </div>
            <p>{message?.message || 'Pas de message'}</p>
            <div className="footer">
              {!seenMessage && (
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={checkedMessage}
                      onClick={() => setCheckedMessage(!checkedMessage)}
                    />
                )}
                  label="J’ai pris connaissance du message ci-dessus"
                />
              )}
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleClickSeen}
                disabled={!checkedMessage && !seenMessage}
              >
                {seenMessage ? 'Fermer' : 'Acquitter'}
              </button>
            </div>
          </>
        )}

      </Box>
    </Modal>
  )
}

export default CommunicationMessageModal
