/* eslint-disable max-len */
import { MutableRefObject, ReactElement } from 'react'
import { MapRef } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { ResizableObjects } from 'reducers/types'
import { Objects } from 'components/Common/ToolsBar/types'
import {
  getClustersRadius, getDisparationDelay, getScale,
} from './utils'
import { CLUSTERS_POPUP_LATITUDE_LIMIT } from '../DefaultValues'
import CartoucheCluster from './CartoucheCluster'

type Props = {
  viewportZoom: number,
  mapRef: MutableRefObject<MapRef | undefined> | undefined,
  shortTrain: boolean
}

export default function Cartouches({
  viewportZoom, mapRef, shortTrain,
}: Props): ReactElement {
  const { basicDataStream } = useSelector((state: RootState) => state.stream)
  const { objectsLayer } = useSelector((state : RootState) => state.objects)
  const { hiddenCartouches } = useSelector((state: RootState) => state.zone)
  const cartoucheObject: ResizableObjects = objectsLayer.filter((object: ResizableObjects) => object.label === Objects.Cartouche)[0]
  const agregationObject: ResizableObjects = objectsLayer.filter((object: ResizableObjects) => object.label === Objects.Agregation)[0]
  const disparitionDelayObject: ResizableObjects = objectsLayer.filter((object: ResizableObjects) => object.label === Objects.DisparitionDelay)[0]
  const latitudeLimit = CLUSTERS_POPUP_LATITUDE_LIMIT
  const cartouchesClustersRadius = !agregationObject.display ? 0 : getClustersRadius(agregationObject.size) * (shortTrain ? 102 * getScale(cartoucheObject.size) : 242 * getScale(cartoucheObject.size))
  const acceptableDelay = disparitionDelayObject?.display
    ? Date.now() - (getDisparationDelay(disparitionDelayObject.size) * 60 * 1000)
    : Date.now() - (240 * 60 * 1000)

  const cartouchesNotNull = basicDataStream?.features?.filter(cartouche => cartouche.properties.numero_marche != null) || []
  const cartouchesValids = cartouchesNotNull?.filter(cartouche => Date.parse(cartouche.properties.date_heure_observee) > acceptableDelay) || []
  const cartouchesNotHidden = []
  cartouchesValids?.forEach(cartouche => {
    const { id } = cartouche.properties
    if (!hiddenCartouches.includes(id)) cartouchesNotHidden.push(cartouche)
  })

  const cartouchesTop = cartouchesNotHidden?.filter(cartouche => cartouche.geometry.coordinates[1] < latitudeLimit)
  const cartouchesBottom = cartouchesNotHidden?.filter(cartouche => cartouche.geometry.coordinates[1] > latitudeLimit)

  const pointsTop: any = cartouchesTop?.map((cartoucheTop, id) => ({
    type: 'Feature',
    properties: {
      cluster: false,
      cartoucheId: id,
      direction: cartoucheTop.properties?.vers_paris,
      trainNumber: cartoucheTop.properties?.numero_marche,
      fenetre: cartoucheTop.properties?.num_fenetre,
      railway: cartoucheTop.properties?.voie,
      delay: cartoucheTop.properties?.ecart_horaire,
      timeObserved: cartoucheTop.properties?.date_heure_observee,
      source: cartoucheTop.properties?.source,
      id: cartoucheTop.properties?.id,
    },
    geometry: {
      type: cartoucheTop.geometry.type,
      coordinates: [
        cartoucheTop.geometry.coordinates[0],
        cartoucheTop.geometry.coordinates[1],
      ],
    },
  }))

  const pointsBottom: any = cartouchesBottom?.map((cartoucheBottom, id) => ({
    type: 'Feature',
    properties: {
      cluster: false,
      cartoucheId: id,
      direction: cartoucheBottom.properties?.vers_paris,
      trainNumber: cartoucheBottom.properties?.numero_marche,
      fenetre: cartoucheBottom.properties?.num_fenetre,
      railway: cartoucheBottom.properties?.voie,
      delay: cartoucheBottom.properties?.ecart_horaire,
      timeObserved: cartoucheBottom.properties?.date_heure_observee,
      source: cartoucheBottom.properties?.source,
      id: cartoucheBottom.properties?.id,
    },
    geometry: {
      type: cartoucheBottom.geometry.type,
      coordinates: [
        cartoucheBottom.geometry.coordinates[0],
        cartoucheBottom.geometry.coordinates[1],
      ],
    },
  }))

  const bounds = mapRef?.current
    ? mapRef.current
      .getMap()
      .getBounds()
      .toArray()
      .flat()
    : null

  return (
    <CartoucheCluster
      viewportZoom={viewportZoom}
      pointsTop={pointsTop}
      pointsBottom={pointsBottom}
      bounds={bounds}
      cartouchesClustersRadius={cartouchesClustersRadius}
      shortTrain={shortTrain}
    />
  )
}
