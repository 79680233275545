import { ReactElement, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { Perimeter } from 'reducers/types'
import { useDispatch } from 'react-redux'
import terms from 'common/terms'
import './perimeters.scss'
import { ThemeProvider } from '@mui/material'
import { customCancelButton, customDeletionButton } from 'common/CustomTheme'
import PerimetersService from '../../reducers/services/perimetersService'

type Props = {
  perimeter: Perimeter,
  closeMenu: any,
}

export default function DeletePerimeterModal({ perimeter, closeMenu }: Props): ReactElement {
  const dispatch = useDispatch()
  const deletePerimeter = perimeter.slug !== undefined ? perimeter.slug : ''

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleMenuClose = () => {
    closeMenu()
    setOpen(false)
  }

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') { return }
    handleMenuClose()
  }

  const removePerimeter = async () => {
    closeMenu()
    dispatch(PerimetersService.deletePerimeter(deletePerimeter))
    handleMenuClose()
  }

  return (
    <>
      <div className="menu-button" role="button" tabIndex={0} onClick={handleOpen}>
        {terms.Perimeter.deletePerimeter}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ m: '10px' }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ maxWidth: '400px' }}>
          {terms.Perimeter.deletePerimeterNotice}
          {' '}
          {perimeter.label}
          {' '}
          ?
        </DialogTitle>
        <DialogActions sx={{ justifyContent: 'center', m: '20px' }}>
          <ThemeProvider theme={customCancelButton}>
            <Button onClick={handleMenuClose}>
              {terms.Common.cancel}
            </Button>
          </ThemeProvider>
          <ThemeProvider theme={customDeletionButton}>
            <Button onClick={removePerimeter}>
              {terms.Common.delete}
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
    </>
  )
}
