/* eslint-disable max-len */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from 'types'
import {
  deleteRequest, get, post, patch,
} from '@osrdata/app_core/dist/requests/index'
import { DashboardParams, GetDashboardParams, Zone } from 'reducers/types'

const getZonesList = createAsyncThunk<Array<Zone>, GetDashboardParams>(
  'zones/getZonesList',
  async (params: GetDashboardParams) => {
    const response = await get(`/open_tco/perimeters/${params.perimeterSlug}/zones/`, { ordering: params.ordering })
    return response.results
  },
)

const createZone = createAsyncThunk<Zone, DashboardParams, ThunkApi>(
  'zones/create',
  async (params: DashboardParams, thunkApi) => {
    try {
      const response: Zone = await post(`/open_tco/perimeters/${params.perimeterSlug}/zones/`, params.zone)
      return response
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const updateZone = createAsyncThunk<any, DashboardParams, ThunkApi>(
  'zone/update',
  async (params: DashboardParams, thunkApi) => {
    try {
      const response: Zone = await patch(`/open_tco/perimeters/${params.perimeterSlug}/zones/${params.zonePk}`, params.zone)
      return { response, oldPk: params.zonePk }
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const deleteZone = createAsyncThunk<Zone, any, ThunkApi>(
  'zones/delete',
  async (deletedZone: any, thunkApi) => {
    try {
      await deleteRequest(`/open_tco/perimeters/${deletedZone.perimeterSlug}/zones/${deletedZone.zonePk}`)
      return deletedZone.zonePk
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const ZonesService = {
  getZonesList,
  createZone,
  updateZone,
  deleteZone,
}

export default ZonesService
