import { ReactElement, useEffect, useState } from 'react'
import {
  Box, Button, IconButton, Modal, ThemeProvider,
} from '@mui/material'
import { customCancelButton, customCreationButton } from 'common/CustomTheme'
import terms from 'common/terms'
import SimpleInput from 'components/Common/Input/SimpleInput'
import { useDispatch, useSelector } from 'react-redux'
import { updateResponseError } from 'reducers/widget'
import { RootState } from 'Store'
import AddIcon from '@mui/icons-material/Add'
import { DEFAULT_VIEWPORT } from 'components/Map/DefaultValues'
import WidgetsService from '../../reducers/services/widgetsService'
import AddFirstWidgetCard from './AddFirstWidgetCard'

import './AddWidgetModal.scss'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '16px 24px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
}

export default function AddWidgetModal(): ReactElement {
  const dispatch = useDispatch()
  const { activeZone } = useSelector((state:RootState) => state.zone)
  const { activePerimeter } = useSelector((state:RootState) => state.perimeter)
  const { responseError, widgetsList } = useSelector((state:RootState) => state.widget)
  const [open, setOpen] = useState<boolean>(false)
  const [patternError, setPatternError] = useState<boolean>(false)
  const [widgetName, setWidgetName] = useState<string>('')

  const handleOpen = () => setOpen(true)
  const onCancel = () => {
    setOpen(false)
    setWidgetName('')
    dispatch(updateResponseError())
    setPatternError(false)
  }

  useEffect(() => {
    if (!responseError && open) onCancel()
  }, [responseError, widgetsList])

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') { return }
    onCancel()
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const patternMatcher = event.target.value
      .match('^[a-zA-Z0-9ÀÂÄÇÉÈÊËÎÏÔÖÙÛÜŸÆŒàâäçéèêëîïôöùûüÿæœ._~:/?#@!$&\'[\\]()*+,;=-\\s]+')
    setWidgetName(event.target.value)
    setPatternError(false)
    if ((patternMatcher && patternMatcher[0].length !== event.target.value.length)
     || (patternMatcher === null && event.target.value !== '')) {
      setPatternError(true)
    }
  }

  const addWidget = event => {
    event.preventDefault()
    const params = {
      widget: {
        label: widgetName,
        viewport: activePerimeter?.viewport || DEFAULT_VIEWPORT,
        layout_position: {
          h: 10,
          w: 15,
          x: 0,
          y: 2,
          min_h: 2,
          min_w: 2,
          moved: false,
          static: false,
        },
      },
      perimeterSlug: activePerimeter?.slug,
      zonePk: activeZone?.pk,
    }

    dispatch(WidgetsService.createWidget(params))
  }

  return (
    <>
      {widgetsList.length !== 0 ? (
        <IconButton sx={{ color: 'white' }} onClick={handleOpen}>
          <AddIcon />
        </IconButton>
      ) : <AddFirstWidgetCard onClick={handleOpen} />}

      <Modal
        open={open}
        onClose={handleClose}
        disablePortal
      >
        <Box className="modal-widget" sx={style}>
          <strong className="modal-widget-title">{terms.Widget.createWidget}</strong>
          <SimpleInput
            label={terms.Common.name}
            value={widgetName}
            onChange={handleNameChange}
            responseError={responseError}
            patternError={patternError}
          />
          <div className="actions">
            <ThemeProvider theme={customCancelButton}>
              <Button onClick={onCancel}>
                {terms.Common.cancel}
              </Button>
            </ThemeProvider>
            <ThemeProvider theme={customCreationButton}>
              <Button onClick={addWidget} disabled={widgetName === '' || patternError}>
                {terms.Widget.createWidgetButton}
              </Button>
            </ThemeProvider>
          </div>
        </Box>
      </Modal>
    </>
  )
}
