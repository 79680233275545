import { ReactElement } from 'react'
import { Layer, LayerProps, Source } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import ObjectsLayer from './types'

const bvsLayer: LayerProps = {
  id: ObjectsLayer.bv,
  type: 'fill',
  paint: {
    'fill-color': '#FFD447',
    'fill-opacity': 0.2,
  },
}

export default function Bvs(): ReactElement {
  const { bvLayer } = useSelector((state: RootState) => state.perimeter)
  return (
    <>
      <Source id={ObjectsLayer.bv} type="geojson" data={bvLayer}>
        <Layer {...bvsLayer} />
      </Source>
    </>
  )
}
