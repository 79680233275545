import { Grid } from '@mui/material'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import DashboardCard from './DashboardCard'
import AddZoneModal from './AddZoneModal'

export default function DefaultDahsboardCardList(): ReactElement {
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)
  const { zonesList } = useSelector((state: RootState) => state.zone)
  return (
    <Grid
      item
      container
      xs={12}
      sm={10}
      md={8}
      spacing={1}
      sx={{ alignContent: 'flex-start', justifyContent: 'flex-start', p: '60px' }}
    >
      {!activePerimeter?.camera_only && (
        <DashboardCard
          path={`/${activePerimeter.slug}/FullView`}
          fullView
        />
      )}
      {activePerimeter?.cameras?.length > 0 && (
        <DashboardCard
          path={`/${activePerimeter.slug}/camera`}
          cameraView
        />
      )}
      {zonesList.length !== 0 && zonesList.filter(zone => zone.default).length !== 0 && (
        zonesList.filter(zone => zone.default).map(item => (
          <DashboardCard
            key={item.pk}
            zone={item}
            path={`/${activePerimeter.slug}/${item.pk}`}
          />
        ))
      )}
      <Grid
        item
        xs={10}
        sm={6}
        md={6}
        lg={4}
        xl={3}
        sx={{ justifyContent: 'center' }}
      >
        <AddZoneModal />
      </Grid>
    </Grid>
  )
}
