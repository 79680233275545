import { RootState } from 'Store'
import { ReactElement } from 'react'
import { Layer, LayerProps, Source } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { Objects, ScreenSize } from 'components/Common/ToolsBar/types'
import ObjectsLayer from './types'

const trackLabelLayer: LayerProps = {
  id: ObjectsLayer.trackLabel,
  type: 'symbol',
  layout: {
    'text-field': ['get', 'voie_label'],
    'text-font': ['Open Sans Regular'],
    'text-allow-overlap': true,
    'text-transform': 'uppercase',
    'text-size': 12,
  },
  paint: {
    'text-color': 'white',
  },
}

const trackLayerCircle: LayerProps = {
  id: `${ObjectsLayer.trackLabel}-circle`,
  type: 'circle',
  paint: {
    'circle-radius': 12,
    'circle-color': 'black',
    'circle-stroke-color': 'white',
    'circle-stroke-width': 2,
  },

}

export default function TrackLabels(): ReactElement {
  const { objectsLayer } = useSelector((state: RootState) => state.objects)
  const { trackLabelsLayer } = useSelector((state: RootState) => state.perimeter)
  const trackObject = objectsLayer.find(object => object.label === Objects.Tiv)

  const sizeRatio = () => {
    switch (trackObject?.size) {
      case ScreenSize.Small: return 0.8
      case ScreenSize.Medium: return 1
      case ScreenSize.Large: return 1.2
      case ScreenSize.Xlarge: return 1.4
      default: return 0.6
    }
  }

  return (
    <Source id={ObjectsLayer.trackLabel} type="geojson" data={trackLabelsLayer}>
      <Layer
        {...trackLayerCircle}
        layout={{
          visibility: trackObject?.display ? 'visible' : 'none',
        }}
        paint={{
          ...trackLayerCircle.paint,
          'circle-radius': 12 * sizeRatio(),
          'circle-stroke-width': 2 * sizeRatio(),
        }}
      />
      <Layer
        {...trackLabelLayer}
        layout={{
          ...trackLabelLayer.layout,
          visibility: trackObject?.display ? 'visible' : 'none',
          'text-size': 12 * sizeRatio(),
        }}
      />
    </Source>
  )
}
