import { ReactElement } from 'react'
import PositionLock from 'components/Widgets/WidgetTools/PositionLock'
import { WidgetView } from 'reducers/types'
import { hasAdminAccess, hasOperatorAccess } from 'helpers/permissions'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import DeleteWidgetModal from './DeleteWidgetModal'
import UpdateWidgetModal from './UpdateWidgetModal'

import '../widget.scss'
import ShortTrainButton from './ShortTrainButton'

type WidgetToolsProp = {
  widget: WidgetView
  shortTrain: boolean
  setShortTrain: (value: boolean) => void
}
export default function WidgetTools({ widget, shortTrain, setShortTrain }: WidgetToolsProp): ReactElement {
  const { activeZone } = useSelector((state: RootState) => state.zone)
  return (
    <>
      <span className="widget-label">{widget.label}</span>
      {activeZone?.default ? (hasAdminAccess() || hasOperatorAccess()) && (
      <Box className="widget-tools">
        <ShortTrainButton shortTrain={shortTrain} setShortTrain={setShortTrain} widget={widget} />
        <UpdateWidgetModal widget={widget} />
        <PositionLock widget={widget} />
        <DeleteWidgetModal widget={widget} />
      </Box>
      )
        : (
          <Box className="widget-tools">
            <ShortTrainButton shortTrain={shortTrain} setShortTrain={setShortTrain} widget={widget} />
            <UpdateWidgetModal widget={widget} />
            <PositionLock widget={widget} />
            <DeleteWidgetModal widget={widget} />
          </Box>
        )}
    </>
  )
}
