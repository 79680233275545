import { ReactElement, useEffect, useState } from 'react'
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, ThemeProvider,
} from '@mui/material'
import { customCancelButton, customCreationButton } from 'common/CustomTheme'
import terms from 'common/terms'
import AddButton from 'components/Common/Button/AddButton'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import {
  setDashboardDescription, setDashboardName, setDefaultDashboard, setPatternError, setScreenSize, updateResponseError,
} from 'reducers/zone'
import ZonesService from '../../reducers/services/zonesService'
import FormContent from './DashboardInputModal/FormContent'

export default function AddZoneModal(): ReactElement {
  const dispatch = useDispatch()
  const [open, setOpen] = useState<boolean>(false)
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)
  const {
    dashboardName, dashboardDescription, defaultDashboard, screenSize, responseError, zonesList,
  } = useSelector((state: RootState) => state.zone)

  const handleOpen = () => setOpen(true)
  const onCancel = () => {
    setOpen(false)
    dispatch(setDashboardName(''))
    dispatch(setDashboardDescription(''))
    dispatch(setDefaultDashboard(false))
    dispatch(setScreenSize(''))
    dispatch(updateResponseError())
    dispatch(setPatternError(false))
  }
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') { return }
    onCancel()
  }

  useEffect(() => {
    if (!responseError && open) onCancel()
  }, [responseError, zonesList])

  const addZone = event => {
    event.preventDefault()
    let zoneToCreate = {}
    if (dashboardDescription !== '') zoneToCreate = { ...zoneToCreate, description: dashboardDescription }
    if (screenSize !== '') zoneToCreate = { ...zoneToCreate, screen_size: screenSize }
    const params = {
      zone: {
        ...zoneToCreate,
        label: dashboardName,
        default: defaultDashboard,
      },
      perimeterSlug: activePerimeter.slug,
    }

    dispatch(ZonesService.createZone(params))
  }

  return (
    <>
      <AddButton handleOpen={handleOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ m: '10px' }}
      >
        <DialogTitle id="alert-dialog-title" className="modal-title">
          {terms.Dashboard.createDashboard}
        </DialogTitle>
        <DialogContent>
          <FormContent />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', p: '20px 24px' }}>
          <ThemeProvider theme={customCancelButton}>
            <Button onClick={onCancel}>
              {terms.Common.cancel}
            </Button>
          </ThemeProvider>
          <ThemeProvider theme={customCreationButton}>
            <Button onClick={addZone} disabled={dashboardName === ''}>
              {terms.Dashboard.creationButton}
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
    </>
  )
}
