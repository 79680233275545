import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import Stack from '@mui/material/Stack'
import AppBar from '@mui/material/AppBar'
import terms from 'common/terms'
import AddWidgetModal from 'components/Widgets/AddWidgetModal'
import { hasAdminAccess, hasOperatorAccess } from 'helpers/permissions'
import { Train } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import LayersSizeSidebar from './LayersSizeSidebar'

import FullscreenButton from './FullscreenButton'
import TcoInfos from './TcoInfos'
import WidgetsPositionLock from './WidgetsPositionLock'
import FluxAlerts from './FluxAlerts'

interface Props {
  showAlerts: boolean
  shortTrain?: { value: boolean, setter: (value: boolean) => void }
}

export default function ToolsBar({ showAlerts, shortTrain }: Props) : ReactElement {
  const { fullscreenHandler } = useSelector((state: RootState) => state.app)
  const { widgetsList } = useSelector((state: RootState) => state.widget)
  const { activeZone } = useSelector((state: RootState) => state.zone)

  return (
    <AppBar className={`toolsbar${fullscreenHandler.active ? ' fullscreen' : ''}`}>
      <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center', height: '65px' }}>
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          {(widgetsList.length !== 0 || activeZone?.label === terms.Dashboard.fullViewCard) && (
          <>
            {activeZone?.default ? (hasAdminAccess() || hasOperatorAccess()) && <LayersSizeSidebar />
              : <LayersSizeSidebar />}
            {shortTrain && (
            <IconButton
              type="button"
              className="toolbar-action-icon"
              onClick={() => shortTrain.setter(!shortTrain.value)}
            >
              <Train />
            </IconButton>
            )}
            <FullscreenButton />
          </>
          )}
          {widgetsList.length !== 0 && (
            activeZone?.default ? (hasAdminAccess() || hasOperatorAccess()) && <AddWidgetModal /> : <AddWidgetModal />
          )}
          {widgetsList.length !== 0 && (
            activeZone?.default ? (hasAdminAccess() || hasOperatorAccess()) && <WidgetsPositionLock />
              : <WidgetsPositionLock />
          )}

        </Stack>
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <FluxAlerts />
          <TcoInfos showAlerts={showAlerts} />
        </Stack>
      </Stack>
    </AppBar>
  )
}

ToolsBar.defaultProps = {
  shortTrain: undefined,
}
