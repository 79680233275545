import { ReactElement } from 'react'
import { Layer, LayerProps, Source } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { Objects, ScreenSize } from 'components/Common/ToolsBar/types'
import { ResizableObjects } from 'reducers/types'
import ObjectsLayer from './types'

export default function GareLabels(): ReactElement {
  const { prNameLayer } = useSelector((state: RootState) => state.perimeter)
  const { objectsLayer } = useSelector((state: RootState) => state.objects)
  const garesObject: ResizableObjects = objectsLayer?.filter((obj: ResizableObjects) => obj.label === Objects.Gare)[0]

  const getGareLabelFontSize = () => {
    switch (garesObject?.size) {
      case ScreenSize.Small: return 12
      case ScreenSize.Medium: return 16
      case ScreenSize.Large: return 20
      case ScreenSize.Xlarge: return 24
      default: return 8
    }
  }

  const bvLabelLayer: LayerProps = {
    id: ObjectsLayer.gareLabel,
    type: 'symbol',
    layout: {
      'text-size': getGareLabelFontSize(),
      'text-anchor': 'center',
      'symbol-placement': 'point',
      'text-allow-overlap': false,
      'text-field': '{station_label}',
      'text-font': [
        'Open Sans Regular',
      ],
      visibility: garesObject?.display ? 'visible' : 'none',
    },
    paint: {
      'text-color': 'rgb(255, 212, 71)',
    },
    minzoom: 12.5,
  }

  return (
    <>
      <Source id={ObjectsLayer.gareLabel} type="geojson" data={prNameLayer}>
        <Layer {...bvLabelLayer} />
      </Source>
    </>
  )
}
