import { ReactElement, useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { Perimeter } from 'reducers/types'
import { updateActivePerimeter } from 'reducers/perimeter'
import { hasAdminAccess } from 'helpers/permissions'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardActionArea from '@mui/material/CardActionArea'
import Typography from '@mui/material/Typography'
import history from '../../appHistory'
import SettingsMenu from './SettingsMenu'

type Props = {
  perimeter: Perimeter;
}

export default function CardItem({ perimeter }: Props): ReactElement {
  const dispatch = useDispatch()
  const [imageUrl, setImageUrl] = useState('')

  const getAuthConfig = () => ({
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  })

  const gotoPerimeter = () => {
    if (perimeter.camera_only) {
      history.push(`/${perimeter.slug}/camera`)
    } else {
      history.push(`/${perimeter.slug}/dashboard`)
    }
    dispatch(updateActivePerimeter(perimeter))
  }

  useEffect(() => {
    if (perimeter.image !== null) {
      const baseURL = perimeter.image
      const config = getAuthConfig()
      axios.get(baseURL, config)
        .then(response => {
          setImageUrl(response.data)
        })
        .catch(error => {
          if (error.response) {
            setImageUrl(error.response.data)
          } else if (error.request) {
            console.log('Error', error.message)
          } else {
            console.log('Error', error.message)
          }
        })
    }
  }, [perimeter.image])

  return (
    <>
      <Card
        sx={{ width: '90%', height: '220px' }}
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          backgroundSize: 'cover',
        }}
        className="perimeter-card perimeter-card-boxshadow"
      >
        {hasAdminAccess() && (
        <CardHeader
          avatar={(
            <SettingsMenu perimeter={perimeter} />
        )}
        />
        )}
        <CardActionArea
          aria-label="settings"
          onClick={() => gotoPerimeter()}
          className="card-action"
          sx={{
            width: '100%', height: '90%', justifyContent: 'center', alignContent: 'flex-end',
          }}
        >
          <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }}>
            {perimeter.label}
          </Typography>
        </CardActionArea>
      </Card>
    </>
  )
}
