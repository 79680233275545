import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Avenir',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
})

export const customCreationButton = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: 'white',
          borderRadius: '30px',
          padding: '6px 6px 6px 8px',
          fontFamily: 'Avenir',
          backgroundColor: '#4B80F0',
          width: '60%',
          '&:hover': {
            color: 'white',
            backgroundColor: '#4B80F0',
            border: '1px solid #4A81F0',
          },
          '&:focus': {
            outline: 'none',
          },
          '&:disabled': {
            color: '#b9b9b9',
            cursor: 'not-allowed',
            pointerEvents: 'visible',
          },
        },
      },
    },
  },
})

export const customCancelButton = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: 'black',
          border: '1px solid black',
          borderRadius: '30px',
          padding: '6px 6px 6px 8px',
          backgroundColor: 'white',
          width: '40%',
        },
      },
    },
  },
})

export const customDeletionButton = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: 'white',
          fontFamily: 'Avenir',
          borderRadius: '30px',
          padding: '6px 6px 6px 8px',
          backgroundColor: '#cd0037',
          width: '50%',
          '&:hover': {
            color: 'white',
            backgroundColor: '#cd0037',
            border: '1px solid #cd0037',
          },
          '&:focus': {
            outline: 'none',
          },
        },
      },
    },
  },
})

export const customOpenButton = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: 'white',
          fontFamily: 'Avenir',
          borderRadius: '30px',
          padding: '6px 6px 6px 8px',
          backgroundColor: '#4B80F0',
          width: '92px',
          height: '30px',
          '&:hover': {
            color: 'white',
            backgroundColor: '#4B80F0',
            border: '1px solid #4A81F0',
          },
          '&:focus': {
            outline: 'none',
          },
        },
      },
    },
  },
})

export const customButton = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: 'white',
          fontFamily: 'Avenir',
          borderRadius: '30px',
          padding: '21px 32px 21px 32px',
          backgroundColor: '#4B80F0',
          height: '44px',
          '&:hover': {
            color: 'white',
            backgroundColor: '#1285dc',
          },
          '&:focus': {
            outline: 'none',
          },
        },
      },
    },
  },
})

export const customActionsButton = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: '#6E6E6F',
          width: '24px',
          height: '24px',
          minWidth: '24px',
          padding: '0',
          border: 'none',
          '&:hover': {
            color: '#6E6E6F',
            border: 'none',
          },
          '&:focus': {
            outline: 'none',
          },
        },
      },
    },
  },
})

export const customPositionButton = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          display: 'flex',
          flexDirection: 'row',
          position: 'absolute',
          height: '38px',
          left: '190px',
          fontWeight: '400',
          fontSize: '14px',
          border: '1px solid #FFFFFF',
          borderRadius: '4px',
          color: 'white',
          boxShadow: '8px 8px 8px rgb(1 1 1 / 16%), 8px 8px 8px rgb(1 1 1 / 16%)',
          '&:hover': {
            color: 'white',
            border: '1px solid #FFFFFF',
          },
          '&:focus': {
            outline: 'none',
          },
        },
      },
    },
  },
})
