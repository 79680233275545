import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Dialog, DialogActions, DialogTitle, ThemeProvider,
} from '@mui/material'
import Button from '@mui/material/Button'
import { customCancelButton, customDeletionButton } from 'common/CustomTheme'
import terms from 'common/terms'
import { Zone } from 'reducers/types'
import { RootState } from 'Store'
import ZonesService from '../../reducers/services/zonesService'

type Props = {
  zone: Zone;
  closeMenu: () => void
}

export default function DeleteZoneModal({ zone, closeMenu }: Props): ReactElement {
  const dispatch = useDispatch()
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)
  const [open, setOpen] = useState<boolean>(false)

  const handleOpen = () => setOpen(true)
  const handleMenuClose = () => {
    closeMenu()
    setOpen(false)
  }

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') { return }
    handleMenuClose()
  }

  const removeDashboard = async () => {
    const deletedZone = {
      perimeterSlug: activePerimeter.slug,
      zonePk: zone.pk,
    }
    dispatch(ZonesService.deleteZone(deletedZone))
    handleMenuClose()
  }
  return (
    <>
      <Button
        onClick={handleOpen}
        className="actions-menu-btn"
      >
        {terms.Common.delete}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title" sx={{ m: '20px 0 0', minWidth: '400px' }}>
          {terms.Dashboard.deleteZone}
          {' '}
          {zone.label}
          {' '}
          ?
        </DialogTitle>
        <DialogActions sx={{ justifyContent: 'center', m: '20px' }}>
          <ThemeProvider theme={customCancelButton}>
            <Button onClick={handleMenuClose}>
              {terms.Common.cancel}
            </Button>
          </ThemeProvider>
          <ThemeProvider theme={customDeletionButton}>
            <Button onClick={removeDashboard}>
              {terms.Common.delete}
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
    </>
  )
}
