import {
  ReactElement, SyntheticEvent, useCallback, useEffect, useState,
} from 'react'
import { Tab, Tabs } from '@mui/material'
import { GATEWAY_DOMAIN } from 'config/config'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { OpenInFull } from '@mui/icons-material'
import './CameraView.scss'
import Spinner from 'react-bootstrap/esm/Spinner'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'

const getImage = async (slug: string) => {
  // eslint-disable-next-line max-len
  const url = `https://${GATEWAY_DOMAIN.url}/open_tco/camera/${slug}/snapshot/?_auth_token=${`${localStorage.getItem('access_token')}`}`
  const response = await fetch(url)
  return response.blob()
}

type Camera = {
  slug: string;
  name: string;
  poste: string;
}

export default function CameraView(): ReactElement {
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)
  const [fullScreenActive, setFullScreenActive] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [imageURL, setImageURL] = useState('')
  const [cameras, setCameras] = useState<Camera[]>([])
  const [activeCamera, setActiveCamera] = useState<Camera | null>(null)
  const [imageLoading, setImageLoading] = useState(false)
  const screen = useFullScreenHandle()

  const handleSetFullScreen = useCallback(state => setFullScreenActive(state), [screen])

  useEffect(() => {
    const camerasList: Camera[] = activePerimeter?.cameras ?? []
    setCameras(camerasList)
  }, [activePerimeter])

  useEffect(() => {
    const camera = cameras[tabValue]
    setActiveCamera(camera)
  }, [tabValue, cameras])

  useEffect(() => {
    const handleImageLoader = (loader = false) => {
      if (activeCamera) {
        if (loader) setImageLoading(true)
        getImage(activeCamera.slug).then(snapshot => {
          setImageURL(URL.createObjectURL(snapshot))
          if (loader) setImageLoading(false)
        })
      }
    }
    handleImageLoader(true)
    const interval = setInterval(handleImageLoader, 30000)

    return () => clearInterval(interval)
  }, [activeCamera])

  const handleChange = async (_: SyntheticEvent, newValue: number) => {
    setActiveCamera(cameras[newValue])
    setTabValue(newValue)
  }

  return (
    <div className={`camera-view mastcontainer${fullScreenActive ? ' fullscreen' : ''}`}>
      <Tabs value={tabValue} onChange={handleChange} className="tabs">
        {cameras.map((camera, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Tab key={index} label={camera.name} />
        ))}
      </Tabs>
      <FullScreen handle={screen} onChange={handleSetFullScreen}>
        <div className="full-screenable-node">
          <div className="camera-topbar">
            <span className="camera-label">{activeCamera?.name ?? ''}</span>
            <button
              type="button"
              onClick={fullScreenActive ? screen.exit : screen.enter}
              className="camera-fullscreen-button"
            >
              <OpenInFull />
            </button>
          </div>
          <div className="image-container">
            {imageLoading ? (
              <Spinner animation="border" className="spinner-style" role="status">
                <span className="visually-hidden" />
              </Spinner>
            ) : (
              <img src={imageURL} alt="camera" className="camera-image" />
            )}
          </div>
        </div>
      </FullScreen>
    </div>
  )
}
