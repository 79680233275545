import { persistCombineReducers } from 'redux-persist'
import { createFilter } from 'redux-persist-transform-filter'
import storage from 'redux-persist/lib/storage' // defaults to localStorage

import userReducer from '@osrdata/app_core/dist/auth/redux/index'
import appReducer from './app'
import perimeterReducer from './perimeter'
import zoneReducer from './zone'
import widgetReducer from './widget'
import streamReducer from './stream'
import objectsReducer from './objects'
import alertsReducer from './alerts'

const userWhiteList = [
  'account',
]

const perimeterWhiteList = [
  'activePerimeter',
]

const zoneWhiteList = [
  'zonesList', 'activeZone', 'dashboardTabs',
]

const widgetWhiteList = [
  'widgetsList',
]

const saveUserFilter = createFilter(
  'user',
  userWhiteList,
)

const savePerimeterFilter = createFilter(
  'perimeter',
  perimeterWhiteList,
)

const saveZoneFilter = createFilter(
  'zone',
  zoneWhiteList,
)

const saveWidgetFilter = createFilter(
  'widget',
  widgetWhiteList,
)

const persistConfig = {
  key: 'root',
  storage,
  transforms: [saveUserFilter, savePerimeterFilter, saveZoneFilter, saveWidgetFilter],
  whitelist: ['user', 'perimeter', 'zone', 'widget'],
}

const rootReducer = {
  user: userReducer,
  app: appReducer,
  perimeter: perimeterReducer,
  zone: zoneReducer,
  widget: widgetReducer,
  stream: streamReducer,
  objects: objectsReducer,
  alerts: alertsReducer,
}

export default persistCombineReducers(persistConfig, rootReducer)
