/* eslint-disable react/no-array-index-key */
import { ReactElement, useEffect } from 'react'
import HomeIcon from '@mui/icons-material/Home'
import {
  Box, Divider, IconButton, Typography,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import CloseIcon from '@mui/icons-material/Close'
import { setZonesList, updateActiveZone, updateDashboardTabs } from 'reducers/zone'
import terms from 'common/terms'
import { toggleLock, updateWidgetsList } from 'reducers/widget'
import { DEFAULT_LATE_PARAMS, updateIsLateSettings, updateObjectsLayer } from 'reducers/objects'
import { reset } from 'reducers/stream'
import history from '../../../appHistory'
import InitialResizableObjects from '../ToolsBar/InitialResizableObjects'

import './topbar.scss'

export default function NavTabs(): ReactElement {
  const dispatch = useDispatch()
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)
  const { activeZone, dashboardTabs, zonesList } = useSelector((state: RootState) => state.zone)

  useEffect(() => {
    if (activeZone && activeZone.screen_settings) {
      dispatch(updateObjectsLayer(activeZone.screen_settings))
      dispatch(updateIsLateSettings({
        is_train_late_delay: activeZone.is_train_late_delay ?? DEFAULT_LATE_PARAMS.is_train_late_delay,
        late_color: activeZone.late_color ?? DEFAULT_LATE_PARAMS.late_color,
        on_time_color: activeZone.on_time_color ?? DEFAULT_LATE_PARAMS.on_time_color,
      }))
    } else {
      dispatch(updateObjectsLayer(InitialResizableObjects))
      dispatch(updateIsLateSettings(DEFAULT_LATE_PARAMS))
    }
  }, [activeZone])

  const goHome = () => {
    history.push('/')
    dispatch(toggleLock(true))
    dispatch(setZonesList([]))
    dispatch(reset())
  }

  const goToDashboardsHome = () => {
    dispatch(updateActiveZone(undefined))
    dispatch(updateWidgetsList([]))
    history.push(`/${activePerimeter?.slug}/dashboard`)
    dispatch(toggleLock(true))
  }

  const goToDashboard = zonePk => {
    if (zonePk === -1) {
      dispatch(updateActiveZone({ label: terms.Dashboard.fullViewCard }))
      history.push(`/${activePerimeter?.slug}/FullView`)
    } else {
      dispatch(updateActiveZone(zonesList.filter(zone => zone.pk === zonePk)[0]))
      history.push(`/${activePerimeter?.slug}/${zonePk}`)
    }
    dispatch(updateWidgetsList([]))
    dispatch(toggleLock(true))
  }

  const onTabClose = tabKey => {
    if (activeZone?.pk === tabKey || tabKey === 'camera') {
      dispatch(updateActiveZone(undefined))
      dispatch(updateWidgetsList([]))
      dispatch(toggleLock(true))
      history.push(`/${activePerimeter?.slug}/dashboard`)
    }
    const newTabsList = dashboardTabs.filter(dashboard => dashboard.key !== tabKey)
    dispatch(updateDashboardTabs(newTabsList))
  }

  return (
    <>
      <Box
        component="div"
        className={`tab-button${activePerimeter ? '' : ' active-tab'}`}
      >
        <IconButton
          onClick={goHome}
          size="medium"
        >
          <HomeIcon />
        </IconButton>
      </Box>
      {activePerimeter && (
        <>
          <KeyboardArrowRightIcon sx={{ ml: '0 !important' }} />
          <Typography className="perimeter-tab" onClick={goToDashboardsHome}>
            {activePerimeter.label}
          </Typography>
          <Divider orientation="vertical" className="divider" />
        </>
      )}
      {dashboardTabs.length !== 0 && (
        dashboardTabs.map((dashboard, index) => (
          <Box
            className={`dashboard-tab${dashboard.key === activeZone?.pk && activeZone ? ' active-tab' : ''}`}
            key={index}
          >
            <Typography
              onClick={() => goToDashboard(dashboard.key ? dashboard.key : -1)}
            >
              {dashboard.value}
            </Typography>
            <CloseIcon onClick={() => onTabClose(dashboard.key)} />
          </Box>
        ))
      )}
    </>
  )
}
