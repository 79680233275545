import { ReactElement, useState } from 'react'
import {
  Box, Button, IconButton, Modal, ThemeProvider,
} from '@mui/material'
import { customCancelButton, customDeletionButton } from 'common/CustomTheme'
import terms from 'common/terms'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { useDispatch, useSelector } from 'react-redux'
import { WidgetView } from 'reducers/types'
import { RootState } from 'Store'
import WidgetsService from 'reducers/services/widgetsService'

type DeleteWidgetProps = {
    widget: WidgetView
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '16px 24px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
}

export default function DeleteWidgetModal({ widget }: DeleteWidgetProps): ReactElement {
  const dispatch = useDispatch()
  const { activeZone } = useSelector((state: RootState) => state.zone)
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)
  const { lock } = useSelector((state: RootState) => state.widget)
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') { return }
    setOpen(false)
  }

  const removeWidget = async () => {
    const params = {
      perimeterSlug: activePerimeter?.slug,
      zonePk: activeZone?.pk,
      widgetSlug: widget.slug,
    }

    dispatch(WidgetsService.deleteWidget(params))
    setOpen(false)
  }

  return (
    <>
      <IconButton onClick={handleOpen} disabled={!lock}>
        <DeleteOutlinedIcon sx={{ color: lock ? 'white' : '#00000042' }} />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        disablePortal
      >
        <Box sx={style} className="modal-widget">
          <span className="modal-widget-subtitle">
            {`${terms.Widget.deleteWidgetNotice} ${widget.label} ?`}
          </span>
          <div className="actions">
            <ThemeProvider theme={customCancelButton}>
              <Button onClick={() => setOpen(false)}>
                {terms.Common.cancel}
              </Button>
            </ThemeProvider>
            <ThemeProvider theme={customDeletionButton}>
              <Button onClick={removeWidget}>
                {terms.Common.delete}
              </Button>
            </ThemeProvider>
          </div>
        </Box>
      </Modal>
    </>
  )
}
