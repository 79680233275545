import { Button, Menu, MenuItem } from '@mui/material'
import terms from 'common/terms'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setOrderBy } from 'reducers/perimeter'
import PerimetersService from 'reducers/services/perimetersService'
import { RootState } from 'Store'
import SouthIcon from '@mui/icons-material/South'
import NorthIcon from '@mui/icons-material/North'
import { OrderBy, OrderingList } from '../Common/types'

export default function PerimeterOrdering(): ReactElement {
  const dispatch = useDispatch()
  const { orderBy } = useSelector((state: RootState) => state.perimeter)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    dispatch(PerimetersService.getPerimetersList({ ordering: orderBy }))
  }, [orderBy])

  const toggleOrderingChoice = (sortBy: string) => {
    dispatch(setOrderBy(sortBy))
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{
          fontSize: '12px', textAlign: 'right', textTransform: 'none', minWidth: 180, justifyContent: 'flex-start',
        }}
      >
        <span style={{ color: '#868686' }}>
          {terms.Common.sortBy}
        </span>
        {' '}
        <span style={{ color: '#43454A', paddingLeft: '3px' }}>
          {OrderingList.filter(o => o.key === orderBy)[0].value}
          {orderBy === OrderBy.name || orderBy === OrderBy.lastCreated || orderBy === OrderBy.lastModified
            ? <SouthIcon sx={{ width: '12px', height: '12px', ml: '5px' }} />
            : <NorthIcon sx={{ width: '12px', height: '12px', ml: '5px' }} />}
        </span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => toggleOrderingChoice(orderBy === OrderBy.name ? OrderBy.lastName : OrderBy.name)}
          style={{ fontSize: '12px', minWidth: '180px' }}
        >
          {terms.Common.alphabeticalOrder}
        </MenuItem>
        <MenuItem
          onClick={() => toggleOrderingChoice(orderBy === OrderBy.lastCreated
            ? OrderBy.created : OrderBy.lastCreated)}
          style={{ fontSize: '12px' }}
        >
          {terms.Common.creationDate}
        </MenuItem>
        <MenuItem
          onClick={() => toggleOrderingChoice(orderBy === OrderBy.lastModified
            ? OrderBy.modified : OrderBy.lastModified)}
          style={{ fontSize: '12px' }}
        >
          {terms.Common.modificationDate}
        </MenuItem>
      </Menu>
    </div>
  )
}
