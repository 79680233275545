import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FullScreenHandle } from 'react-full-screen'
import { AppState } from './types'

const initialState: AppState = {
  error: null,
  fullscreenHandler: {
    active: false, enter: async () => undefined, exit: async () => undefined, node: { current: null },
  },
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    getError: (state, action: PayloadAction<null>) => {
      state.error = action.payload
    },
    getFullscreenHandler: (state, action: PayloadAction<FullScreenHandle>) => {
      state.fullscreenHandler.active = action.payload.active
      state.fullscreenHandler.enter = action.payload.enter
      state.fullscreenHandler.exit = action.payload.exit
    },
  },
})

export const { getError, getFullscreenHandler } = appSlice.actions

export default appSlice.reducer
