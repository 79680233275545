import { ReactElement, useEffect, useRef } from 'react'
import 'App.scss'
import _ from 'lodash'
import jsonpatch from 'fast-json-patch'
import {
  updateTcoDataStream, updateTcoStreamState, toggleTcoReOpen, updateTcoAlerts,
} from 'reducers/stream'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { GATEWAY_DOMAIN } from 'config/config'

export default function TcoDataStream(): ReactElement {
  const dispatch = useDispatch()
  const tcoReOpen = useSelector((state: RootState) => state.stream.tcoReOpen)
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)
  const endpoint = activePerimeter?.tconext ? 'tco_next' : 'tco'

  let tcoDataStream
  const ws: any = useRef()

  useEffect(() => {
    ws.current = new WebSocket(
      `wss://${GATEWAY_DOMAIN.url}/open_tco/data_stream/${endpoint}/${activePerimeter?.slug}/?_auth_token=${
        `${localStorage.getItem('access_token')}`}`,
    )
    ws.current.onopen = () => dispatch(updateTcoStreamState(true))
    ws.current.onmessage = (event: { data: any }) => {
      const response = JSON.parse(event.data)
      if (tcoDataStream === undefined) {
        tcoDataStream = {}
      }
      if (response.op === 'alert') {
        dispatch(updateTcoAlerts(response))
      }
      if (response[0]?.op !== 'health' && response.op !== 'alert') {
        const newData = jsonpatch.applyPatch(_.cloneDeep(tcoDataStream), response).newDocument
        tcoDataStream = newData
        dispatch(updateTcoDataStream(newData))
      }
    }
    ws.current.onclose = () => {
      dispatch(updateTcoStreamState(false))
      dispatch(toggleTcoReOpen(!tcoReOpen))
      tcoDataStream = {}
    }
    const wsCurrent = ws.current
    return () => {
      wsCurrent.close()
    }
  }, [tcoReOpen])
  return (
    <> </>
  )
}
