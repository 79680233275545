import { ReactElement } from 'react'
import { Grid, InputLabel, TextField } from '@mui/material'

import './input.scss'

type Props = {
    label: string,
    value: string,
    onChange: (value: any) => void,
}

export default function TextAreaField({
  label, value, onChange,
}: Props) : ReactElement {
  return (
    <Grid sx={{ mt: '15px' }}>
      <InputLabel htmlFor="my-input" className="input-label">{label}</InputLabel>
      <TextField
        multiline
        rows={5}
        value={value}
        onChange={onChange}
        onKeyPress={event => {
          if (event.key === 'Enter') { event.preventDefault() }
        }}
        className="textarea-field"
      />
    </Grid>
  )
}
