/* eslint-disable max-len */
import { ReactElement } from 'react'
import useSupercluster from 'use-supercluster'

import CartoucheMarker from './CartoucheMarker'
import CartouchePopup from './CartouchePopup'

type Props = {
  viewportZoom: number
  pointsTop: Array<any>
  pointsBottom: Array<any>
  bounds: any
  cartouchesClustersRadius: number
  shortTrain: boolean
}

export default function CartoucheCluster({
  viewportZoom, pointsTop, pointsBottom, bounds, cartouchesClustersRadius, shortTrain,
}: Props): ReactElement {
  const clustersTop = useSupercluster({
    points: pointsTop,
    bounds,
    zoom: viewportZoom,
    options: { radius: cartouchesClustersRadius },
  })

  const clustersBottom = useSupercluster({
    points: pointsBottom,
    bounds,
    zoom: viewportZoom,
    options: { radius: cartouchesClustersRadius },
  })

  return (
    <>
      {clustersTop.clusters.map(cluster => {
        const [longitude, latitude] = cluster.geometry.coordinates
        const {
          cluster: isCluster,
        } = cluster.properties
        const clusterId = cluster.properties.cluster_id
        const clusterLeaves = clusterId !== undefined ? (clustersTop.supercluster?.getLeaves(clusterId, Infinity)) : undefined
        if (!isCluster) {
          return (
            <CartoucheMarker
              key={`1-cartouche-${cluster.properties.cartoucheId}`}
              longitude={cluster.geometry.coordinates[0]}
              latitude={cluster.geometry.coordinates[1]}
              direction={cluster.properties.direction}
              trainNumber={cluster.properties.trainNumber}
              id={cluster.properties.id}
              railway={cluster.properties.railway}
              delay={cluster.properties.delay}
              timeObserved={cluster.properties.timeObserved}
              shortTrain={shortTrain}
              isTCO={cluster.properties.source === 'TCO'}
            />
          )
        }
        return (
          <CartouchePopup
            key={`plusieurs-cartouches-${clusterId}`}
            clusterLeaves={clusterLeaves}
            clusterId={clusterId}
            longitude={longitude}
            latitude={latitude}
            popupAnchor="bottom"
            offsetTop={-20}
            shortTrain={shortTrain}
          />
        )
      })}
      {clustersBottom.clusters.map(cluster => {
        const [longitude, latitude] = cluster.geometry.coordinates
        const {
          cluster: isCluster,
        } = cluster.properties
        const clusterId = cluster.properties.cluster_id
        const clusterLeaves = clusterId !== undefined ? (clustersBottom.supercluster?.getLeaves(clusterId, Infinity)) : undefined
        if (!isCluster) {
          return (
            <CartoucheMarker
              key={`1-cartouche-${cluster.properties.cartoucheId}`}
              longitude={cluster.geometry.coordinates[0]}
              latitude={cluster.geometry.coordinates[1]}
              direction={cluster.properties.direction}
              trainNumber={cluster.properties.trainNumber}
              id={cluster.properties.id}
              railway={cluster.properties.railway}
              delay={cluster.properties.delay}
              timeObserved={cluster.properties.timeObserved}
              shortTrain={shortTrain}
              isTCO={cluster.properties.source === 'TCO'}
            />
          )
        }
        return (
          <CartouchePopup
            key={`plusieurs-cartouches-${clusterId}`}
            clusterLeaves={clusterLeaves}
            clusterId={clusterId}
            longitude={longitude}
            latitude={latitude}
            popupAnchor="top"
            offsetTop={20}
            shortTrain={shortTrain}
          />
        )
      })}
    </>
  )
}
