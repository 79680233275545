/* eslint-disable max-len */
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  WidgetView, PostParams, PatchParams, DeleteParams, GetWidgetParams,
} from 'reducers/types'
import {
  deleteRequest, get, post, patch,
} from '@osrdata/app_core/dist/requests/index'
import { ThunkApi } from 'types'

const getWidgetsList = createAsyncThunk<Array<WidgetView>, GetWidgetParams>(
  'widgets/getWidgetsList',
  async (params: GetWidgetParams) => {
    const response = await get(`/open_tco/perimeters/${params.perimeterSlug}/zones/${params.zonePk}/widgets/`)
    return response.results
  },
)

const createWidget = createAsyncThunk<WidgetView, PostParams, ThunkApi>(
  'widgets/create',
  async (params: PostParams, thunkApi) => {
    try {
      const response: WidgetView = await post(`/open_tco/perimeters/${params.perimeterSlug}/zones/${params.zonePk}/widgets/`,
        params.widget)
      return response
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const updateWidget = createAsyncThunk<WidgetView, PatchParams, ThunkApi>(
  'widgets/update',
  async (params: PatchParams, thunkApi) => {
    try {
      const response: WidgetView = await
      patch(`/open_tco/perimeters/${params.perimeterSlug}/zones/${params.zonePk}/widgets/${params.widgetSlug}`, params.widget)
      return response
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const deleteWidget = createAsyncThunk<DeleteParams, DeleteParams, ThunkApi>(
  'widgets/delete',
  async (params: DeleteParams, thunkApi) => {
    try {
      await deleteRequest(`/open_tco/perimeters/${params.perimeterSlug}/zones/${params.zonePk}/widgets/${params.widgetSlug}`)
      return params
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const WidgetsService = {
  getWidgetsList,
  createWidget,
  updateWidget,
  deleteWidget,
}

export default WidgetsService
