/* eslint-disable max-len */
import { ReactElement } from 'react'
import { Marker } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { ResizableObjects } from 'reducers/types'
import { Objects } from 'components/Common/ToolsBar/types'
import CartoucheSetup from './CartoucheSetup'
import { getCartoucheOffsetTop, getCartoucheOffsetLeft, getCartoucheOffsetLeftShortTrain } from './utils'

type Props = {
  latitude: number,
  longitude: number,
  direction: boolean | null
  trainNumber: string | null
  id: string
  railway: string | null
  delay: number | null
  timeObserved: string | null
  shortTrain: boolean
  isTCO: boolean
}

export default function CartoucheMarker({
  latitude, longitude, direction, trainNumber, id, timeObserved,
  railway, delay, shortTrain, isTCO,
}: Props): ReactElement {
  const { objectsLayer } = useSelector((state : RootState) => state.objects)
  const cartoucheObject: ResizableObjects = objectsLayer.filter((object: ResizableObjects) => object.label === Objects.Cartouche)[0]

  return (
    <Marker
      latitude={latitude}
      longitude={longitude}
      offsetLeft={shortTrain ? getCartoucheOffsetLeftShortTrain(cartoucheObject.size) : getCartoucheOffsetLeft(cartoucheObject.size)}
      offsetTop={getCartoucheOffsetTop(cartoucheObject.size)}
    >
      <CartoucheSetup
        direction={direction}
        trainNumber={trainNumber}
        id={id}
        railway={railway}
        delay={delay}
        timeObserved={timeObserved}
        shortTrain={shortTrain}
        isTco={isTCO}
      />
    </Marker>
  )
}
