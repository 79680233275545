import { ReactElement } from 'react'
import {
  Checkbox, FormControlLabel, FormGroup, Slider, Box,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { ResizableObjects } from 'reducers/types'
import { RootState } from 'Store'
import { updateObjectsLayer } from 'reducers/objects'
import terms from 'common/terms'
import { ObjectsLabels, ScreenSizeToNumbers } from './types'
import { hasAdminAccess, hasOperatorAccess } from '../../../helpers/permissions'

type Props = {
    objectToResize: ResizableObjects
}

const marks = [
  {
    value: 1,
    label: terms.ObjectsToolBar.small,
  },
  {
    value: 2,
    label: '',
  },
  {
    value: 3,
    label: terms.ObjectsToolBar.medium,
  },
  {
    value: 4,
    label: '',
  },
  {
    value: 5,
    label: terms.ObjectsToolBar.big,
  },
]

const marksLarge = [
  {
    value: 0,
    label: terms.ObjectsToolBar.small,
  },
  {
    value: 1,
    label: '',
  },
  {
    value: 2,
    label: '',
  },
  {
    value: 3,
    label: terms.ObjectsToolBar.medium,
  },
  {
    value: 4,
    label: '',
  },
  {
    value: 5,
    label: '',
  },
  {
    value: 6,
    label: terms.ObjectsToolBar.big,
  },
]

const marks2 = [
  {
    value: 0,
    label: terms.ObjectsToolBar.t0,
  },
  {
    value: 1,
    label: terms.ObjectsToolBar.t1,
  },
  {
    value: 2,
    label: terms.ObjectsToolBar.t2,
  },
  {
    value: 3,
    label: terms.ObjectsToolBar.t3,
  },
  {
    value: 4,
    label: terms.ObjectsToolBar.t4,
  },
  {
    value: 5,
    label: terms.ObjectsToolBar.t5,
  },
  {
    value: 6,
    label: terms.ObjectsToolBar.t6,
  },
]

export default function ResizableLayer({ objectToResize }: Props): ReactElement {
  const dispatch = useDispatch()
  const { activeZone } = useSelector((state: RootState) => state.zone)
  const { objectsLayer } = useSelector((state: RootState) => state.objects)
  const object: ResizableObjects = objectsLayer.filter((obj: ResizableObjects) => obj.label === objectToResize.label)[0]

  const handleCheck = () => {
    const newLayersSettings: ResizableObjects[] = []
    newLayersSettings.push(...objectsLayer.filter((obj: ResizableObjects) => obj.label !== object.label))
    newLayersSettings.push({ label: object.label, display: !object.display, size: object.size })
    dispatch(updateObjectsLayer(newLayersSettings))
  }

  const handleSizeChange = size => {
    const newLayersSettings: ResizableObjects[] = []
    newLayersSettings.push(...objectsLayer.filter((obj: ResizableObjects) => obj.label !== object.label))
    newLayersSettings.push({
      label: object.label,
      display: object.display,
      size: ScreenSizeToNumbers.filter(s => s.numberValue === size)[0].size,
    })
    dispatch(updateObjectsLayer(newLayersSettings))
  }

  const valuetext = (value: number) => `${value}`

  const getMarks = (key: string) => {
    switch (key) {
      case 'disparition_delay':
        return marks2
      case 'circulation_boxes':
        return marksLarge
      default:
        return marks
    }
  }

  return (
    <>
      {(!activeZone?.default || !objectToResize.adminSlider || hasAdminAccess() || hasOperatorAccess()) && (
      <Box className="box-style">
        <FormGroup>
          <FormControlLabel
            className="checkbox-label"
            control={(
              <Checkbox
                checked={object.display}
                onChange={handleCheck}
              />
        )}
            label={ObjectsLabels.filter(obj => obj.key === object.label)[0].label}
          />
        </FormGroup>
        <Slider
          step={1}
          min={(objectToResize.label === 'disparition_delay' || objectToResize.label === 'circulation_boxes') ? 0 : 1}
          max={(objectToResize.label === 'disparition_delay' || objectToResize.label === 'circulation_boxes') ? 6 : 5}
          marks={getMarks(objectToResize.label)}
          valueLabelDisplay="off"
          value={ScreenSizeToNumbers.filter(s => s.size === object.size)[0]?.numberValue}
          onChange={(e: any) => handleSizeChange(e.target.value)}
          className={`slider${object.display ? '' : ' slider-disabled'}`}
          getAriaValueText={valuetext}
          disabled={!object.display}
        />
      </Box>
      )}
    </>
  )
}
