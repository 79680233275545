import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import ZonesService from 'reducers/services/zonesService'
import Grid from '@mui/material/Grid'
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'
import { setUpdatedZone } from 'reducers/zone'
import DefaultDahsboardCardList from './DefaultDashboardCardList'
import DashboardList from './DashboardList'

export default function Dashboard(): ReactElement {
  const dispatch = useDispatch()
  const { fullscreenHandler } = useSelector((state: RootState) => state.app)
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)
  const {
    deletedZone, updatedZone, zonesList, orderBy,
  } = useSelector((state: RootState) => state.zone)

  useEffect(() => {
    if (updatedZone || deletedZone || zonesList.length === 0) {
      dispatch(ZonesService.getZonesList({ perimeterSlug: activePerimeter.slug, ordering: orderBy }))
      dispatch(setUpdatedZone(false))
    }
  }, [deletedZone, updatedZone])

  return (
    <main className={`mastcontainer ${fullscreenHandler.active ? ' fullscreen' : ''}`}>
      <Fade in timeout={500}>
        <Box>
          <Grid container sx={{ height: '100%', p: 2 }} margin="auto">
            <DefaultDahsboardCardList />
            <DashboardList />
          </Grid>
        </Box>
      </Fade>
    </main>
  )
}
