/* eslint-disable max-len */
export const terms = {
  TopBar: {
    disconnect: 'Déconnexion',
  },
  Perimeter: {
    welcomeTo: 'Bienvenue sur',
    selectCard: 'Veuillez sélectionner un périmètre pour continuer',
    addPerimeter: 'Créer un nouveau périmètre',
    addPerimeterButton: 'Créer le périmètre',
    deletePerimeterNotice: 'Voulez-vous vraiment supprimer le périmètre',
    renamePerimeterNotice: 'Entrez un nouveau nom pour le périmètre',
    deletePerimeter: 'Supprimer le périmètre ',
    changePerimeterPhoto: 'Changer la photo du périmètre ',
    renamePerimeter: 'Renommer le périmètre',
    perimeterLength: 'Tous les périmètres',
  },
  Dashboard: {
    createDashboard: 'Créer un tableau de bord',
    creationButton: 'Créer le tableau de bord',
    dashboardDescription: 'Description (300 caractères max)',
    defaultDashboard: 'Tableau de bord public',
    screenChoice: 'Utiliser de préférence sur :',
    screenWall: 'Mur d’écrans',
    wideScreen: 'Écran large',
    desktop: 'Ordinateur de bureau',
    laptop: 'Ordinateur portable',
    fullViewCard: 'Carte pleine vue',
    fullViewDescription: 'Vue de l’ensemble du périmètre',
    deleteZone: 'Voulez-vous vraiment supprimer la zone',
    updateDashboard: 'Modifier le tableau de bord',
    updateButton: 'Modifier le tableau de bord',
    dashboards: 'Mes tableaux de bord',
    lastModified: 'Dernière modification',
    creationDate: 'Date de création',
    cameraViewCard: 'Vue caméra',
    cameraViewDescription: 'Accéder aux images des caméras du TCO',
  },
  Widget: {
    createFirstWidget: 'Créer mon premier widget',
    createWidget: 'Créer un widget',
    createWidgetButton: 'Créer le widget',
    deleteWidgetNotice: 'Voulez-vous vraiment supprimer le widget',
    renameWidget: 'Renommer le widget',
    savePosition: 'Sauvegarder et verrouiller la position des widgets',
  },
  Common: {
    appName: 'OpenTCO',
    update: 'Renommer',
    delete: 'Supprimer',
    modify: 'Modifier',
    cancel: 'Annuler',
    ok: 'Ok',
    yes: 'Oui',
    patternError: 'Seuls ces caractères spéciaux sont acceptés: -._~:/?#[]@!$&\'()*+,;=',
    sortBy: 'Trier par',
    alphabeticalOrder: 'Ordre alphabétique',
    creationDate: 'Date de création',
    modificationDate: 'Date de modification',
    name: 'Nom',
    open: 'Ouvrir',
    tco: 'TCO',
    basic: 'BREHAT',
  },
  ObjectsToolBar: {
    cartouches: 'Étiquette',
    disparitionDelay: 'Étiquette : Temps avant disparition',
    confirmTitle: 'Voulez-vous vraiment supprimer cette étiquette ? Elle réapparaîtra au passage sur la prochaine balise.',
    agregation: 'Agrégation',
    signaux: 'Libellés des signaux',
    adv: 'Libellés des appareils de voies',
    tiv: 'Libellés des voies',
    gares: 'Libellés des gares',
    annonces: 'Libellés des annonces',
    quais: 'Libellés des quais',
    small: 'Petit',
    medium: 'Moyen',
    big: 'Grand',
    t0: '30s',
    t1: '1min',
    t2: '2min',
    t3: '3min',
    t4: '5min',
    t5: '15min',
    t6: '30min',
  },
  AlertsMonitor: {
    alerts: 'Alertes',
    textAlerts: 'Alertes textuelles',
    soundAlerts: 'Alertes sonores',
    volume: 'Volume des alertes sonores',
    noAlerts: 'Aucune alerte',
    stationsNotified: 'gare(s) notifiée(s)',
    noStationsNotified: 'Aucune gare notifiée',
  },
  DelaySlider: {
    threshold: 'Seuil de retard :',
    minutes: 'min',
    title: 'Choix des couleurs',
    onTime: 'A l’heure/En avance',
    late: 'En retard',
  },
  CGU: {
    title: 'Conditions générales d’utilisation',
    contentText1: 'Je m’engage à respecter la :',
    contentLink1: 'Charte de l’utilisateur des outils numériques (RG00044)',
    contentText2: 'J’ai pris connaissance des :',
    contentLink2: '10 CybeRéflexes',
    btn: 'Accepter les conditions',
  },
}

export default terms
