import terms from 'common/terms'

const ScreenSize = [
  {
    key: 'video_wall',
    value: terms.Dashboard.screenWall,
  },
  {
    key: 'large_screen',
    value: terms.Dashboard.wideScreen,
  },
  {
    key: 'desktop_screen',
    value: terms.Dashboard.desktop,
  },
  {
    key: 'laptop_screen',
    value: terms.Dashboard.laptop,
  },
]

export default ScreenSize
