import { ReactElement, useEffect } from 'react'
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import moment from 'moment'
import 'moment/locale/fr'
import { setOrderBy, updateActiveZone, updateDashboardTabs } from 'reducers/zone'
import { OrderBy } from 'components/Common/types'
import ZonesService from 'reducers/services/zonesService'
import SouthIcon from '@mui/icons-material/South'
import NorthIcon from '@mui/icons-material/North'
import ActionsMenu from './ActionsMenu'
import history from '../../appHistory'

export default function DashboardList(): ReactElement {
  const dispatch = useDispatch()
  const { zonesList, orderBy, dashboardTabs } = useSelector((state: RootState) => state.zone)
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)

  useEffect(() => {
    dispatch(ZonesService.getZonesList({ perimeterSlug: activePerimeter.slug, ordering: orderBy }))
  }, [orderBy])

  const goToDashboard = zone => {
    if (zone) {
      dispatch(updateActiveZone(zone))
      if (dashboardTabs.filter(dashboard => dashboard.key === zone.pk).length === 0) {
        const newTabsList = []
        newTabsList.push(...dashboardTabs)
        newTabsList.push({ key: zone.pk, value: zone.label })
        dispatch(updateDashboardTabs(newTabsList))
      }
    } else {
      dispatch(updateActiveZone({ label: terms.Dashboard.fullViewCard }))
      if (dashboardTabs.filter(dashboard => dashboard.key === undefined).length === 0) {
        const newTabsList = []
        newTabsList.push(...dashboardTabs)
        newTabsList.push({ value: terms.Dashboard.fullViewCard })
        dispatch(updateDashboardTabs(newTabsList))
      }
    }
    history.push(`/${activePerimeter?.slug}/${zone.pk}`)
  }

  return (
    zonesList.length !== 0 && zonesList.filter(zone => !zone.default).length !== 0 && (
    <TableContainer>
      <Table className="dashboard-table">
        <TableHead>
          <TableRow>
            <TableCell onClick={() => dispatch(setOrderBy(orderBy === OrderBy.name ? OrderBy.lastName : OrderBy.name))}>
              {terms.Dashboard.dashboards}
              {orderBy === OrderBy.name && <SouthIcon />}
              {orderBy === OrderBy.lastName && <NorthIcon />}
            </TableCell>
            <TableCell onClick={() => dispatch(setOrderBy(orderBy === OrderBy.lastModified
              ? OrderBy.modified : OrderBy.lastModified))}
            >
              {terms.Dashboard.lastModified}
              {orderBy === OrderBy.lastModified && <SouthIcon />}
              {orderBy === OrderBy.modified && <NorthIcon /> }
            </TableCell>
            <TableCell onClick={() => dispatch(setOrderBy(orderBy === OrderBy.lastCreated
              ? OrderBy.created : OrderBy.lastCreated))}
            >
              {terms.Dashboard.creationDate}
              {orderBy === OrderBy.lastCreated && <SouthIcon />}
              {orderBy === OrderBy.created && <NorthIcon />}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {zonesList.filter(zone => !zone.default).map(zone => (
            <TableRow
              key={zone.pk}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell onClick={() => goToDashboard(zone)} sx={{ cursor: 'pointer' }}>{zone.label}</TableCell>
              <TableCell>{`${moment(zone.modified).fromNow()}`}</TableCell>
              <TableCell>{`${moment(zone.created).format('DD/MM/YYYY')}`}</TableCell>
              <TableCell align="right"><ActionsMenu zone={zone} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    )
  )
}
