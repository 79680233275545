/* eslint-disable max-len */
import { ReactElement } from 'react'
import '../../../App.scss'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { ResizableObjects } from 'reducers/types'
import { Objects } from 'components/Common/ToolsBar/types'
import { getScale } from './utils'

type Props = {
  opacity: string | null
  trainHead: string | null
  xHead: string | null
  xTime: string | null
  statusColor: string | null
  xDelay: string | null
  xTextTrainNumber: string | null
  trainNumber: string | null
  railwayShort: string | null
  xTextTime: string | null
  timeObserved: string | null
  xTextDelay: string | null
  delay: number | null
  shortTrain: boolean
  strokeWidth: number | null
  trainStrokeColor: string
  trainFillColor: string
  textColor: string
  isTCO: boolean
}

export default function CartoucheSvg({
  opacity, trainHead, xHead, xTime, statusColor, xDelay, xTextTrainNumber, trainNumber,
  railwayShort, xTextTime, timeObserved, xTextDelay, delay, shortTrain, strokeWidth,
  trainStrokeColor, trainFillColor, textColor, isTCO,
}: Props): ReactElement {
  const { objectsLayer } = useSelector((state : RootState) => state.objects)
  const cartoucheObject: ResizableObjects = objectsLayer.filter((object: ResizableObjects) => object.label === Objects.Cartouche)[0]

  return (
    <svg
      width={shortTrain ? 102 : 242}
      height={40}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      opacity={opacity}
      transform={`scale(${getScale(cartoucheObject.size)})`}
      style={{ overflow: 'visible' }}
    >
      {!shortTrain && (
      <>
        <rect
          id="railway"
          strokeWidth={2}
          stroke={trainStrokeColor}
          fill={trainFillColor}
          width={40}
          height={28.5}
          x={101}
          y={1.5}
        />
        <text
          id="nvoie"
          className="cartouche-text"
          style={{ fontSize: railwayShort.length < 3 ? '18px' : '10px' }}
          xmlSpace="preserve"
          textAnchor="middle"
          fill={textColor}
          y={22}
          x={121}
        >
          {railwayShort}
        </text>
        <rect
          id="time"
          strokeWidth={2}
          stroke={trainStrokeColor}
          fill={trainFillColor}
          width={62}
          height={28.5}
          x={xTime}
          y={1.5}
        />
        <text
          id="ntime"
          className="cartouche-text"
          style={{ fontSize: '18px' }}
          textAnchor="middle"
          fill={textColor}
          y={22}
          x={xTextTime}
        >
          {moment(timeObserved).format('HH:mm')}
        </text>
        <rect
          id="delay"
          strokeWidth={strokeWidth}
          stroke={trainStrokeColor}
          fill={isTCO ? trainFillColor : statusColor}
          width={38}
          height={28.5}
          x={xDelay}
          y={1.5}
        />
        <text
          id="ndelay"
          className="cartouche-text"
          style={{ fontSize: '16px' }}
          textAnchor="middle"
          fill={textColor}
          y={22}
          x={xTextDelay}
        >
          {isTCO ? 'TCO' : delay}
        </text>
      </>
      )}
      <path
        id="head"
        strokeWidth="2"
        stroke={trainStrokeColor}
        fill={trainFillColor}
        d={trainHead}
        transform={xHead}
      />
      <text
        id="ntrain"
        className="cartouche-train-number"
        style={{ fontSize: '20px' }}
        textAnchor="middle"
        fill={textColor}
        y={24}
        x={xTextTrainNumber}
      >
        {trainNumber}
      </text>
    </svg>
  )
}
