import { ReactElement } from 'react'
import terms from 'common/terms'
import SimpleInput from 'components/Common/Input/SimpleInput'
import TextAreaField from 'components/Common/Input/TextAreaField'
import { useDispatch, useSelector } from 'react-redux'
import { setDashboardDescription, setDashboardName, setPatternError } from 'reducers/zone'
import { RootState } from 'Store'
import { Grid } from '@mui/material'

export default function ModalInputFields(): ReactElement {
  const dispatch = useDispatch()
  const {
    dashboardName, dashboardDescription, responseError, patternError, defaultDashboard,
  } = useSelector((state: RootState) => state.zone)

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const patternMatcher = event.target.value
      .match('^[a-zA-Z0-9ÀÂÄÇÉÈÊËÎÏÔÖÙÛÜŸÆŒàâäçéèêëîïôöùûüÿæœ._~:/?#@!$&\'[\\]()*+,;=-\\s]+')
    dispatch(setDashboardName(event.target.value))
    dispatch(setPatternError(false))
    if ((patternMatcher && patternMatcher[0].length !== event.target.value.length)
     || (patternMatcher === null && event.target.value !== '')) {
      dispatch(setPatternError(true))
    }
  }

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setDashboardDescription(event.target.value))
  }

  return (
    <Grid>
      <SimpleInput
        label={terms.Common.name}
        value={dashboardName}
        onChange={handleNameChange}
        responseError={responseError}
        patternError={patternError}
      />
      {defaultDashboard && (
      <TextAreaField
        label={terms.Dashboard.dashboardDescription}
        value={dashboardDescription}
        onChange={handleDescriptionChange}
      />
      )}
    </Grid>
  )
}
