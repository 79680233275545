import {
  Button, Menu, MenuItem, ThemeProvider,
} from '@mui/material'
import { ReactElement, useState } from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { customActionsButton } from 'common/CustomTheme'
import { Zone } from 'reducers/types'
import { useDispatch } from 'react-redux'
import {
  setDashboardDescription, setDashboardName, setDefaultDashboard, setScreenSize,
} from 'reducers/zone'
import DeleteZoneModal from './DeleteZoneModal'
import UpdateZoneModal from './UpdateZoneModal'

type Props = {
  zone: Zone;
}
export default function ActionsMenu({ zone }: Props): ReactElement {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    dispatch(setDashboardName(''))
    dispatch(setDashboardDescription(''))
    dispatch(setDefaultDashboard(false))
    dispatch(setScreenSize(''))
  }

  return (
    <>
      <ThemeProvider theme={customActionsButton}>
        <Button onClick={handleClick}>
          <MoreHorizIcon />
        </Button>
      </ThemeProvider>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem style={{ fontSize: '12px', minWidth: '180px' }}>
          <UpdateZoneModal closeMenu={handleClose} zone={zone} />
        </MenuItem>
        <MenuItem style={{ fontSize: '12px' }}>
          <DeleteZoneModal zone={zone} closeMenu={handleClose} />
        </MenuItem>
      </Menu>
    </>
  )
}
