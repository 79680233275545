import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ObjectsState, ResizableObjects } from './types'

export const DEFAULT_LATE_PARAMS = {
  is_train_late_delay: 5,
  on_time_color: '#72A512',
  late_color: '#E41F13',
}

const initialState: ObjectsState = {
  objectsLayer: [],
  isLateSettings: DEFAULT_LATE_PARAMS,
}

export const objectsSlice = createSlice({
  name: 'objects',
  initialState,
  reducers: {
    updateObjectsLayer: (state, action: PayloadAction<ResizableObjects[]>) => {
      state.objectsLayer = action.payload
    },
    updateIsLateSettings: (state, action: PayloadAction<typeof DEFAULT_LATE_PARAMS>) => {
      state.isLateSettings = action.payload
    },
  },
})

export const { updateObjectsLayer, updateIsLateSettings } = objectsSlice.actions

export default objectsSlice.reducer
