import { ReactElement } from 'react'
import { Alert, Snackbar } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

interface CustomSnackbarProps {
  message: string,
  displaySnackbar: boolean,
  handleClose: () => void,
  severity?: 'success' | 'info' | 'warning' | 'error',
}

const CustomSnackbar = ({
  message, displaySnackbar, handleClose, severity,
}: CustomSnackbarProps): ReactElement => (
  <Snackbar
    open={displaySnackbar}
    onClose={handleClose}
    autoHideDuration={3000}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    disableWindowBlurListener
  >
    <Alert
      action={(
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={handleClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
        )}
      severity={severity}
      sx={{ width: '100%' }}
    >
      {message}
    </Alert>
  </Snackbar>
)

CustomSnackbar.defaultProps = {
  severity: 'success',
}

export default CustomSnackbar
