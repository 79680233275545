import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import { Perimeter } from 'reducers/types'
import { useDispatch } from 'react-redux'
import terms from 'common/terms'
import PerimetersService from '../../reducers/services/perimetersService'
import './perimeters.scss'

type Props = {
  perimeter: Perimeter,
  closeMenu: any,
}

export default function UpdatePerimeterModal({ perimeter, closeMenu }: Props): ReactElement {
  const dispatch = useDispatch()
  const inputRef = useRef(null)
  const [uploadedFile, setUploadedFile] = useState(null)

  useEffect(() => {
    if (uploadedFile !== null) {
      const formData = new FormData()
      formData.append('file', uploadedFile)
      const params = {
        image: formData,
        slug: perimeter.slug,
      }
      dispatch(PerimetersService.updatePerimeter(params))
    }
  }, [uploadedFile])

  const handleFileChange = event => {
    if (!event.target.files[0]) return
    setUploadedFile(event.target.files[0])
    closeMenu()
  }

  return (
    <>
      <label
        htmlFor="image-input"
        className="menu-button m-0"
      >
        {terms.Perimeter.changePerimeterPhoto}
      </label>
      <input
        style={{ display: 'none' }}
        ref={inputRef}
        type="file"
        id="image-input"
        accept="image/png, image/jpeg, image/jpg"
        onChange={handleFileChange}
      />
    </>
  )
}
