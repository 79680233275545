import { ReactElement } from 'react'
import { Layer, LayerProps, Source } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import ObjectsLayer from './types'

export const sensLayer: LayerProps = {
  id: ObjectsLayer.sensCirculation,
  type: 'symbol',
  minzoom: 15,
  layout: {
    'icon-image': [
      'match',
      ['get', 'circulation_direction'],
      'pair',
      'voie sens Paris',
      'impair',
      'voie autre sens',
      'voie en double sens',
    ],
    'icon-offset': [-200, 0.1],
    'icon-size': {
      stops: [
        [15, 0.6],
        [16, 0.85],
      ],
    },
  },
  paint: { 'icon-opacity': 1 },
}

export default function SensDeCirculation(): ReactElement {
  const { sensCirculationLayer } = useSelector((state: RootState) => state.perimeter)
  return (
    <Source id={ObjectsLayer.sensCirculation} type="geojson" data={sensCirculationLayer}>
      <Layer {...sensLayer} />
    </Source>
  )
}
