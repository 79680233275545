import { RootState } from 'Store'
import { GATEWAY_DOMAIN } from 'config/config'
import moment from 'moment'
import { ReactElement, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  receiveNewAlert, receivePreviousAlerts, resetState, toggleAlertsReopen,
} from 'reducers/alerts'
import { Alert } from 'reducers/types'

export default function AlertStream(): ReactElement {
  const dispatch = useDispatch()
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)
  const { alertsReopen } = useSelector((state: RootState) => state.alerts)
  const ws = useRef<WebSocket>()

  useEffect(() => {
    const wsConnect = () => {
      ws.current = new WebSocket(
        `wss://${GATEWAY_DOMAIN.url}/open_tco/data_stream/monitoring/${activePerimeter?.slug}/?_auth_token=${
          `${localStorage.getItem('access_token')}`}`,
      )

      ws.current.onmessage = e => {
        const data = JSON.parse(e.data)
        // Message on connection
        if (data?.source === 'monitoring') {
          if (data?.alerts) {
            dispatch(receivePreviousAlerts(data.alerts))
          }
        }
        // Debug
        if (data?.source === 'MONITORING') {
          const alert: Alert = {
            ...data,
            timestamp: moment(data.time).format('HH:mm'),
          }
          dispatch(receiveNewAlert(alert))
        }
      }

      ws.current.onclose = () => {
        dispatch(toggleAlertsReopen())
      }

      return ws.current
    }

    const wsCurrent = wsConnect()
    return () => {
      dispatch(resetState())
      wsCurrent.close()
    }
  }, [alertsReopen])

  return (
    <></>
  )
}
