import { Viewport } from '../../types'

export const DEFAULT_VIEWPORT: Viewport = {
  latitude: 48.61,
  longitude: 1.81,
  zoom: 10.75, // zoom ideal 34"
  minZoom: 8.3,
  maxZoom: 17,
  bearing: 179.1,
  pitch: 0,
}

export const DEFAULT_VIEWPORT_SART: Viewport = {
  latitude: 48.91,
  longitude: 1.99,
  zoom: 11.54, // zoom ideal 34"
  minZoom: 8.3,
  maxZoom: 17,
  bearing: 179.1,
  pitch: 0,
}

export const CLUSTERS_POPUP_LATITUDE_LIMIT = 48.625
export const MAX_CARTOUCHES_PER_COLUMN = 100
