import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests/index'
import { ThunkApi } from 'types'
import ObjectsLayer from 'components/Map/types'
import { GetParams, Perimeter } from '../types'

const getPerimetersList = createAsyncThunk<any, GetParams>(
  'perimeters/getPerimetersList',
  async (params: GetParams) => {
    const response = await get('/open_tco/perimeters/', params)
    return response
  },
)

const createPerimeter = createAsyncThunk<Perimeter, Perimeter, ThunkApi>(
  'perimeters/create',
  async (newPerimeter: Perimeter, thunkApi) => {
    try {
      const response: Perimeter = await post('/open_tco/perimeters/', newPerimeter)
      return response
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const updatePerimeter = createAsyncThunk<Perimeter, Perimeter | any, ThunkApi>(
  'perimeters/update',
  async (params: Perimeter | any, thunkApi) => {
    try {
      const response: Perimeter = await patch(`/open_tco/perimeters/${params.slug}`,
        params.image ? params.image : params)
      return response
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const deletePerimeter = createAsyncThunk<string, string, ThunkApi>(
  'perimeters/delete',
  async (perimeterSlug: string, thunkApi) => {
    try {
      await deleteRequest(`/open_tco/perimeters/${perimeterSlug}`)
      return perimeterSlug
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getSignalsLayerData = createAsyncThunk<any, string>(
  'perimeters/getSignalsLayerData',
  async (perimeterSlug: string) => {
    const response = await get(`/chartis/v2/layer/${ObjectsLayer.signaux}/geojson/sch/`,
      {
        perimeter: perimeterSlug,
        page_size: 200000,
      })
    return response
  },
)
const getAdvLayerData = createAsyncThunk<any, string>(
  'perimeters/getAdvLayerData',
  async (perimeterSlug: string) => {
    const response = await get(`/chartis/v2/layer/${ObjectsLayer.adv}/geojson/sch/`,
      {
        perimeter: perimeterSlug,
        page_size: 200000,
      })
    return response
  },
)

const getBvLayerData = createAsyncThunk<any, string>(
  'perimeters/getBvLayerData',
  async (perimeterSlug: string) => {
    const response = await get(`/chartis/v2/layer/${ObjectsLayer.bv}/geojson/sch/`,
      {
        perimeter: perimeterSlug,
        page_size: 200000,
      })
    return response
  },
)

const getSensCirculationLayerData = createAsyncThunk<any, string>(
  'perimeters/getSensCirculationLayerData',
  async (perimeterSlug: string) => {
    const response = await get(`/chartis/v2/layer/${ObjectsLayer.sensCirculation}/geojson/sch/`,
      {
        perimeter: perimeterSlug,
        page_size: 200000,
      })
    return response
  },
)

const getQuaiLayerData = createAsyncThunk<any, string>(
  'perimeters/getQuaiLayerData',
  async (perimeterSlug: string) => {
    const response = await get(`/chartis/v2/layer/${ObjectsLayer.quai}/geojson/sch/`,
      {
        perimeter: perimeterSlug,
        page_size: 200000,
      })
    return response
  },
)

const getTrackLabelLayerData = createAsyncThunk<any, string>(
  'perimeters/getTrackLabelLayerData',
  async (perimeterSlug: string) => {
    const response = await get(`/chartis/v2/layer/${ObjectsLayer.trackLabel}/geojson/sch/`,
      {
        perimeter: perimeterSlug,
        page_size: 200000,
      })
    return response
  },
)

const getPrTrigramLayerData = createAsyncThunk<any, string>(
  'perimeters/getPrTrigramLayerData',
  async (perimeterSlug: string) => {
    const response = await get(`/chartis/v2/layer/${ObjectsLayer.gareTrigram}/geojson/sch/`,
      {
        perimeter: perimeterSlug,
        page_size: 200000,
      })
    return response
  },
)

const getPrLabelLayerData = createAsyncThunk<any, string>(
  'perimeters/getPrLabelLayerData',
  async (perimeterSlug: string) => {
    const response = await get(`/chartis/v2/layer/${ObjectsLayer.gareLabel}/geojson/sch/`,
      {
        perimeter: perimeterSlug,
        page_size: 200000,
      })
    return response
  },
)

const getLayersBbox = createAsyncThunk<GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>,
 string, ThunkApi>(
   'creation/getLayersBbox',
   async (perimeterSlug: string, thunkApi) => {
     try {
       const res = await
       get(`/chartis/v2/layer/${ObjectsLayer.tiv}/bbox/sch/`, { perimeter: perimeterSlug })
       return res
     } catch (e) {
       return thunkApi.rejectWithValue({
         message: e.response.data,
         status: e.response.status,
       })
     }
   },
 )

const PerimetersService = {
  getPerimetersList,
  createPerimeter,
  updatePerimeter,
  deletePerimeter,
  getSignalsLayerData,
  getAdvLayerData,
  getBvLayerData,
  getSensCirculationLayerData,
  getQuaiLayerData,
  getTrackLabelLayerData,
  getPrTrigramLayerData,
  getPrLabelLayerData,
  getLayersBbox,
}

export default PerimetersService
