import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import { toggleLock } from 'reducers/widget'
import { RootState } from 'Store'
import { IconButton } from '@mui/material'

export default function FullscreenButton(): ReactElement {
  const dispatch = useDispatch()
  const { fullscreenHandler } = useSelector((state: RootState) => state.app)

  const fullscreenAndLock = () => {
    if (!fullscreenHandler.active) {
      dispatch(toggleLock(true))
      dispatch(fullscreenHandler.enter)
    }
    dispatch(fullscreenHandler.exit)
  }

  return (
    <IconButton sx={{ color: 'white' }} onClick={fullscreenAndLock}>
      <OpenInFullIcon />
    </IconButton>
  )
}
