import { ReactElement, useState } from 'react'
import { IconButton } from '@mui/material'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { useDispatch, useSelector } from 'react-redux'
import WidgetsService from 'reducers/services/widgetsService'
import { WidgetView } from 'reducers/types'
import { RootState } from 'Store'

type PositionLockProps = {
  widget?: WidgetView | undefined
}

const defaultProps = {
  widget: undefined,
}
export default function PositionLock({ widget }: PositionLockProps): ReactElement {
  const dispatch = useDispatch()
  const { lock, widgetViewport } = useSelector((state: RootState) => state.widget)
  const { activeZone } = useSelector((state: RootState) => state.zone)
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)
  const [isLocked, setIsLocked] = useState<boolean>(widget.is_locked)

  const onViewportLock = () => {
    setIsLocked(!isLocked)
    const requestParams = {
      perimeterSlug: activePerimeter?.slug,
      zonePk: activeZone?.pk,
      widgetSlug: widget.slug,
      widget: widget.is_locked ? {
        is_locked: !widget.is_locked,
      } : {
        is_locked: !widget.is_locked,
        viewport: widgetViewport?.viewport,
      },
    }
    dispatch(WidgetsService.updateWidget(requestParams))
  }

  return (
    <IconButton onClick={onViewportLock} disabled={!lock}>
      {isLocked ? <LockOutlinedIcon sx={{ color: lock ? 'white' : '#00000042' }} />
        : <LockOpenOutlinedIcon sx={{ color: lock ? 'white' : '#00000042' }} />}
    </IconButton>
  )
}

PositionLock.defaultProps = defaultProps
