/* eslint-disable no-unneeded-ternary */
/* eslint-disable max-len */
/* eslint-disable react/style-prop-object */
import { MutableRefObject, ReactElement } from 'react'
import useSupercluster from 'use-supercluster'
import { MapRef, Marker } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { ResizableObjects } from 'reducers/types'
import { Objects, ScreenSize } from 'components/Common/ToolsBar/types'
import TrigramSvg from './GareTrigramSvg'
import { getSize } from '../utils'

type Props = {
  viewportZoom: number
  mapRef: MutableRefObject<MapRef | undefined> | undefined
};

export default function Gares({
  viewportZoom, mapRef,
}: Props): ReactElement {
  const { prTrigramLayer } = useSelector((state: RootState) => state.perimeter)
  const { objectsLayer } = useSelector((state: RootState) => state.objects)
  const garesObject: ResizableObjects = objectsLayer?.filter((obj: ResizableObjects) => obj.label === Objects.Gare)[0]
  const zoomLimit = 12.5

  const radiusTrigramName = viewportZoom < zoomLimit ? 30 * getSize(garesObject?.size) : 120 * getSize(garesObject?.size)
  const points: any = prTrigramLayer?.features.map(gare => ({
    type: 'Feature',
    properties: {
      cluster: false,
      id: gare.properties.id,
      trigram: gare.properties.trigram,
    },
    geometry: {
      type: 'Point',
      coordinates: [
        gare.geometry.coordinates[0],
        gare.geometry.coordinates[1]],
    },
  }))

  const bounds = mapRef?.current
    ? mapRef.current
      .getMap()
      .getBounds()
      .toArray()
      .flat()
    : null

  const { clusters } = useSupercluster({
    points: points ?? [],
    bounds,
    zoom: viewportZoom,
    options: { radius: radiusTrigramName, maxZoom: 15 },
  })

  const getTrigramOffset = () => {
    switch (garesObject?.size) {
      case ScreenSize.Small: return -18
      case ScreenSize.Medium: return -24
      case ScreenSize.Large: return -29
      case ScreenSize.Xlarge: return -33
      default: return -12
    }
  }

  return (
    <>
      {clusters.map(cluster => {
        const [longitude, latitude] = cluster.geometry.coordinates
        const {
          cluster: isCluster,
        } = cluster.properties
        if (!isCluster && viewportZoom < zoomLimit) {
          return (
            <Marker
              key={`gare-${cluster.properties.id}`}
              longitude={longitude}
              latitude={latitude}
              offsetTop={getTrigramOffset()}
              draggable={false}
            >
              <TrigramSvg
                trigram={cluster.properties.trigram}
              />
            </Marker>
          )
        } return (
          <div key={`gare-${cluster.properties.cluster_id ?? cluster.properties.id}`} />
        )
      })}
    </>
  )
}
