import { Objects, ScreenSize } from 'components/Common/ToolsBar/types'
import { ReactElement } from 'react'
import { Layer, LayerProps, Source } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { ResizableObjects } from 'reducers/types'
import { RootState } from 'Store'
import ObjectsLayer from './types'
import { getStops } from './utils'

const signalLayer: LayerProps = {
  id: ObjectsLayer.signaux,
  type: 'circle',
  minzoom: 12.5,
  paint: {
    'circle-radius': [
      'interpolate', ['linear'], ['zoom'],
      14, 3,
      18, 14,
    ],
    'circle-color': '#C0CFD8',
  },
}

const signalNamesLayer: LayerProps = {
  id: `${ObjectsLayer.signaux}-name`,
  type: 'symbol',
  layout: {
    'text-allow-overlap': true,
    'text-field': ['get', 'label'],
    'text-transform': 'uppercase',
    'text-letter-spacing': 0.04,
    'text-font': [
      'Open Sans Regular',
    ],
  },
  paint: {
    'text-color': 'white',
  },
}

export default function Signals() : ReactElement {
  const { objectsLayer } = useSelector((state: RootState) => state.objects)
  const { signalsLayer } = useSelector((state: RootState) => state.perimeter)
  const sgObject: ResizableObjects = objectsLayer.filter((object: ResizableObjects) => object.label
   === Objects.Signals)[0]

  const offsetRate = () => {
    switch (sgObject?.size) {
      case ScreenSize.Small: return 0.9
      case ScreenSize.Medium: return 0.8
      case ScreenSize.Large: return 0.7
      case ScreenSize.Xlarge: return 0.6
      default: return 1
    }
  }

  return (
    <Source id={ObjectsLayer.signaux} type="geojson" data={signalsLayer}>
      <Layer {...{
        ...signalLayer,
      }}
      />
      <Layer {...{
        ...signalNamesLayer,
        layout: {
          ...signalNamesLayer.layout,
          visibility: sgObject?.display ? 'visible' : 'none',
          'text-size': {
            stops: getStops(sgObject?.size),
          },
          'text-offset': [
            'interpolate',
            ['linear'],
            ['zoom'],
            13,
            ['literal', [0, offsetRate()]],
            15,
            ['literal', [0, 1.4 * offsetRate()]],
            17,
            ['literal', [0, 1.8 * offsetRate()]],
            19,
            ['literal', [0, 2.2 * offsetRate()]],
          ],
        },
      }}
      />
    </Source>
  )
}
