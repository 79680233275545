import { Objects } from 'components/Common/ToolsBar/types'
import { ReactElement, useEffect, useState } from 'react'
import { Layer, LayerProps, Source } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { ResizableObjects } from 'reducers/types'
import { RootState } from 'Store'
import ObjectsLayer from './types'
import { getStops } from './utils'

const advNamesLayer: LayerProps = {
  id: `${ObjectsLayer.adv}-name`,
  type: 'symbol',
  layout: {
    'text-allow-overlap': true,
    'text-field': ['get', 'label'],
    'text-transform': 'uppercase',
    'text-offset': [0, 1],
    'text-font': [
      'Open Sans Regular',
    ],
  },
  paint: {
    'text-color': 'white',
    'text-halo-blur': 2,
    'text-halo-color': 'black',
    'text-halo-width': 1,
  },
}

export default function Advs(): ReactElement {
  const { objectsLayer } = useSelector((state: RootState) => state.objects)
  const { advLayer } = useSelector((state: RootState) => state.perimeter)
  const advObject: ResizableObjects = objectsLayer?.filter((obj: ResizableObjects) => obj.label === Objects.Adv)[0]
  const [treatedAdvData, setTreatedAdvData] = useState<any>(null)

  useEffect(() => {
    if (advLayer) {
      const featuresList: Array<GeoJSON.Feature> = []
      advLayer.features.forEach((feature: any) => {
        const newFeature = {
          ...feature,
          geometry: feature.geometry && feature.geometry.type !== null ? {
            type: feature.geometry.type,
            coordinates: feature.geometry.coordinates,
          } : null,
        }
        featuresList.push(newFeature)
      })
      const labelGeojson: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> = {
        type: 'FeatureCollection',
        features: featuresList,
      }
      setTreatedAdvData(labelGeojson)
    }
  }, [advLayer])

  return (
    <Source id={ObjectsLayer.adv} type="geojson" data={treatedAdvData}>
      <Layer
        {...{
          ...advNamesLayer,
          minzoom: 14,
          layout: {
            ...advNamesLayer.layout,
            visibility: advObject?.display ? 'visible' : 'none',
            'text-size': {
              stops: getStops(advObject?.size),
            },
          },
        }}
      />
    </Source>
  )
}
