import { ReactElement } from 'react'
import bannerBG from 'assets/images/img-top-banner.jpg'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import Stack from '@mui/material/Stack'
import terms from 'common/terms'

export default function Banner(): ReactElement {
  const styles = {
    bannerContainer: {
      backgroundImage: `url(${bannerBG})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '0px 0px 0px 100px',
    },
  }

  return (
    <Fade in timeout={500}>
      <Box style={styles.bannerContainer}>
        <Grid
          container
          justifyContent="center"
          sx={{
            height: '310px', width: '100%', pl: 3, mt: '60px',
          }}
        >
          <Grid item xs={12} sm={10} md={8} sx={{ mt: 10 }}>
            <Stack direction="row" spacing={2} sx={{ mb: '20px' }}>
              <Typography sx={{
                fontWeight: '400', fontSize: '56px', color: '#000000', fontFamily: 'Avenir',
              }}
              >
                {terms.Perimeter.welcomeTo}
              </Typography>
              <Typography sx={{
                fontWeight: '800', fontSize: '56px', color: '#000000', fontFamily: 'Avenir',
              }}
              >
                {terms.Common.appName}
              </Typography>
            </Stack>
            <Typography variant="subtitle1">
              {terms.Perimeter.selectCard}
              <ArrowRightAltIcon sx={{ ml: 1 }} />
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  )
}
