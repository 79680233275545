import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrderBy } from 'components/Common/types'
import ZonesService from 'reducers/services/zonesService'
import {
  DashboardTab, HiddenCartouches, Zone, ZoneState,
} from './types'

const initialState: ZoneState = {
  zonesList: [],
  deletedZone: false,
  dashboardName: '',
  dashboardDescription: '',
  defaultDashboard: false,
  screenSize: '',
  patternError: false,
  updatedZone: false,
  orderBy: OrderBy.lastCreated,
  dashboardTabs: [],
  hiddenCartouches: [],
}

const updateZonesList = (state: ZoneState, action: PayloadAction<Array<Zone>>) => {
  state.zonesList = action.payload
}

const addZone = (state: ZoneState, action: PayloadAction<Zone>) => {
  state.zonesList.push(action.payload)
  state.activeZone = action.payload
  state.dashboardTabs.push({ key: action.payload.pk, value: action.payload.label })
  state.responseError = undefined
}

const deleteZone = (state: ZoneState, action: PayloadAction<any>) => {
  state.zonesList = state.zonesList.filter(zone => zone.pk !== action.payload)
  state.dashboardTabs = state.dashboardTabs.filter(dashboard => dashboard.key !== action.payload)
  state.deletedZone = true
}

export const zoneSlice = createSlice({
  name: 'zone',
  initialState,
  reducers: {
    updateActiveZone: (state, action: PayloadAction<Zone>) => {
      state.activeZone = action.payload
    },
    updateDeletedZone: (state, action: PayloadAction<boolean>) => {
      state.deletedZone = action.payload
    },
    setUpdatedZone: (state, action: PayloadAction<boolean>) => {
      state.updatedZone = action.payload
    },
    setDashboardName: (state, action: PayloadAction<string>) => {
      state.dashboardName = action.payload
    },
    setDashboardDescription: (state, action: PayloadAction<string>) => {
      state.dashboardDescription = action.payload
    },
    setDefaultDashboard: (state, action: PayloadAction<boolean>) => {
      state.defaultDashboard = action.payload
    },
    setScreenSize: (state, action: PayloadAction<string>) => {
      state.screenSize = action.payload
    },
    updateResponseError: state => {
      state.responseError = undefined
    },
    setPatternError: (state, action: PayloadAction<boolean>) => {
      state.patternError = action.payload
    },
    setOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload
    },
    updateDashboardTabs: (state, action: PayloadAction<DashboardTab[]>) => {
      state.dashboardTabs = action.payload
    },
    setZonesList: (state, action: PayloadAction<Array<Zone>>) => {
      state.zonesList = action.payload
    },
    updateHiddenCartouches: (state, action: PayloadAction<Array<HiddenCartouches>>) => {
      state.hiddenCartouches = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(ZonesService.getZonesList.fulfilled, updateZonesList)
    builder.addCase(ZonesService.createZone.fulfilled, addZone)
    builder.addCase(ZonesService.createZone.rejected, (state, action) => {
      state.responseError = action.payload
    })
    builder.addCase(ZonesService.updateZone.fulfilled, (state, action) => {
      state.zonesList = state.zonesList.map(zone => (zone.pk === action.payload.pk ? action.payload.response
        : zone))
      state.dashboardTabs = state.dashboardTabs.map(tab => (tab.key === action.payload.oldPk
        ? { key: action.payload.response.pk, value: action.payload.response.label } : tab))
      state.updatedZone = true
      state.responseError = undefined
    })
    builder.addCase(ZonesService.updateZone.rejected, (state, action) => {
      state.responseError = action.payload
    })
    builder.addCase(ZonesService.deleteZone.fulfilled, deleteZone)
  },
})

export const {
  updateActiveZone, updateDeletedZone, setDashboardName, setDashboardDescription,
  setDefaultDashboard, setScreenSize, updateResponseError, setPatternError, setOrderBy, updateDashboardTabs,
  setUpdatedZone, setZonesList, updateHiddenCartouches,
} = zoneSlice.actions

export default zoneSlice.reducer
