import { ReactElement, useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import { Perimeter } from 'reducers/types'
import { useDispatch, useSelector } from 'react-redux'
import terms from 'common/terms'
import SimpleInput from 'components/Common/Input/SimpleInput'
import { ThemeProvider } from '@mui/material'
import { customCancelButton, customCreationButton } from 'common/CustomTheme'
import { RootState } from 'Store'
import { updateResponseError } from 'reducers/perimeter'
import PerimetersService from '../../reducers/services/perimetersService'
import './perimeters.scss'

type Props = {
  perimeter: Perimeter,
  closeMenu: any,
}

export default function UpdatePerimeterModal({ perimeter, closeMenu }: Props): ReactElement {
  const dispatch = useDispatch()
  const [perimeterName, setPerimeterName] = useState<string>('')
  const [patternError, setPatternError] = useState<boolean>(false)
  const { responseError, updatedPerimeter } = useSelector((state: RootState) => state.perimeter)

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)

  useEffect(() => {
    setPerimeterName(perimeter.label)
  }, [])

  const onCancel = () => {
    setOpen(false)
    setPerimeterName('')
    dispatch(updateResponseError(undefined))
    setPatternError(false)
    closeMenu()
  }
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') { return }
    onCancel()
  }

  useEffect(() => {
    if (!responseError && open) onCancel()
  }, [responseError, updatedPerimeter])

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const patternMatcher = event.target.value
      .match('^[a-zA-Z0-9ÀÂÄÇÉÈÊËÎÏÔÖÙÛÜŸÆŒàâäçéèêëîïôöùûüÿæœ._~:/?#@!$&\'[\\]()*+,;=-\\s]+')
    setPerimeterName(event.target.value)
    setPatternError(false)
    if ((patternMatcher && patternMatcher[0].length !== event.target.value.length)
     || (patternMatcher === null && event.target.value !== '')) {
      setPatternError(true)
    }
  }

  const updatePerimeter = event => {
    event.preventDefault()
    const params = {
      label: perimeterName,
      slug: perimeter.slug,
    }
    dispatch(PerimetersService.updatePerimeter(params))
  }

  return (
    <>
      <div className="menu-button" role="button" tabIndex={0} onClick={handleOpen}>
        {terms.Perimeter.renamePerimeter}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title" className="modal-title">
          {terms.Perimeter.renamePerimeter}
          {' '}
          {perimeter.label}
        </DialogTitle>
        <DialogContent>
          <SimpleInput
            label={terms.Common.name}
            value={perimeterName}
            onChange={handleNameChange}
            responseError={responseError}
            patternError={patternError}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', p: '20px 24px' }}>
          <ThemeProvider theme={customCancelButton}>
            <Button onClick={onCancel}>
              {terms.Common.cancel}
            </Button>
          </ThemeProvider>
          <ThemeProvider theme={customCreationButton}>
            <Button
              onClick={updatePerimeter}
              disabled={perimeterName === '' || patternError || perimeterName === perimeter.label}
            >
              {terms.Common.update}
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
    </>
  )
}
