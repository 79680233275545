/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { ReactElement, useState } from 'react'
import '../../../App.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { ResizableObjects } from 'reducers/types'
import { Objects } from 'components/Common/ToolsBar/types'
import { updateHiddenCartouches } from 'reducers/zone'
import IconButton from '@mui/material/IconButton'
import { customCancelButton, customCreationButton } from 'common/CustomTheme'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import terms from 'common/terms'
import { DEFAULT_LATE_PARAMS } from 'reducers/objects'
import {
  getHeadOthers, getHeadParis, getHeadUnknown, getScale,
} from './utils'
import CartoucheSvg from './CartoucheSvg'

type Props = {
  direction: boolean | null
  trainNumber: string | null
  railway: string | null
  delay: number | null
  timeObserved: string | null
  shortTrain: boolean
  isTco: boolean
  id: string
}

export default function CartoucheSetup({
  direction, trainNumber, railway, delay, timeObserved, shortTrain, id, isTco,
}: Props): ReactElement {
  const dispatch = useDispatch()
  const { hiddenCartouches } = useSelector((state: RootState) => state.zone)
  const { objectsLayer, isLateSettings } = useSelector((state : RootState) => state.objects)
  const cartoucheObject: ResizableObjects = objectsLayer.filter((object: ResizableObjects) => object.label === Objects.Cartouche)[0]

  const statusColor = (delay >= (isLateSettings.is_train_late_delay ?? DEFAULT_LATE_PARAMS.is_train_late_delay))
    ? isLateSettings.late_color ?? DEFAULT_LATE_PARAMS.late_color : isLateSettings.on_time_color ?? DEFAULT_LATE_PARAMS.on_time_color
  let railwayShort = ''
  if (railway) {
    railwayShort = (railway.substring(0, 1) === 'V' ? railway.substring(1) : railway.substring(0)).replaceAll(/\s/g, '')
  }

  const scale = getScale(cartoucheObject.size)

  const trainHead = direction === null ? getHeadUnknown() : direction ? getHeadParis() : getHeadOthers()
  const xHead = direction === true ? 'translate(0)' : (shortTrain ? 'translate(0)' : 'translate(139)')
  const xTime = direction ? '140' : '39'
  const xDelay = direction ? '202' : '1'
  const xTextTrainNumber = direction ? '57' : (shortTrain ? '47' : '186')
  const xTextTime = direction ? '170' : '69'
  const xTextDelay = direction ? '221' : '20'

  const acceptableDelay = Date.now() - (10 * 60 * 1000)
  const opacity = Date.parse(timeObserved) > acceptableDelay ? '1' : '0.3'

  const [open, setOpen] = useState<boolean>(false)
  const handleOpen = () => setOpen(true)
  const onCancel = () => setOpen(false)
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') { return }
    onCancel()
  }
  const hideCartouche = () => {
    const hiddenList = [...hiddenCartouches, id]
    dispatch(updateHiddenCartouches(hiddenList))
  }

  const trainStrokeColor = isTco ? '#C0CFD8' : '#F6F8F9'
  const trainFillColor = isTco ? '#000000' : '#C0CFD8'
  const textColor = isTco ? '#FFFFFF' : '#000000'

  return (
    <>
      <IconButton
        sx={{
          p: 0,
          width: shortTrain ? 102 * scale : 242 * scale,
          height: 40 * scale,
        }}
        onClick={handleOpen}
      >
        <CartoucheSvg
          opacity={opacity}
          trainHead={trainHead}
          xHead={xHead}
          xTime={xTime}
          statusColor={statusColor}
          xDelay={xDelay}
          xTextTrainNumber={xTextTrainNumber}
          trainNumber={trainNumber}
          railwayShort={railwayShort}
          xTextTime={xTextTime}
          timeObserved={timeObserved}
          xTextDelay={xTextDelay}
          delay={delay}
          shortTrain={shortTrain}
          strokeWidth={2}
          trainStrokeColor={trainStrokeColor}
          trainFillColor={trainFillColor}
          textColor={textColor}
          isTCO={isTco}
        />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ m: '10px' }}
      >
        <DialogTitle id="alert-dialog-title" className="modal-title">
          {terms.ObjectsToolBar.confirmTitle}
        </DialogTitle>
        <DialogActions sx={{ justifyContent: 'center', p: '20px 24px' }}>
          <ThemeProvider theme={customCancelButton}>
            <Button onClick={onCancel}>
              {terms.Common.cancel}
            </Button>
          </ThemeProvider>
          <ThemeProvider theme={customCreationButton}>
            <Button onClick={hideCartouche}>
              {terms.Common.yes}
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
    </>
  )
}
