import { ReactElement } from 'react'
import Card from '@mui/material/Card'
import AddIcon from '@mui/icons-material/Add'
import CardActionArea from '@mui/material/CardActionArea'

type Props = {
    handleOpen: () => void,
}

export default function AddButton({ handleOpen }: Props): ReactElement {
  return (
    <Card
      sx={{ width: '90%', height: 220 }}
      className="perimeter-card shadow-none"
    >
      <CardActionArea
        aria-label="settings"
        onClick={handleOpen}
        sx={{ width: '100%', height: '100%', textAlign: 'center' }}
      >
        <AddIcon sx={{ color: '#CFCFCF', fontSize: '100px' }} />
      </CardActionArea>
    </Card>
  )
}
