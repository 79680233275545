import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Alerts, Stream, StreamState } from './types'

const initialState: StreamState = {
  tcoDataStream: undefined,
  tcoStreamState: false,
  tcoReOpen: false,
  basicDataStream: undefined,
  basicStreamState: false,
  basicReOpen: false,
}

export const viewSlice = createSlice({
  name: 'streams',
  initialState,
  reducers: {
    reset: () => initialState,
    updateTcoDataStream: (state, action: PayloadAction<Stream>) => {
      state.tcoDataStream = action.payload
    },
    updateTcoStreamState: (state, action: PayloadAction<boolean>) => {
      state.tcoStreamState = action.payload
    },
    toggleTcoReOpen: (state, action: PayloadAction<boolean>) => {
      state.tcoReOpen = action.payload
    },
    updateBasicDataStream: (state, action: PayloadAction<Stream>) => {
      state.basicDataStream = action.payload
    },
    updateBasicStreamState: (state, action: PayloadAction<boolean>) => {
      state.basicStreamState = action.payload
    },
    toggleBasicReOpen: (state, action: PayloadAction<boolean>) => {
      state.basicReOpen = action.payload
    },
    updateTcoAlerts: (state, action: PayloadAction<Alerts>) => {
      state.tcoAlerts = action.payload
    },
    updateBasicAlerts: (state, action: PayloadAction<Alerts>) => {
      state.basicAlerts = action.payload
    },
  },
})

export const {
  updateTcoDataStream, updateTcoStreamState, toggleTcoReOpen, updateBasicDataStream, updateBasicStreamState,
  toggleBasicReOpen, updateTcoAlerts, updateBasicAlerts, reset,
} = viewSlice.actions

export default viewSlice.reducer
