/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import {
  ReactElement,
  MutableRefObject,
  Ref,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MapGL, { MapRef, AttributionControl, NavigationControl } from 'react-map-gl'

import sensVoieParis from 'assets/images/sensvoie-paris.png'
import sensVoieAutre from 'assets/images/sensvoie-autre.png'
import sensVoieDouble from 'assets/images/sensvoie-double.png'
import direction from 'assets/images/direction.png'
import { Viewport } from 'types'
import mapStyle from 'assets/styles/mapStyle.json'
import { updateViewport } from 'reducers/widget'
import { RootState } from 'Store'
import { WidgetView } from 'reducers/types'
import PerimetersService from 'reducers/services/perimetersService'
import bbox from '@turf/bbox'
import { hasAdminAccess, hasOperatorAccess } from '../../helpers/permissions'
import { DEFAULT_VIEWPORT } from './DefaultValues'
import { transformRequest } from './utils'
import MapLayers from './MapLayers'

const defaultViewport = DEFAULT_VIEWPORT

type Props = {
  viewportProp?: Viewport
  widget?: WidgetView | undefined
  shortTrain: boolean
}

const defaultProps = {
  viewportProp: defaultViewport,
  widget: undefined,
}

export default function Map({ viewportProp, widget, shortTrain }: Props): ReactElement {
  const dispatch = useDispatch()
  const [viewport, setViewport] = useState(viewportProp)
  const { lock } = useSelector((state: RootState) => state.widget)
  const { activePerimeter, layerBbox } = useSelector((state: RootState) => state.perimeter)
  const { activeZone } = useSelector((state: RootState) => state.zone)
  const mapRef: MutableRefObject<MapRef | undefined> | undefined = useRef()

  useEffect(() => {
    if (activePerimeter) {
      dispatch(PerimetersService.getLayersBbox(activePerimeter?.slug))
    }
  }, [activePerimeter])

  useEffect(() => {
    if (layerBbox !== null && layerBbox.bbox !== null) {
      const centeredMap = mapRef.current?.getMap().cameraForBounds(bbox(layerBbox.bbox), {
        padding: 20,
        bearing: activePerimeter?.viewport.bearing || viewport.bearing,
      })
      const newViewport = {
        ...viewport,
        zoom: centeredMap.zoom,
        latitude: centeredMap.center.lat,
        longitude: centeredMap.center.lng,
        minZoom: centeredMap.zoom < viewport.zoom ? centeredMap.zoom : viewport.minZoom,
        bearing: activePerimeter?.viewport.latitude !== 0 ? activePerimeter?.viewport.bearing : viewport.bearing,
      }
      if (widget?.viewport === newViewport || !widget) {
        setViewport(newViewport)
      } else {
        setViewport(widget.viewport)
      }
      if (activePerimeter?.viewport.latitude === 0) {
        dispatch(PerimetersService.updatePerimeter({ slug: activePerimeter.slug, viewport: newViewport }))
      }
    }
  }, [layerBbox])

  useEffect(() => {
    if (mapRef.current) {
      const currentMap = mapRef.current.getMap()
      currentMap.loadImage(sensVoieParis, (_error: string, img: string) => currentMap.addImage('voie sens Paris', img))
      currentMap.loadImage(sensVoieAutre, (_error: string, img: string) => currentMap.addImage('voie autre sens', img))
      currentMap.loadImage(sensVoieDouble, (_error: string, img: string) => currentMap.addImage('voie en double sens', img))
      currentMap.loadImage(direction, (_error: string, img: string) => currentMap.addImage('direction', img))
    }
  }, [])

  const getCursor = () => ((widget ? (widget.is_locked && lock) || (!hasAdminAccess() && !hasOperatorAccess()) : false) ? 'default' : 'grab')
  const getCursorOpentcoAccess = () => ((widget ? (widget.is_locked && lock) : false) ? 'default' : 'grab')

  const zoneTypeParams = () => (activeZone?.default
    ? (!widget.is_locked && lock
          && (hasAdminAccess() || hasOperatorAccess()))
    : !widget.is_locked && lock)

  const onViewportChange = (newViewport: Viewport) => {
    if (layerBbox !== null && layerBbox.bbox !== null) {
      const maxBound = bbox(layerBbox.bbox)
      if ((newViewport.longitude >= (maxBound[0] - 0.05) && newViewport.longitude <= (maxBound[2] + 0.05))
        && (newViewport.latitude >= (maxBound[1] - 0.05) && newViewport.latitude <= (maxBound[3] + 0.05))) {
        setViewport(newViewport)
        const newWidgetViewport = {
          viewport: newViewport,
          widgetSlug: widget?.slug,
        }
        dispatch(updateViewport(newWidgetViewport))
      }
    }
  }

  const onClickZoom = e => {
    const zoomValue = e.target.parentNode.title === 'Zoom In' ? (0.1) : (-0.1)
    const newViewportZoom = {
      ...viewport,
      zoom: viewport.zoom + zoomValue,
    }
    setViewport(newViewportZoom)
    const newWidgetViewport = {
      viewport: newViewportZoom,
      widgetSlug: widget?.slug,
    }
    dispatch(updateViewport(newWidgetViewport))
  }

  const lockNavigationControlDefaultZoom = () => undefined

  return (
    <>
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        transformRequest={transformRequest}
        mapStyle={mapStyle}
        onViewportChange={(newViewport: Viewport) => onViewportChange(newViewport)}
        dragRotate={false}
        ref={mapRef as Ref<MapRef>}
        attributionControl={false}
        getCursor={activeZone?.default ? getCursor : getCursorOpentcoAccess}
        doubleClickZoom={widget ? zoneTypeParams() : true}
        dragPan={widget ? zoneTypeParams() : true}
        scrollZoom={widget ? zoneTypeParams() : true}
      >
        <MapLayers
          viewport={viewport}
          mapRef={mapRef}
          shortTrain={shortTrain}
        />
        <AttributionControl className="custom-attribution" />
        {(!widget
            || (!activeZone?.default && !widget.is_locked && lock)
            || ((activeZone?.default && !widget.is_locked && lock) && (hasAdminAccess() || hasOperatorAccess())))
          && (
          <div className="map-infos">
            {viewport?.zoom && (
            <div className="zoom-display">
              <span>{viewport.zoom.toFixed(1)}</span>
            </div>
            )}
            <NavigationControl
              showCompass={false}
              className="navigation-control-position"
              onClick={e => onClickZoom(e)}
              onViewportChange={() => lockNavigationControlDefaultZoom()}
            />
          </div>
          )}
      </MapGL>
    </>
  )
}

Map.defaultProps = defaultProps
