import { ReactElement } from 'react'
import terms from 'common/terms'
import {
  ThemeProvider, Dialog, Button, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Box,
} from '@mui/material'
import { customButton } from 'common/CustomTheme'
import logo from 'assets/logos/logo-DGEXSOL-SNCF-colo.svg'

type Props = {
  handleOpen: () => void,
  isOpen: boolean
}

export default function Cgu({ handleOpen, isOpen }: Props): ReactElement {
  return (
    <Dialog
      open={!isOpen}
      disableEscapeKeyDown
    >
      <Box p={2} display="flex" flexDirection="column" alignItems="center" textAlign="center">
        <img alt="" src={logo} width="200" />
        <DialogTitle>
          {terms.CGU.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {terms.CGU.contentText1}
          </DialogContentText>
          <DialogContentText>
            <a
              href="https://digidocplus.sncf.fr/visionneusePdf/1702381270603qHBJVDp"
              target="_blank"
              rel="noreferrer"
            >
              {terms.CGU.contentLink1}
            </a>
          </DialogContentText>
        </DialogContent>
        <DialogContent sx={{ paddingTop: 0 }}>
          <DialogContentText>
            {terms.CGU.contentText2}
          </DialogContentText>
          <DialogContentText>
            <a
              href="https://sncf.sharepoint.com/sites/Les10CybeReflexes"
              target="_blank"
              rel="noreferrer"
            >
              {terms.CGU.contentLink2}
            </a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ThemeProvider theme={customButton}>
            <Button onClick={handleOpen}>
              {terms.CGU.btn}
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Box>

    </Dialog>
  )
}
