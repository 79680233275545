import { ReactElement } from 'react'
import {
  Checkbox, FormControlLabel, FormGroup, Grid,
} from '@mui/material'
import { RootState } from 'Store'
import { useDispatch, useSelector } from 'react-redux'
import { setDefaultDashboard } from 'reducers/zone'
import terms from 'common/terms'
import { hasAdminAccess, hasOperatorAccess } from 'helpers/permissions'
import ModalInputFields from './ModalInputFields'
import ScreenSizeCheckbox from './ScreenSizeCheckbox'

export default function FormContent(): ReactElement {
  const dispatch = useDispatch()
  const { defaultDashboard } = useSelector((state: RootState) => state.zone)

  return (
    <Grid>
      <ModalInputFields />
      {(hasAdminAccess() || hasOperatorAccess()) && (
      <FormGroup sx={{ mt: '10px' }}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={defaultDashboard}
              onChange={() => dispatch(setDefaultDashboard(!defaultDashboard))}
              sx={{ color: '#C8C8C8' }}
            />
              )}
          label={terms.Dashboard.defaultDashboard}
        />
      </FormGroup>
      )}
      {defaultDashboard && <ScreenSizeCheckbox />}
    </Grid>
  )
}
