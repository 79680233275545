import { ReactElement } from 'react'
import { Button, IconButton, ThemeProvider } from '@mui/material'
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined'
import terms from 'common/terms'
import { customPositionButton } from 'common/CustomTheme'
import { useDispatch, useSelector } from 'react-redux'
import { toggleLock } from 'reducers/widget'
import { RootState } from 'Store'
import { WidgetPosition } from 'reducers/types'
import WidgetsService from 'reducers/services/widgetsService'

import './toolsbar.scss'

export default function WidgetsPositionLock(): ReactElement {
  const dispatch = useDispatch()
  const { lock, widgetsPositions, widgetsList } = useSelector((state: RootState) => state.widget)
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)
  const { activeZone } = useSelector((state: RootState) => state.zone)

  const handleOnClick = () => {
    dispatch(toggleLock(!lock))
  }

  const savePosition = () => {
    dispatch(toggleLock(true))
    widgetsPositions?.map((position: WidgetPosition) => (
      dispatch(WidgetsService.updateWidget({
        perimeterSlug: activePerimeter.slug,
        zonePk: activeZone?.pk,
        widgetSlug: position.widgetSlug,
        widget: {
          layout_position: { ...position.layout_position },
          viewport: widgetsList.find(widget => widget.slug === position.widgetSlug)?.viewport,
        },
      }))
    ))
  }

  return (
    <>
      <IconButton
        className={`toolbar-action-icon${!lock ? ' active-position-save-icon' : ''}`}
        onClick={handleOnClick}
      >
        <GridViewOutlinedIcon />
      </IconButton>
      {!lock && (
      <ThemeProvider theme={customPositionButton}>
        <Button onClick={savePosition}>{terms.Widget.savePosition}</Button>
      </ThemeProvider>
      )}
    </>
  )
}
