import terms from 'common/terms'

export enum OrderBy {
    name = 'name',
    lastName= '-name',
    lastCreated = '-created',
    lastModified = '-modified',
    created = 'created',
    modified = 'modified',
}

export const OrderingList = [
  {
    key: OrderBy.name,
    value: terms.Common.alphabeticalOrder,
  },
  {
    key: OrderBy.lastName,
    value: terms.Common.alphabeticalOrder,
  },
  {
    key: OrderBy.lastCreated,
    value: terms.Common.creationDate,
  },
  {
    key: OrderBy.created,
    value: terms.Common.creationDate,
  },
  {
    key: OrderBy.lastModified,
    value: terms.Common.modificationDate,
  },
  {
    key: OrderBy.modified,
    value: terms.Common.modificationDate,
  },
]
