import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TcoDataStream from 'components/Common/TcoDataStream'
import BasicStream from 'components/Common/BasicStream'
import WidgetsService from 'reducers/services/widgetsService'
import { RootState } from 'Store'
import { WidgetPosition, WidgetView } from 'reducers/types'
import { Responsive, WidthProvider } from 'react-grid-layout'
import Widget from 'components/Widgets/Widget'
import ToolsBar from 'components/Common/ToolsBar/ToolsBar'
import AddWidgetModal from 'components/Widgets/AddWidgetModal'
import { updateWidgetsPositions } from 'reducers/widget'
import Loading from 'components/Common/Backdrop/Loading'
import AlertStream from 'components/Common/AlertStream'
import { updateSettings } from 'reducers/alerts'

const ResponsiveGridLayout = WidthProvider(Responsive)

export default function WidgetsViewMap(): ReactElement {
  const dispatch = useDispatch()
  const { fullscreenHandler } = useSelector((state: RootState) => state.app)
  const { activeZone } = useSelector((state : RootState) => state.zone)
  const { activePerimeter } = useSelector((state : RootState) => state.perimeter)
  const {
    widgetsList, lock, updatedWidget, loading,
  } = useSelector((state : RootState) => state.widget)

  const params = {
    isDraggable: !lock,
    isResizable: !lock,
  }

  useEffect(() => {
    dispatch(WidgetsService.getWidgetsList({ perimeterSlug: activePerimeter?.slug, zonePk: activeZone?.pk }))
  }, [activeZone, updatedWidget])

  const onLayoutChange = (layout: Array<ReactGridLayout.Layout>) => {
    const positionsList: WidgetPosition[] = []
    layout.map(l => (
      positionsList.push({
        widgetSlug: widgetsList.filter((widget: WidgetView) => widget.label === l.i)[0].slug,
        layout_position: { ...l, min_h: 2, min_w: 2 },
      })
    ))
    dispatch(updateWidgetsPositions(positionsList))
  }

  useEffect(() => {
    if (activeZone && activeZone.monitoring_config) {
      dispatch(updateSettings({
        volume: activeZone.monitoring_config.volume * 100,
        soundAlerts: activeZone.monitoring_config.sound_active,
        textAlerts: activeZone.monitoring_config.pop_up_active,
        notifiedStations: activeZone.monitoring_config.notified_gares.map(gare => ({
          label: gare.gare,
          enabled: gare.enabled,
        })),
      }))
    }
  }, [activeZone])

  return (
    <main className={`mastcontainer mastcontainer-map${fullscreenHandler.active ? ' fullscreen' : ''}`}>
      {widgetsList.length === 0 && <Loading open={loading} />}
      {(!loading || widgetsList.length !== 0) && (
      <>
        {(activePerimeter.tco || activePerimeter.tconext) && <TcoDataStream />}
        {(activePerimeter.tco || activePerimeter.tconext || activePerimeter.basic) && <AlertStream />}
        {activePerimeter.basic && (<BasicStream />)}
        <ToolsBar showAlerts />
        {widgetsList.length !== 0 ? (
          <ResponsiveGridLayout
            {...params}
            breakpoints={{
              lg: 1500, md: 1200, sm: 900, xs: 600, xxs: 0,
            }}
            cols={{
              lg: 48, md: 36, sm: 24, xs: 12, xxs: 6,
            }}
            rowHeight={35}
            className={`layout${fullscreenHandler.active ? ' fullscreen' : ''}`}
            autoSize
            measureBeforeMount
            useCSSTransforms
            resizeHandles={['e', 's', 'se']}
            onLayoutChange={onLayoutChange}
          >
            {widgetsList.length !== 0 && widgetsList.map((widget: WidgetView) => (
              <div
                className={`widget-grid-top${fullscreenHandler.active ? ' fullscreen' : ''}`}
                key={widget.label}
                data-grid={widget.layout_position}
              >
                <Widget params={widget} />
              </div>
            ))}
          </ResponsiveGridLayout>
        ) : <AddWidgetModal />}
      </>
      )}
    </main>
  )
}
