import terms from 'common/terms'

export enum ScreenSize {
    XXSmall = 'XXS',
    XSmall = 'XS',
    Small = 'S',
    Medium = 'M',
    Large = 'L',
    Xlarge = 'XL',
    XXLarge = 'XXL',
}

export const ScreenSizeToNumbers = [
  {
    numberValue: 0,
    size: ScreenSize.XXSmall,
  },
  {
    numberValue: 1,
    size: ScreenSize.XSmall,
  },
  {
    numberValue: 2,
    size: ScreenSize.Small,
  },
  {
    numberValue: 3,
    size: ScreenSize.Medium,
  },
  {
    numberValue: 4,
    size: ScreenSize.Large,
  },
  {
    numberValue: 5,
    size: ScreenSize.Xlarge,
  },
  {
    numberValue: 6,
    size: ScreenSize.XXLarge,
  },
]

export enum Objects {
    Cartouche = 'circulation_boxes',
    DisparitionDelay = 'disparition_delay',
    Agregation = 'agregation',
    Signals = 'signals',
    Adv = 'switches',
    Tiv = 'tracks',
    Gare = 'stations',
    Annonces = 'annonce',
    Quais = 'quais',
}

export const ObjectsLabels = [
  {
    key: Objects.Cartouche,
    label: terms.ObjectsToolBar.cartouches,
  },
  {
    key: Objects.DisparitionDelay,
    label: terms.ObjectsToolBar.disparitionDelay,
  },
  {
    key: Objects.Agregation,
    label: terms.ObjectsToolBar.agregation,
  },
  {
    key: Objects.Signals,
    label: terms.ObjectsToolBar.signaux,
  },
  {
    key: Objects.Adv,
    label: terms.ObjectsToolBar.adv,
  },
  {
    key: Objects.Tiv,
    label: terms.ObjectsToolBar.tiv,
  },
  {
    key: Objects.Gare,
    label: terms.ObjectsToolBar.gares,
  },
  {
    key: Objects.Annonces,
    label: terms.ObjectsToolBar.annonces,
  },
  {
    key: Objects.Quais,
    label: terms.ObjectsToolBar.quais,
  },
]
