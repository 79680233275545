import * as React from 'react'
import { ReactElement } from 'react'
import { Perimeter } from 'reducers/types'
import SettingsIcon from '@mui/icons-material/Settings'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import DeletePerimeterModal from './DeletePerimeterModal'
import PhotoPerimeterModal from './PhotoPerimeterModal'
import UpdatePerimeterModal from './UpdatePerimeterModal'

type Props = {
  perimeter: Perimeter,
}

export default function SettingsMenu({ perimeter }: Props): ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <IconButton
        aria-label="settings"
        onClick={handleClick}
        sx={{ color: 'white' }}
        className="icon-button-settings"
      >
        <SettingsIcon sx={{ color: 'white', fontSize: '30px' }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem>
          <PhotoPerimeterModal perimeter={perimeter} closeMenu={closeMenu} />
        </MenuItem>
        <MenuItem sx={{ marginY: '5px' }}>
          <UpdatePerimeterModal perimeter={perimeter} closeMenu={closeMenu} />
        </MenuItem>
        <MenuItem>
          <DeletePerimeterModal perimeter={perimeter} closeMenu={closeMenu} />
        </MenuItem>
      </Menu>
    </>
  )
}
