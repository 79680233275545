/* eslint-disable max-len */
import { ReactElement, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { updateActivePerimeter } from 'reducers/perimeter'
import { updateActiveZone, updateDashboardTabs } from 'reducers/zone'
import { updateWidgetsList } from 'reducers/widget'
import Container from '@mui/material/Container'
import Banner from './Banner'
import CardsList from './CardsList'

export default function Home(): ReactElement {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateActivePerimeter(undefined))
    dispatch(updateActiveZone(undefined))
    dispatch(updateDashboardTabs([]))
    dispatch(updateWidgetsList([]))
  }, [])

  return (
    <Container maxWidth={false} disableGutters sx={{ height: '100%' }}>
      <Banner />
      <CardsList />
    </Container>
  )
}
