import {
  AttachFile, Close, Delete, FileUploadOutlined,
} from '@mui/icons-material'
import { Box, Modal } from '@mui/material'
import Dropzone, { FileRejection, ErrorCode } from 'react-dropzone'
import './UploadGuide.scss'
import { useState } from 'react'
import CustomSnackbar from 'components/Common/Snackbar/Snackbar'
import { post } from '@osrdata/app_core/dist/requests'

interface Props {
  open: boolean
  handleClose: () => void
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 500,
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '24px',
  gap: '24px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
}

const DOCUMENT_FILE_SIZE_LIMIT = 20 * 1024 * 1024
const acceptedTypes = {
  'application/pdf': ['.pdf'],
}

const UploadGuide = ({ open, handleClose } :Props) => {
  const [fileSelected, setFileSelected] = useState<File[]>([])
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [isUploading, setIsUploading] = useState(false)

  const handleDropRejected = (reject: FileRejection[]) => {
    if (!reject[0]) return
    const fileName = reject[0].file.name
    switch (reject[0].errors?.[0].code) {
      case ErrorCode.FileInvalidType:
        setSnackbarMessage(`Le fichier ${fileName} n'est pas au format .pdf`)
        setOpenSnackbar(true)
        break
      case ErrorCode.FileTooLarge:
        setSnackbarMessage(`Le fichier ${fileName} est trop lourd (max 20Mo)`)
        setOpenSnackbar(true)
        break
      case ErrorCode.FileTooSmall:
        setSnackbarMessage(`Le fichier ${fileName} est trop petit`)
        setOpenSnackbar(true)
        break
      case ErrorCode.TooManyFiles:
        setSnackbarMessage('Vous ne pouvez importer qu\'un seul fichier')
        setOpenSnackbar(true)
        break
      default:
        setSnackbarMessage(`Une erreur inconnue sur le fichier ${fileName}`)
        setOpenSnackbar(true)
        break
    }
  }

  const onClose = () => {
    setFileSelected([])
    handleClose()
  }

  const dropzoneClassName = `dropzone ${(fileSelected.length > 0) ? 'has-files' : ''}`
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <>
        <Box className="upload-guide" sx={style}>
          <div className="header">
            <span>Importer un guide utilisateur</span>
            <button type="button" onClick={onClose}>
              <Close />
            </button>
          </div>
          {fileSelected.length === 0 ? (
            <Dropzone
              maxFiles={1}
              maxSize={DOCUMENT_FILE_SIZE_LIMIT} // 20MiB
              onDropRejected={handleDropRejected}
              onDropAccepted={file => setFileSelected(file)}
              accept={acceptedTypes}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ className: dropzoneClassName })}>
                  <input {...getInputProps()} />
                  <FileUploadOutlined className="icon" />
                  <h4>Glissez-déposez votre document ici</h4>
                  <p>Ou</p>
                  <div className="upload-btn">
                    <button type="button" className="btn btn-primary">
                      <FileUploadOutlined />
                      Choisissez un fichier
                    </button>
                    <span>Format attendu : .pdf</span>
                  </div>
                </div>
              )}
            </Dropzone>
          ) : (
            <div className="file-selected">
              <div className="file">
                <AttachFile />
                <span>{fileSelected[0].name}</span>
                <button type="button" onClick={() => setFileSelected([])}>
                  <Delete />
                </button>
              </div>
              <div className="actions">
                <button type="button" onClick={() => setFileSelected([])} className="cancel">
                  Annuler
                </button>
                <button
                  type="button"
                  disabled={isUploading}
                  onClick={() => {
                    setIsUploading(true)
                    const formData = new FormData()
                    const object = { formData }
                    formData.append('file', fileSelected[0])
                    post('/open_tco/guide/', object.formData).catch(() => {
                      setSnackbarMessage('Une erreur est survenue lors de l\'importation du guide')
                      setOpenSnackbar(true)
                      setIsUploading(false)
                    }).then(() => {
                      setIsUploading(false)
                      onClose()
                    })
                  }}
                >
                  Importer
                </button>

              </div>
            </div>
          )}
        </Box>
        <CustomSnackbar
          message={snackbarMessage}
          displaySnackbar={openSnackbar}
          handleClose={() => setOpenSnackbar(false)}
          severity="error"
        />
      </>
    </Modal>
  )
}

export default UploadGuide
