import {
  Box, FormControlLabel, FormGroup, Input, Slider, debounce,
} from '@mui/material'
import { RootState } from 'Store'
import terms from 'common/terms'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateIsLateSettings } from 'reducers/objects'
import ZonesService from 'reducers/services/zonesService'
import { Zone } from 'reducers/types'

const MAX_DELAY = 240

interface Props {
  defaultLateDelay: number
  defaultOnTimeColor: string
  defaultLateColor: string
}

const DelaySlider = ({ defaultLateColor, defaultLateDelay, defaultOnTimeColor }: Props) => {
  const dispatch = useDispatch()
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)
  const { activeZone } = useSelector((state: RootState) => state.zone)
  const [value, setValue] = useState<number | string>(defaultLateDelay)
  const [onTimeColor, setOnTimeColor] = useState<string>(defaultOnTimeColor)
  const [delayColor, setDelayColor] = useState<string>(defaultLateColor)
  const [settingsChanged, setSettingsChanged] = useState(false)

  const saveSettings = (changed: boolean, newSettings) => {
    if (changed) {
      if (activeZone.pk) {
        dispatch(ZonesService.updateZone({
          perimeterSlug: activePerimeter.slug,
          zonePk: activeZone.pk,
          zone: {
            ...newSettings,
          },
        }))
      }
      dispatch(updateIsLateSettings(newSettings))
      setSettingsChanged(false)
    }
  }
  const debouncedSaveSettings = useCallback(debounce(saveSettings, 1000), [activePerimeter, activeZone])

  useEffect(() => {
    const newSettings: Partial<Zone> = {
      ...(typeof value === 'number' && { is_train_late_delay: value }),
      on_time_color: onTimeColor,
      late_color: delayColor,
    }
    debouncedSaveSettings(settingsChanged, newSettings)
  }, [onTimeColor, delayColor, value])

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number)
    setSettingsChanged(true)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(event.target.value))
    setSettingsChanged(true)
  }

  const handleBlur = () => {
    if (typeof value !== 'number' || value < 0) {
      setValue(0)
    } else if (value > MAX_DELAY) {
      setValue(MAX_DELAY)
    }
  }

  const handleOnTimeColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnTimeColor(event.target.value)
    setSettingsChanged(true)
  }

  const handleDelayColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDelayColor(event.target.value)
    setSettingsChanged(true)
  }
  return (
    <Box className="box-style" sx={{ marginBottom: 0 }}>
      <FormGroup>
        <FormControlLabel
          className="delay-slider-label"
          control={(
            <>
              <span>{terms.DelaySlider.minutes}</span>
              <Input
                value={value}
                size="small"
                onChange={handleInputChange}
                onBlur={handleBlur}
                inputProps={{
                  step: 5,
                  min: 0,
                  max: MAX_DELAY,
                  type: 'number',
                  'aria-labelledby': 'input-slider',
                }}
              />
            </>
        )}
          label={terms.DelaySlider.threshold}
          labelPlacement="start"
        />
      </FormGroup>
      <Slider
        value={typeof value === 'number' ? value : 0}
        min={0}
        max={MAX_DELAY}
        className="slider"
        onChange={handleSliderChange}
        aria-labelledby="input-slider"
      />
      <div className="delay-colors">
        <strong>{terms.DelaySlider.title}</strong>
        <div className="color-input">
          <span>{terms.DelaySlider.onTime}</span>
          <input type="color" value={onTimeColor} onChange={handleOnTimeColorChange} />
        </div>
        <div className="color-input">
          <span>{terms.DelaySlider.late}</span>
          <input type="color" value={delayColor} onChange={handleDelayColorChange} />
        </div>
      </div>

    </Box>
  )
}

export default DelaySlider
