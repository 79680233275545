import { NotificationsOutlined } from '@mui/icons-material'
import {
  Autocomplete, Badge, Checkbox, Snackbar, TextField, Typography, debounce,
} from '@mui/material'
import {
  ChangeEvent, useCallback, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { updateAlertsList, updateSettings } from 'reducers/alerts'
import { Alert } from 'reducers/types'
import gong from 'assets/sounds/gong.mp3'
import ding from 'assets/sounds/ding.mp3'
import terms from 'common/terms'
import ZonesService from 'reducers/services/zonesService'
import AlertNotification from '../AlertNotification/AlertNotification'
import './AlertsMonitor.scss'

const AlertsMonitor = () => {
  const dispatch = useDispatch()
  const [windowVisible, setWindowVisible] = useState(false)
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)
  const { activeZone } = useSelector((state: RootState) => state.zone)
  const { settings, alertsList, newAlert } = useSelector((state: RootState) => state.alerts)
  const [audio] = useState(new Audio(gong))
  const [secondaryAudio] = useState(new Audio(ding))
  const [textAlerts, setTextAlerts] = useState<Alert[]>([])
  const [settingsChanged, setSettingsChanged] = useState(false)

  const saveSettings = (changed: boolean, newSettings) => {
    if (changed) {
      dispatch(ZonesService.updateZone({
        perimeterSlug: activePerimeter.slug,
        zonePk: activeZone.pk,
        zone: {
          monitoring_config: {
            sound_active: newSettings.soundAlerts,
            pop_up_active: newSettings.textAlerts,
            volume: newSettings.volume / 100,
            notified_gares: newSettings.notifiedStations.map(station => ({
              gare: station.label,
              enabled: station.enabled,
            })),
          },
        },
      }))
      setSettingsChanged(false)
    }
  }
  const debouncedSaveSettings = useCallback(debounce(saveSettings, 1000), [activePerimeter, activeZone])

  useEffect(() => {
    audio.volume = settings.volume / 100
    secondaryAudio.volume = settings.volume / 100
  }, [settings.volume])

  useEffect(() => {
    if (settings.soundAlerts) {
      audio.volume = settings.volume / 100
      secondaryAudio.volume = settings.volume / 100
    } else {
      audio.pause()
      secondaryAudio.pause()
      audio.currentTime = 0
      secondaryAudio.currentTime = 0
    }
  }, [settings.soundAlerts])

  useEffect(() => {
    debouncedSaveSettings(settingsChanged, settings)
  }, [settings.soundAlerts, settings.textAlerts, settings.volume, settings.notifiedStations])

  useEffect(() => {
    if (newAlert
      && !settings.notifiedStations.some(station => station.label === newAlert.gare && station.enabled)) return
    if (settings.soundAlerts && !!newAlert && audio) {
      if (newAlert.code === 1001) {
        audio.pause()
        audio.currentTime = 0
        if (!secondaryAudio.paused) {
          secondaryAudio.currentTime = 0
        } else {
          secondaryAudio.play()
        }
      } else {
        secondaryAudio.pause()
        secondaryAudio.currentTime = 0
        if (!audio.paused) {
          audio.currentTime = 0
        } else {
          audio.play()
        }
      }
    }
    if (settings.textAlerts && !!newAlert && !windowVisible) {
      setTextAlerts(prev => [...prev, newAlert])
    }

    setTimeout(() => {
      setTextAlerts(prev => prev.filter(alert => alert.id !== newAlert?.id))
    }, 10000)
  }, [newAlert])

  const handleClick = () => {
    setWindowVisible(!windowVisible)
    if (alertsList.some(alert => !alert.read)) {
      dispatch(updateAlertsList(alertsList.map(alert => ({ ...alert, read: true }))))
    }
  }

  const handleChangeVolume = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateSettings({
      ...settings,
      volume: e.target.value,
    }))
    setSettingsChanged(true)
  }

  const handleCheckTextAlerts = () => {
    dispatch(updateSettings({
      ...settings,
      textAlerts: !settings.textAlerts,
    }))
    setSettingsChanged(true)
  }

  const handleCheckSoundAlerts = () => {
    dispatch(updateSettings({
      ...settings,
      soundAlerts: !settings.soundAlerts,
    }))
    setSettingsChanged(true)
  }

  const handleCloseTextAlert = (id: string) => () => {
    setTextAlerts(prev => prev.filter(alert => alert.id !== id))
  }

  const handleChangeSelectedStations = (stations: { label: string, enabled: boolean }[]) => {
    dispatch(updateSettings({
      ...settings,
      notifiedStations: settings.notifiedStations.map(station => ({
        ...station,
        enabled: stations.some(s => s.label === station.label),
      })),
    }))
    setSettingsChanged(true)
  }

  return (
    <>
      <div className="alerts-monitor" style={{ marginLeft: '5px', marginRight: '-10px' }}>
        <button type="button" className="alerts-monitor-icon" onClick={handleClick}>
          <Badge
            variant="dot"
            color={alertsList.some(alert => !alert.read) ? 'error' : 'default'}
          >
            <NotificationsOutlined />
          </Badge>
        </button>
        {windowVisible && (
        <div className="alerts-monitor-window">
          <header>
            <div className="title">
              <NotificationsOutlined />
              <span>{terms.AlertsMonitor.alerts}</span>
            </div>
            <div className="settings">
              <div className="input-checkboxes">
                <div className="input-checkbox">
                  <span>{terms.AlertsMonitor.textAlerts}</span>
                  <input type="checkbox" checked={settings.textAlerts} onChange={handleCheckTextAlerts} />
                </div>
                <div className="input-checkbox">
                  <span>{terms.AlertsMonitor.soundAlerts}</span>
                  <input type="checkbox" checked={settings.soundAlerts} onChange={handleCheckSoundAlerts} />
                </div>
              </div>
              <div className="volume-slider">
                <span>{terms.AlertsMonitor.volume}</span>
                <input type="range" min="0" max="100" value={settings.volume} onChange={handleChangeVolume} />
              </div>
            </div>
            <Autocomplete
              multiple
              disableCloseOnSelect
              disableClearable
              sx={{
                maxWidth: 400, width: 250, backgroundColor: 'white', margin: '5px', borderRadius: '5px',
              }}
              options={settings.notifiedStations as { label: string, enabled: boolean }[]}
              renderInput={params => (
                <TextField
                  {...params}
                  size="small"
                  placeholder={settings.notifiedStations.filter(station => station.enabled).length === 0
                    ? terms.AlertsMonitor.noStationsNotified : ''}
                />
              )}
              value={settings.notifiedStations.filter(station => station.enabled)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  <Typography sx={{ fontSize: '12px' }}>
                    {option.label}
                  </Typography>
                </li>
              )}
              renderTags={value => (
                <Typography>
                  {`${value.length} ${terms.AlertsMonitor.stationsNotified}`}
                </Typography>
              )}
              onChange={(e, value) => handleChangeSelectedStations(value)}
            />
          </header>
          <div className="alerts-list">
            {alertsList.length === 0 ? (
              <div className="no-alerts">
                <span>{terms.AlertsMonitor.noAlerts}</span>
              </div>
            ) : (
              <>
                {alertsList
                  .filter(alert => settings.notifiedStations.some(
                    station => station.label === alert.gare && station.enabled,
                  ))
                  .map(alert => (<AlertNotification key={alert.id} alert={alert} />))}
              </>
            )}
          </div>
        </div>
        )}
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={settings.textAlerts && textAlerts.length > 0}
      >
        <div className="text-alerts" style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {textAlerts.map(alert => (
            <AlertNotification
              key={alert.id}
              alert={alert}
              handleClose={handleCloseTextAlert(alert.id)}
            />
          ))}
        </div>
      </Snackbar>
    </>
  )
}

export default AlertsMonitor
